//import urlUtil from '../utils/urlUtil.js';

const ipAddress = "https://" + "www.maicai168.cn";//www.maicai168.cn 101.34.218.240 43.139.45.99
const BASE_URL = "/api/";//"https://" + ipAddress + "/api/";
const FILE_BASE_URL = ipAddress + "/api/file/";

//接口
const interfaces = {
	FILE_ADDRESS_URL: FILE_BASE_URL,
	IP_ADDRESS_URL: ipAddress,
	LOGIN_PWD_URL: BASE_URL+"oauth/user/login/pwd",
	LOGIN_SMS_URL: BASE_URL+"oauth/user/login/sms",
	SHOP_SEARCH_URL: BASE_URL+"oauth/shop/search",
	SHOP_QUERY_URL: BASE_URL+"oauth/shop/query",
	OAUTH_USER_PWD_URL: BASE_URL+"oauth/user/pwd",
	SYSTEM_VERSION_NEW_URL: BASE_URL+"system/version/new",
	USER_URL: BASE_URL+"oauth/user",
	WORKER_APPLY_URL: BASE_URL+"oauth/worker/apply",
	AUTHORITY_URL: BASE_URL+"oauth/authority",
	WORKER_AUTHORITY_URL: BASE_URL+"oauth/worker/authority",
	ACCOUNT_URL: BASE_URL+"basic/account",
	BASIC_ACCOUNT_DETAILED_URL: BASE_URL+"basic/account/detailed",
	SUMM_ACCOUNT_COLLECT_PAY_URL: BASE_URL+"summ/account/collect/pay",
	SUMM_ACCOUNT_STREAM_DATE_URL: BASE_URL+"summ/account/stream/date",
	OAUTH_WORKER_ACCOUNT_AUTHORITY_URL: BASE_URL+"oauth/worker/account/authority",
	SHOP_MINE_URL: BASE_URL+"oauth/shop/mine",
	FILE_URL: BASE_URL+"file",
	FILE_APP_URL: BASE_URL+"file/app",
	FILE_PRINT_URL: BASE_URL+"file/print",
	SYSTEM_MARKET_URL: BASE_URL+"system/market",
	SYSTEM_DISTRICT_URL: BASE_URL+"system/district",
	SHOP_TOKEN_URL: BASE_URL+"oauth/shop/token",
	OAUTH_SHOP_INFO_URL: BASE_URL+"oauth/shop/info",
	OAUTH_CUSTOMER_URL: BASE_URL+"oauth/customer",
	OAUTH_OWNER_URL: BASE_URL+"oauth/owner",
	OAUTH_SUPPLIER_URL: BASE_URL+"oauth/supplier",
	OAUTH_WORKER_URL: BASE_URL+"oauth/worker",
	OAUTH_COMPANY_URL: BASE_URL+"oauth/company",
	OAUTH_WORKER_FIRE_URL: BASE_URL+"oauth/worker/fire",
	WORKER_HANDLE_URL: BASE_URL+"oauth/worker/handle",
	WORKER_FIRE_URL: BASE_URL+"oauth/worker/fire",
	COST_CLAUSE_URL: BASE_URL+"system/cost/clause",
	BASIC_SALE_COST_URL: BASE_URL+"basic/sale/cost",
	BASIC_SHOP_COMMODITY_URL: BASE_URL+"basic/shop/commodity",
	SALE_PURCHASE_SHOP_COMMODITY_URL: BASE_URL+"sale/purchase/shop/commodity",
	OWNER_EXPEND_CLAUSE_URL: BASE_URL+"basic/owner/expend/clause",
	SHOP_EXPEND_CLAUSE_URL: BASE_URL+"basic/shop/expend/clause",
	SHOP_COLLECT_CLAUSE_URL: BASE_URL+"basic/shop/collect/clause",
	WAREHOUSE_URL: BASE_URL+"basic/warehouse",
	SYSTEM_COMMODITY_URL: BASE_URL+"system/system/commodity",
	SALE_CARGO_NO_URL: BASE_URL+"sale/cargo/no",
	BASIC_SHOP_COMMODITY_TYPE_URL: BASE_URL+"basic/shop/commodity/type",
	SALE_CARGO_URL: BASE_URL+"sale/cargo",
	SALE_CARGO_FINISH_URL: BASE_URL+"sale/cargo/finish",
	SALE_CARGO_FINISH_CANCEL_URL: BASE_URL+"sale/cargo/finish/cancel",
	CARGO_SEARCH_URL: BASE_URL+"sale/cargo/search",
	PURCHASE_SEARCH_URL: BASE_URL+"sale/purchase/search",
	SALE_PURCHASE_URL: BASE_URL+"sale/purchase",
	ALLOT_SEARCH_URL: BASE_URL+"sale/allot/search",
	SALE_ALLOT_URL: BASE_URL+"sale/allot",
	SALE_WAREHOUSE_COMMODITY_URL: BASE_URL+"sale/warehouse/commodity",
	SALE_CARGO_BACK_URL: BASE_URL+"sale/cargo/back",
	SALE_TAKE_STOCK_URL: BASE_URL+"sale/take/stock",
	STOCK_SEARCH_URL: BASE_URL+"sale/take/stock/search",
	IN_OUT_SEARCH_URL: BASE_URL+"sale/stock/in/out/search",
	SALE_STOCK_IN_OUT_URL: BASE_URL+"sale/stock/in/out",
	CUSTOMER_ARREARS_INIT_URL: BASE_URL+"finance/customer/arrears/init",
	OWNER_ARREARS_INIT_URL: BASE_URL+"finance/owner/arrears/init",
	SUPPIER_ARREARS_INIT_URL: BASE_URL+"finance/supplier/arrears/init",
	WORKER_ARREARS_INIT_URL: BASE_URL+"finance/worker/arrears/init",
	SALE_WHOLESALE_SEARCH_URL: BASE_URL+"sale/wholesale/search",
	FINANCE_CUSTOMER_COLLECT_ARREARS_URL: BASE_URL+"finance/customer/collect/arrears",
	FINANCE_OWNER_EXPEND_URL: BASE_URL+"finance/owner/expend",
	FINANCE_OWNER_PAY_URL: BASE_URL+"finance/owner/pay",
	FINANCE_SUPPLIER_PAY_URL: BASE_URL+"finance/supplier/pay",
	FINANCE_SHOP_COLLECT_URL: BASE_URL+"finance/shop/collect",
	FINANCE_SHOP_EXPEND_URL: BASE_URL+"finance/shop/expend",
	FINANCE_WORKER_PAY_URL: BASE_URL+"finance/worker/pay",
	FINANCE_TRANSFER_ACCOUNT_URL: BASE_URL+"finance/transfer/account",
	SALE_WHOLESALE_URL: BASE_URL+"sale/wholesale",
	SALE_WHOLESALE_BACK_URL: BASE_URL+"sale/wholesale/back",
	SALE_WHOLESALE_SAVE_URL: BASE_URL+"sale/wholesale/save",
	SALE_PROCESS_PLAN_URL: BASE_URL+"sale/process/plan",
	SALE_PROCESS_URL: BASE_URL+"sale/process",
	SALE_PROCESS_SEARCH_URL: BASE_URL+"sale/process/search",
	SUMM_CUSTOMER_STREAM_URL: BASE_URL+"summ/customer/stream",
	SUMM_OWNER_STREAM_URL: BASE_URL+"summ/owner/stream",
	SUMM_SUPPLIER_STREAM_URL: BASE_URL+"summ/supplier/stream",
	SUMM_WORKER_STREAM_URL: BASE_URL+"summ/worker/stream",
	SUMM_CUSTOMER_STREAM_AGGREGATE_URL: BASE_URL+"summ/customer/stream/aggregate",
	SUMM_OWNER_STREAM_AGGREGATE_URL: BASE_URL+"summ/owner/stream/aggregate",
	SUMM_SUPPLIER_STREAM_AGGREGATE_URL: BASE_URL+"summ/supplier/stream/aggregate",
	SUMM_WORKER_STREAM_AGGREGATE_URL: BASE_URL+"summ/worker/stream/aggregate",
	BASIC_SHOP_CONFIG_URL: BASE_URL+"basic/shop/config",
	BASIC_SHOP_CONFIG_DIFF_URL: BASE_URL+"basic/shop/config/diff",
	BASIC_SHOP_CONFIG_SETTLE_TIME_URL: BASE_URL+"basic/shop/config/settle/time",
	SUMM_SETTLE_URL: BASE_URL+"summ/settle",
	FINANCE_CUSTOMER_ARREARS_SUMM_URL: BASE_URL+"finance/customer/arrears/summ",
	FINANCE_CUSTOMER_ACCRUED_ARREARS_URL: BASE_URL+"finance/customer/accrued/arrears",
	SUMM_CUSTOMER_RECONCILIATION_URL: BASE_URL+"summ/customer/reconciliation",
	SUMM_SALE_PROFIT_LOSS_URL: BASE_URL+"summ/sale/Profit/Loss",
	SUMM_CARGO_SUMM_SETTLE_URL: BASE_URL+"summ/cargo/summ/settle",
	SUMM_CARGO_SUMM_SETTLE_NEW_URL: BASE_URL+"summ/cargo/summ/settle/new",
	SUMM_COLLECT_PAY_RECORD_URL: BASE_URL+"summ/collect/pay/record",
	SUMM_CUSTOMER_SALE_SETTLE_URL: BASE_URL+"summ/customer/sale/settle",
	SUMM_SALE_PROFIT_URL: BASE_URL+"summ/sale/profit",
	SUMM_SALE_SITUATION_URL: BASE_URL+"summ/sale/situation",
	SUMM_PURCHASE_SETTLE_URL: BASE_URL+"summ/purchase/settle",
	FINANCE_CUSTOMER_ARREARS_DETAILED_URL: BASE_URL+"finance/customer/arrears/detailed",
	BASIC_CARGO_SELF_LINE_URL: BASE_URL+"basic/cargo/self/line",
	OAUTH_SHOP_URL: BASE_URL+"oauth/shop",
	SALE_STOCK_CARGO_URL: BASE_URL+"sale/stock/cargo",
	FINANCE_CUSTOMER_COLLECT_URL: BASE_URL+"finance/customer/collect",
	FINANCE_CUSTOMER_COLLECT_PRINT_URL: BASE_URL+"finance/customer/collect/print",
	OAUTH_USER_NAME_URL: BASE_URL+"oauth/user/name",
	OAUTH_USER_INFO_URL: BASE_URL+"oauth/user/info",
	OAUTH_USER_AVATAR_URL: BASE_URL+"oauth/user/avatar",
	OAUTH_SHOP_APPLY_URL: BASE_URL+"oauth/shop/apply",
	OAUTH_SHOP_APPLY_REVOKE_URL: BASE_URL+"oauth/shop/apply/revoke",
	OAUTH_WORKER_REVOKE_URL: BASE_URL+"oauth/worker/revoke",
	OAUTH_CUSTOMER_RESTORE_URL: BASE_URL+"oauth/customer/restore",
	OAUTH_OWNER_RESTORE_URL: BASE_URL+"oauth/owner/restore",
	OAUTH_SUPPLIER_RESTORE_URL: BASE_URL+"oauth/supplier/restore",
	BASIC_SHOP_PRINT_URL: BASE_URL+"basic/shop/print",
	BASIC_WORKER_HABIT_URL: BASE_URL+"basic/worker/habit",
	OAUTH_WORKER_OWNER_URL: BASE_URL+"oauth/worker/owner",
	OAUTH_WORKER_OWNER_NEW_URL: BASE_URL+"oauth/worker/owner/new",
	OAUTH_WORKER_CONFIG_URL: BASE_URL+"oauth/worker/config",
	SALE_LOG_SEARCH_URL: BASE_URL+"sale/log/search",
	SALE_LOG_URL: BASE_URL+"sale/log",
	SUMM_LOG_URL: BASE_URL+"summ/log",
	FINANCE_LOG_SEARCH_URL: BASE_URL+"finance/log/search",
	FINANCE_LOG_URL: BASE_URL+"finance/log",
	SALE_CARGO_COMMODITY_URL: BASE_URL+"sale/cargo/commodity",
	SUMM_CAPITAL_SETTLE_URL: BASE_URL+"summ/capital/settle",
	SALE_WHOLESALE_PRINT_URL: BASE_URL+"sale/wholesale/print",
	PRINT_URL: BASE_URL+"print",
	SHARE_CUSTOMER_INFO_URL: BASE_URL+"share/customer/info",
	SHARE_WHOLESALE_URL: BASE_URL+"share/wholesale",
	SHARE_COLLECT_URL: BASE_URL+"share/collect",
	SHARE_WHOLESALE_INFO_URL: BASE_URL+"share/wholesale/info",
	SHARE_RECONCILIATION_URL: BASE_URL+"share/reconciliation",
	SHARE_TRACE_COMMODITY_URL: BASE_URL+"share/trace/commodity",
	SHARE_CARGO_URL: BASE_URL+"share/cargo",
	SUMM_ACCOUNT_SETTLE_URL: BASE_URL+"summ/account/settle",
	SALE_CARGO_DISCOUNT_URL: BASE_URL+"sale/cargo/discount",
	FINANCE_ACCOUNT_BALANCE_INIT_URL: BASE_URL+"finance/account/balance/init",
	OAUTH_CUSTOMER_BIND_STATE_URL: BASE_URL+"oauth/customer/bind/state",
	OAUTH_CUSTOMER_BIND_URL: BASE_URL+"oauth/customer/bind",
	SALE_WHOLESALE_COLLECT_URL: BASE_URL+"sale/wholesale/collect",
	SALE_CARGO_COMMODITY_UPDATE_URL: BASE_URL+"sale/cargo/commodity/update",
	SALE_CARGO_NO_URL: BASE_URL+"sale/cargo/no",
	SALE_WHOLESALE_SEQUENCE_URL: BASE_URL+"sale/wholesale/sequence",
	SALE_WHOLESALE_SEQUENCE_PRINT_URL: BASE_URL+"sale/wholesale/sequence/print",
	SALE_WORKER_RANK_URL: BASE_URL+"sale/worker/rank",
	SALE_CARGO_ADVANCE_EXPEND_URL: BASE_URL+"sale/cargo/advance/expend",
	SALE_WHOLESALE_ADVANCE_URL: BASE_URL+"sale/wholesale/advance",
	OAUTH_WORKER_LINE_URL: BASE_URL+"oauth/worker/line",
	SALE_WHOLESALE_PRINT_LIST_URL: BASE_URL+"sale/wholesale/print/list",
	SALE_WHOLESALE_PRINT_LIST_SUMMARY_URL: BASE_URL+"sale/wholesale/print/list/summary",
	BASIC_ACCOUNT_NAME_BALANCE_URL: BASE_URL+"basic/account/name/balance",
	OAUTH_WORKER_WAREHOUSE_URL: BASE_URL+"oauth/worker/warehouse",
	SALE_CARGO_TRANSFER_URL: BASE_URL+"sale/cargo/transfer",
	SALE_STOCK_COMMODITY_URL: BASE_URL+"sale/stock/commodity",
	OAUTH_WHOLESALE_WORKER_URL: BASE_URL+"oauth/wholesale/worker",
	SUMM_SALE_DAILY_URL: BASE_URL+"summ/sale/daily",
	SALE_CARGO_MAKE_PRICE_URL: BASE_URL+"sale/cargo/make/price",
	BASIC_SHOP_COMMODITY_COPY_URL: BASE_URL+"basic/shop/commodity/copy",
	SUMM_SALE_STATISTICS_URL: BASE_URL+"summ/sale/statistics",
	SALE_WHOLESALE_PRINT_CUSTOMER_MULTIPLE_URL: BASE_URL+"sale/wholesale/print/customer/multiple",
	SUMM_SHOP_PROFIT_URL: BASE_URL+"summ/shop/profit",
	BASIC_OWNER_LINE_WORKER_URL: BASE_URL+"basic/owner/line/worker",
	SALE_WHOLESALE_SAVE_UPDATE_MULTIPLE_URL: BASE_URL+"sale/wholesale/save/update/multiple",
	BASIC_BUYER_URL: BASE_URL+"basic/buyer",
	SALE_BUYER_SETTLE_URL: BASE_URL+"sale/buyer/settle",
	SALE_WHOLESALE_PRINT_CUSTOMER_COLLECT_URL: BASE_URL+"sale/wholesale/print/customer/collect",
	SUMM_SALE_REPORT_DAILY_URL: BASE_URL+"summ/sale/report/daily",
	SUMM_SALE_DAILY_RANGE_URL: BASE_URL+"summ/sale/daily/range",
	SUMM_SALE_DAILY_RANGE_SET_URL: BASE_URL+"summ/sale/daily/range/set",
	SALE_BUYER_RANK_URL: BASE_URL+"sale/buyer/rank",
	SUMM_PROCESS_SETTLE_URL: BASE_URL+"summ/process/settle",
	BASIC_SHOP_COMMODITY_COST_MULTIPLE_URL: BASE_URL+"basic/shop/commodity/cost/multiple",
	BASIC_SHOP_COMMODITY_TYPE_MULTIPLE_URL: BASE_URL+"basic/shop/commodity/type/multiple",
	SALE_STOCK_COMMODITY_DATE_URL: BASE_URL+"sale/stock/commodity/date",
	BASIC_WORKER_WHOLESALE_WAY_URL: BASE_URL+"basic/worker/wholesale/way",
	BASIC_SHOP_COMMODITY_MEMBER_URL: BASE_URL+"basic/shop/commodity/member",
	SUMM_SALE_STATISTICS_RANGE_URL: BASE_URL+"summ/sale/statistics/range",
	OAUTH_CUSTOMER_WHOLESALE_LAST_URL: BASE_URL+"oauth/customer/wholesale/last",
	SALE_WAREHOUSE_COMMODITY_SORT_URL: BASE_URL+"sale/warehouse/commodity/sort",
	SALE_WAREHOUSE_SUPPLEMENT_URL: BASE_URL+"sale/wholesale/supplement",
	BASIC_SHOP_CONFIG_SHARE_URL: BASE_URL+"basic/shop/config/share",
	FINANCE_CUSTOMER_COLLECT_DISCOUNT_URL: BASE_URL+"finance/customer/collect/discount",
	FINANCE_OWNER_EXPEND_PAY_URL: BASE_URL+"finance/owner/expend/pay",
	BASIC_WORKER_PRINT_DELIVERY_URL: BASE_URL+"basic/worker/print/delivery",
	SUMM_LOG_SEARCH_URL: BASE_URL+"summ/log/search",
	BASIC_SHOP_CONFIG_ISSHOWOTHER_URL: BASE_URL+"basic/shop/config/isShowOther",
	SUMM_SUPPLIER_RECONCILIATION_URL: BASE_URL+"summ/supplier/reconciliation",
	OAUTH_CUSTOMER_ARREARS_SHOP_URL: BASE_URL+"oauth/customer/arrears/shop",
	SUMM_ACCOUNT_STREAM_EXCEL_URL: BASE_URL+"summ/account/stream/excel",
	SALE_ADVANCE_ORDER_SAVE_URL: BASE_URL+"sale/advance/order/save",
	SALE_ADVANCE_ORDER_URL: BASE_URL+"sale/advance/order",
	SALE_STOCK_SUMM_URL: BASE_URL+"sale/stock/summ",
	SALE_STOCK_SETTLE_URL: BASE_URL+"sale/stock/settle",
	SALE_STOCK_REJECT_URL: BASE_URL+"sale/stock/reject",
	OAUTH_WORKER_EDIT_WAREHOUSE_URL: BASE_URL+"oauth/worker/edit/warehouse",
	SALE_ADVANCE_ORDER_WHOLESALE_URL: BASE_URL+"sale/advance/order/wholesale",
	SALE_ADVANCE_ORDER_COMMODITY_URL: BASE_URL+"sale/advance/order/commodity",
	SALE_ADVANCE_ORDER_CANCEL_URL: BASE_URL+"sale/advance/order/cancel",
	BASIC_BUYER_CUSTOMER_URL: BASE_URL+"basic/buyer/customer",
	SALE_WHOLESALE_BATCH_REMOVE_URL: BASE_URL+"sale/wholesale/batch/remove",
	BASIC_SHOP_CONFIG_SHOW_IMG_URL: BASE_URL+"basic/shop/config/show/img",
	BASIC_SHOP_CONFIG_SHOW_CODE_URL: BASE_URL+"basic/shop/config/show/code",
	SALE_CARGO_SON_URL: BASE_URL+"sale/cargo/son",
	SALE_CARGO_SETTLE_URL: BASE_URL+"sale/cargo/settle",
	SALE_CARGO_DISCOUNT_BATCH_URL: BASE_URL+"sale/cargo/discount/batch",
	SALE_CARGO_FINISH_BATCH_URL: BASE_URL+"sale/cargo/finish/batch",
	BASIC_SHOP_CONFIG_PRINT_UPDATE_WHOLESALE_URL: BASE_URL+"basic/shop/config/print/update/wholesale",
}

//广播
const broadcasts = {//NAMESPANCE_MENU2: `${prefix}_MENU2` 
	UPDATE_MARKET: "com.xzs.salefood.wholesalemanage.UPDATE_MARKET",
};

//{stringUtil.language(1069)}常量
const others = {
	ApiKeyUSB: "WRMqYZpHvMKuTMvfWGsqjJDxw2W35jsv",//USB打印
};

export default Object.assign({},
	interfaces,broadcasts,others // 所有的变量都在这里export导出 多个用逗号隔开
);
