import React,{Component} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import * as arithUtil from '../utils/arithUtil.js';
import * as stringUtil from '../utils/stringUtil.js';
import * as otherUtil from '../utils/otherUtil.js';
import * as printUtil from '../utils/printUtil.js';
import urlUtil from '../utils/urlUtil.js';
import ToastBox from '../components/ToastBox';
import MaskModal from '../components/MaskModal';
import TabTopActivity from '../components/TabTopActivity';
import Pagination from '../components/Pagination';
import CustomerAddActivityView from './CustomerAddActivityView';
import CustomerCollectView from './CustomerCollectView';
import { Select } from 'antd';
import style from 'antd/dist/antd.css';

 //我要开单
 export default class WholesaleAddActivity extends Component{
    constructor(props) {
      super(props);
      let paramsPage = this.props.navigationStacks[this.props.navigationStacks.length-1].paramsPage;
      this.state={
        regIndex:-1,//-1不stringUtil.language(1026)任何区域，0stringUtil.language(1048)
        regIndexVal:-1,
        wholesaleInsertVoItem:{createTime:arithUtil.timeToNumber(arithUtil.getCurrDateTime()),
            totalNumber:0,
            totalWeight:0,
            totalGoodsMoney:0,
            totalOtherMoneyTotal:0,
            advanceMoney:0,
            costs:[],
            commodities:[],
            describe:'',
            money:0
        },
        weightMode:0,//0{stringUtil.language(1023)}，1市{stringUtil.language(1172)}
        workerHabitInfo:{},
        saleWorkers:[],
        customerItemAll:[],
        warehouseCommodityItemAll:[],
        warehouseCommodityItemData:[],
        commodityTypesAll:[],
        wholesaleCargosData:[],
        currCustomerItem:{},
        customerAddShowPop:false,addShowPopIndex:0,
        costsShowPop:false,
        vegEditShow:false,
        keySearch:'',//{stringUtil.language(1063)}{stringUtil.language(1034)}关键字
        currShopCommodityTypeItem:{id:-1,name:stringUtil.language(1097)},
        currCargoItem:{id:-1,cargoMode:0,targetName:stringUtil.language(517),cargoNo:stringUtil.language(571)},
        currWholesaleCommodityVo:{},//当前正在{stringUtil.language(1092)}的待{stringUtil.language(1037)}{stringUtil.language(1063)}
        buyersAll:[],
        currWholesaleCommodityVoKey:-1,
        keyTagVal:-1,
      };
      if(localStorage.getItem("shopConfigInfo")!=null&&JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit!=null) this.state.weightMode = JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit;
    }
    componentDidMount(){
        // if(localStorage.getItem("choiceBuyer")){this.state.choiceBuyer=JSON.parse(localStorage.getItem("choiceBuyer"));}
        stringUtil.httpRequest(urlUtil.BASIC_WORKER_HABIT_URL,null,['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.state.workerHabitInfo = successResponse.data;
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
        stringUtil.httpRequest(urlUtil.BASIC_BUYER_URL,null,['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.setState({buyersAll:successResponse.data});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
        this.initCustomers();
        stringUtil.httpRequest(urlUtil.CARGO_SEARCH_URL,{
            state:1,//-1stringUtil.language(1097) 0 待接 1 售卖 2 stringUtil.language(1053)
            cargoMode:-1,currPage:0,pageSize:1000
        },['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                let ownerCargosResultObj= successResponse.data;
                this.state.wholesaleCargosData = ownerCargosResultObj.items;
                let allItem = {id:-1,cargoMode:0,targetName:stringUtil.language(517),cargoNo:stringUtil.language(571)}
                this.state.wholesaleCargosData.unshift(allItem);
                this.setState({wholesaleCargosData: this.state.wholesaleCargosData});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
        this.initData();
        stringUtil.httpRequest(urlUtil.BASIC_SHOP_COMMODITY_TYPE_URL,null,['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.state.commodityTypesAll= successResponse.data;
                let allItem = {id:-1,name:stringUtil.language(1097)};
                this.state.commodityTypesAll.unshift(allItem);
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
        stringUtil.httpRequest(urlUtil.BASIC_SALE_COST_URL,null,['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                let resultVal = JSON.parse(JSON.stringify(successResponse.data));
                resultVal.forEach((item, index) => {
                    this.state.wholesaleInsertVoItem.costs.push({
                        costClauseCalMode:item.costClauseCalMode,
                        costClauseId:item.costClauseId,
                        costClauseName:item.costClauseName,
                        costClausePayee:item.costClausePayee,
                        costClauseScope:item.costClauseScope,
                        moneyScal:item.money,
                        money:0
                    })
                })
                this.setState({wholesaleInsertVoItem: this.state.wholesaleInsertVoItem});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
        stringUtil.httpRequest(urlUtil.OAUTH_WHOLESALE_WORKER_URL,null,['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.state.saleWorkers=successResponse.data;
                this.state.saleWorkers.unshift({id:0,noteName:stringUtil.language(431)});
                this.setState({saleWorkers:this.state.saleWorkers});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
        document.onkeydown=(e)=>{
            console.log('keyCode：',e,this.state.regIndex)
            if(e.keyCode===40){//下
                if(this.state.vegEditShow){
                    this.setState({currCommodityEditsView:'numberEdit'},()=>{
                        document.getElementById('numberEdit').focus();
                    });
                }
            }else if(e.keyCode===37){//左
                if(this.state.vegEditShow){
                    this.state.currWholesaleCommodityVo.mode = 0;//stringUtil.language(1022)
                    this.state.currWholesaleCommodityVo.unitPrice=this.state.currWholesaleCommodityVo.weightMakePrice;
                    this.refs.unitPriceVal.value=this.state.currWholesaleCommodityVo.unitPrice;
                    this.setState({currWholesaleCommodityVo:this.state.currWholesaleCommodityVo},()=>{document.getElementById('numberEdit').focus();});
                }
            }else if(e.keyCode===39){//右
                if(this.state.vegEditShow){
                    this.state.currWholesaleCommodityVo.mode = 1;//stringUtil.language(1021)
                    this.state.currWholesaleCommodityVo.unitPrice=this.state.currWholesaleCommodityVo.numMakePrice;
                    this.refs.unitPriceVal.value=this.state.currWholesaleCommodityVo.unitPrice;
                    this.setState({currWholesaleCommodityVo:this.state.currWholesaleCommodityVo},()=>{document.getElementById('numberEdit').focus();});
                }
            }else if(e.keyCode===36){//regIndex==0打开/关闭stringUtil.language(1048)区域2022-5-10.10:27stringUtil.language(1027)用此键
                if(this.state.regIndex!=0)this.setState({customerItemDatas:this.state.customerItemAll,regIndex:0},()=>{document.getElementById('customerSearch').focus();});
                else if(this.state.regIndex==0)this.setState({regIndex:-1});
            }else if(e.keyCode===33){//regIndex==1打开/关闭stringUtil.language(970)区域
                if(this.state.regIndex!=1)this.setState({regIndex:1},()=>{document.getElementById('workerSerial').focus();});
                else if(this.state.regIndex==1)this.setState({regIndex:-1});
            }else if(e.keyCode===34){//regIndex==2{stringUtil.language(1063)}区域
                if(this.state.regIndex!=2)this.setState({regIndex:2},()=>{document.getElementById('commoditySearch').focus();});
                else if(this.state.regIndex==2)this.setState({regIndex:-1});
            }else if(e.keyCode===35){//regIndex==3stringUtil.language(1161)区域
                this.setState({regIndex:3},()=>{this.state.currCommodityEditsView='advance';document.getElementById('advance').focus();});
            }else if(e.keyCode===16||e.keyCode===13){
                if((e.keyCode===13&&this.state.vegEditShow&&e.code=='NumpadEnter')||(e.keyCode===16&&this.state.vegEditShow)){
                    if(this.state.currWholesaleCommodityVo.mode==0){
                        if(document.getElementById('numberEdit')==document.activeElement)document.getElementById('totalWeightEdit').focus();
                        else if(document.getElementById('totalWeightEdit')==document.activeElement)document.getElementById('skinWeightEdit').focus();
                        else if(document.getElementById('skinWeightEdit')==document.activeElement)document.getElementById('unitPriceEdit').focus();
                        else if(document.getElementById('unitPriceEdit')==document.activeElement)document.getElementById('buyerEdit').focus();
                        else if(document.getElementById('buyerEdit')==document.activeElement){
                            this.submitCommodityItem();
                            this.setState({vegEditShow:false},()=>{document.getElementById('commoditySearch').focus();});
                        }
                    }else{
                        if(document.getElementById('numberEdit')==document.activeElement){
                            if(this.state.currWholesaleCommodityVo.mode==1&&!this.state.currWholesaleCommodityVo.standard)document.getElementById('totalWeightEdit').focus();
                            else document.getElementById('unitPriceEdit').focus();
                        }else if(document.getElementById('totalWeightEdit')==document.activeElement)document.getElementById('unitPriceEdit').focus();
                        else if(document.getElementById('unitPriceEdit')==document.activeElement)document.getElementById('buyerEdit').focus();
                        else if(document.getElementById('buyerEdit')==document.activeElement){
                            this.submitCommodityItem();
                            this.setState({vegEditShow:false},()=>{document.getElementById('commoditySearch').focus();});
                        }
                    }
                }else if((e.keyCode===13&&(document.getElementById('advance')==document.activeElement||document.getElementById('describe')==document.activeElement)&&e.code=='NumpadEnter')||(e.keyCode===16&&(document.getElementById('advance')==document.activeElement||document.getElementById('describe')==document.activeElement))){
                    if(this.state.currCommodityEditsView=='save'){document.getElementById('advance').focus();this.state.currCommodityEditsView='advance';}
                    else if(this.state.currCommodityEditsView=='confirm')this.state.currCommodityEditsView='save';
                    else if(document.getElementById('describe')==document.activeElement)this.state.currCommodityEditsView='confirm';
                    else if(document.getElementById('advance')==document.activeElement)document.getElementById('describe').focus();
                    this.setState({currCommodityEditsView:this.state.currCommodityEditsView});
                }else{
                    if(document.getElementById('customerSearch')==document.activeElement||document.getElementById('customerSerial')==document.activeElement){
                        if(this.state.customerItemDatas.length==1){
                            if (this.state.customerItemDatas[0].warning!=2) {
                                this.state.currCustomerItem = JSON.parse(JSON.stringify(this.state.customerItemDatas[0]));
                                this.state.isPrintTag=this.state.currCustomerItem.type==1?0:(localStorage.getItem("printShareInfo")&&JSON.parse(localStorage.getItem("printShareInfo")).wholesale&&JSON.parse(localStorage.getItem("printShareInfo")).wholesale.arrearsCustomer==1?1:0);
                                this.setState({regIndex:-1});
                            }else{ToastBox.error(stringUtil.language(50));}
                        }else if(this.state.customerItemDatas.length>1){
                            if(document.getElementById('customerSerial')==document.activeElement){
                                if(this.state.regIndexVal<=this.state.customerItemDatas.length){
                                    if (this.state.customerItemDatas[this.state.regIndexVal-1].warning!=2) {
                                        this.state.currCustomerItem = JSON.parse(JSON.stringify(this.state.customerItemDatas[this.state.regIndexVal-1]));
                                        this.state.isPrintTag=this.state.currCustomerItem.type==1?0:(localStorage.getItem("printShareInfo")&&JSON.parse(localStorage.getItem("printShareInfo")).wholesale&&JSON.parse(localStorage.getItem("printShareInfo")).wholesale.arrearsCustomer==1?1:0);
                                        this.setState({regIndex:-1});
                                    }else{ToastBox.error(stringUtil.language(50));}
                                }else{ToastBox.error(stringUtil.language(30));}
                            }else{document.getElementById('customerSerial').value='';document.getElementById('customerSerial').focus();}
                        }else{ToastBox.error(stringUtil.language(378));this.setState({regIndex:-1});};
                    }else if(document.getElementById('workerSerial')==document.activeElement){
                        if(this.state.regIndexVal<=this.state.saleWorkers.length){
                            this.state.wholesaleInsertVoItem.workerId=this.state.saleWorkers[this.state.regIndexVal-1].id;
                            this.state.wholesaleInsertVoItem.workerName=this.state.saleWorkers[this.state.regIndexVal-1].noteName;
                            this.setState({regIndex:-1});
                        }else{ToastBox.error(stringUtil.language(30));}
                    }else if(document.getElementById('describe')==document.activeElement&&e.code=='Enter'){
                        if(this.state.currCommodityEditsView=='confirm')this.submitWholesale(1);
                        else if(this.state.currCommodityEditsView=='save')this.submitWholesale(0);
                    }else{
                        if(document.getElementById('commoditySearch')==document.activeElement||document.getElementById('commoditySerial')==document.activeElement){
                            if(this.state.warehouseCommodityItemData.length==1){
                                this.keySelectCommodity(JSON.parse(JSON.stringify(this.state.warehouseCommodityItemData[0])));
                            }else if(this.state.warehouseCommodityItemData.length>1){
                                if(document.getElementById('commoditySerial')==document.activeElement){
                                    if(this.state.regIndexVal<=this.state.warehouseCommodityItemData.length){
                                        this.keySelectCommodity(JSON.parse(JSON.stringify(this.state.warehouseCommodityItemData[this.state.regIndexVal-1])));
                                    }else{ToastBox.error(stringUtil.language(30));}
                                }else{document.getElementById('commoditySerial').value='';document.getElementById('commoditySerial').focus();}
                            }else{ToastBox.error(stringUtil.language(378));};
                        }
                    }
                }
            }
            this.state.regIndexVal=-1;
        }
        document.getElementById('commoditySearch').focus();
    }
    initData(){
        stringUtil.httpRequest(urlUtil.SALE_WAREHOUSE_COMMODITY_URL,null,['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.state.warehouseCommodityItemAll = successResponse.data;
                this.setState({warehouseCommodityItemData:this.state.warehouseCommodityItemAll});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    }
    initCustomers(){
        stringUtil.httpRequest(urlUtil.OAUTH_CUSTOMER_URL,{state:1},['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.state.customerItemAll = successResponse.data;
                if(this.state.currCustomerItem.id==null){
                    this.state.currCustomerItem=JSON.parse(JSON.stringify(this.state.customerItemAll[0]));
                    this.state.isPrintTag=this.state.currCustomerItem.type==1?0:(localStorage.getItem("printShareInfo")&&JSON.parse(localStorage.getItem("printShareInfo")).wholesale&&JSON.parse(localStorage.getItem("printShareInfo")).wholesale.arrearsCustomer==1?1:0);
                }
                this.searchCustomers();
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    }
    searchCustomers(){
        this.state.customerItemDatas = [];
        if (this.state.keySearchCus!=null&&this.state.keySearchCus!='') {
            for(let i=0;i<this.state.customerItemAll.length;i++){
                if ((this.state.customerItemAll[i].noteName).indexOf(this.state.keySearchCus)!=-1) {
                    this.state.customerItemDatas.push(this.state.customerItemAll[i]);
                }
            }
        }else{this.state.customerItemDatas = this.state.customerItemAll;}
        this.setState({customerItemDatas:this.state.customerItemDatas});
    }
    warehouseCommodityItemSearch(){
        if(this.state.sort){ToastBox.error(stringUtil.language(51));return;}
        this.state.warehouseCommodityItemData = [];
        for(let i=0;i<this.state.warehouseCommodityItemAll.length;i++){
            let commodityItem = this.state.warehouseCommodityItemAll[i];
            if (this.state.currCargoItem.id!=-1 && commodityItem.cargoId!=this.state.currCargoItem.id) continue;
            if (this.state.currShopCommodityTypeItem.id!=-1 && commodityItem.commodityTypeId!=this.state.currShopCommodityTypeItem.id) continue;
            if(this.state.keySearch&&this.state.keySearch.trim().length>0){
                var keywords = this.state.keySearch.trim().split(/\s+/);
                let is = true;
                for(let j=0;j<keywords.length;j++){
                    console.log(keywords[j]);
                     let isSub = false;
                     let pys=stringUtil.makePy((commodityItem.commodityName).trim());let isPinYinHava=0;
                    if(pys&&pys.length>0)for(let k=0;k<pys.length;k++){
                        if(pys[k].indexOf((this.state.keySearch?this.state.keySearch:'').trim().toUpperCase())!=-1){
                            isPinYinHava=1;break;
                        }
                    }
                     if(isSub||commodityItem.commodityName.indexOf(keywords[j])!=-1||isPinYinHava){
                        isSub = true;
                     }
                     if(commodityItem.commodityCode){
                        if(isSub||commodityItem.commodityCode.indexOf(keywords[j])!=-1){
                            isSub = true;
                        }
                     }
                     let ownerName = "";
                     if(commodityItem.targetType==0||commodityItem.targetType==2){
                        ownerName = commodityItem.targetName?commodityItem.targetName:'';
                     }else if(commodityItem.targetType==1){
                        ownerName = stringUtil.language(1051)+(commodityItem.targetName?commodityItem.targetName:'');
                     }
                     if(isSub||ownerName.indexOf(keywords[j])!=-1){
                        isSub = true;
                     }
                     if(!isSub){
                        is = false;
                        break;
                     }
                }
                if(!is){
                  continue;
                }
            }
            //if (commodityItem.commodityName.indexOf(this.state.keySearch)==-1&&(commodityItem.commodityCode?commodityItem.commodityCode:'').indexOf(this.state.keySearch)==-1) continue;
            this.state.warehouseCommodityItemData.push(commodityItem);
        }
    }
    keySelectCommodity(item){
        let currEditWarehouseCommodityItem = JSON.parse(JSON.stringify(item));
        for(let i=0;i<currEditWarehouseCommodityItem.costs.length;i++){
            currEditWarehouseCommodityItem.costs[i].moneyScal = currEditWarehouseCommodityItem.costs[i].money;
            currEditWarehouseCommodityItem.costs[i].money = 0;
        }
        this.state.currWholesaleCommodityVo = {
            buyerCode:currEditWarehouseCommodityItem.buyerCode,
            warehouseId:currEditWarehouseCommodityItem.warehouseId,
            cargoCommodityId:currEditWarehouseCommodityItem.cargoCommodityId,
            cargoId:currEditWarehouseCommodityItem.cargoId,
            systemCommodityId:currEditWarehouseCommodityItem.systemCommodityId,
            commodityId:currEditWarehouseCommodityItem.commodityId,
            commodityName:currEditWarehouseCommodityItem.commodityName,
            commodityCode:currEditWarehouseCommodityItem.commodityCode,
            targetType:currEditWarehouseCommodityItem.targetType,
            targetId:currEditWarehouseCommodityItem.targetId,
            targetName:currEditWarehouseCommodityItem.targetName,
            cargoNo:currEditWarehouseCommodityItem.cargoNo,
            unitMaster:currEditWarehouseCommodityItem.unitMaster,
            mode:currEditWarehouseCommodityItem.wholesaleMode,//0stringUtil.language(1022)，1stringUtil.language(1021)
            number:0,
            totalWeight:0,
            skinWeight:0,
            weight:0,
            unitPrice:currEditWarehouseCommodityItem.wholesaleMode?arithUtil.fenToYuan(currEditWarehouseCommodityItem.numMakePrice):arithUtil.unitPriceTo(arithUtil.fenToYuan(currEditWarehouseCommodityItem.weightMakePrice),0,this.state.weightMode),
            totalUnitPrice:arithUtil.fenToYuan(currEditWarehouseCommodityItem.numMakePrice),
            numMakePrice:arithUtil.fenToYuan(currEditWarehouseCommodityItem.numMakePrice),
            weightMakePrice:arithUtil.unitPriceTo(arithUtil.fenToYuan(currEditWarehouseCommodityItem.weightMakePrice),0,this.state.weightMode),
            standard:currEditWarehouseCommodityItem.standard,
            commodityReduce:currEditWarehouseCommodityItem.reduce,
            reduceWeight:0,
            goodsMoney:0,
            costs:currEditWarehouseCommodityItem.costs,
        };
        this.state.currWholesaleCommodityVoKey = -1;
        this.state.currCommodityEditsView='numberEdit';
        this.setState({vegEditShow:true},()=>{document.getElementById('commoditySerial').value='';document.getElementById(this.state.currCommodityEditsView).focus();});
    }
    submitWholesale(wholesaleType){//0stringUtil.language(1145)，1stringUtil.language(1144)
        if(wholesaleType==1){
            this.state.wholesaleInsertVoItem.customerId = this.state.currCustomerItem.id;
            if (this.state.wholesaleInsertVoItem.commodities.length==0) {
                ToastBox.error(stringUtil.language(190));return;
            }
            for(let i=0;i<this.state.wholesaleInsertVoItem.commodities.length;i++){
                let commodityItem = this.state.wholesaleInsertVoItem.commodities[i];
                if (commodityItem.unitPrice==0) {
                    ToastBox.error(stringUtil.language(1010)+'“'+commodityItem.commodityName+'”'+stringUtil.language(1011)+'！');return;
                }
                if (commodityItem.mode==0&&commodityItem.weight<=0) {
                    ToastBox.error(stringUtil.language(1010)+'“'+commodityItem.commodityName+'”'+stringUtil.language(1012)+'！');return;
                }
                if (commodityItem.mode==1&&commodityItem.number<=0) {
                    ToastBox.error(stringUtil.language(1010)+'“'+commodityItem.commodityName+'”'+stringUtil.language(560));return;
                }
            }
            let wholesaleInsertVo = {workerId:0,costs:[],commodities:[]};
            wholesaleInsertVo.workerId = this.state.wholesaleInsertVoItem.workerId==null?0:(this.state.wholesaleInsertVoItem.workerId==0?-1:this.state.wholesaleInsertVoItem.workerId);
            wholesaleInsertVo.createTime = this.state.wholesaleInsertVoItem.createTime;
            wholesaleInsertVo.customerId = this.state.wholesaleInsertVoItem.customerId;
            wholesaleInsertVo.customerAlias = this.state.wholesaleInsertVoItem.customerAlias;
            wholesaleInsertVo.advanceMoney = this.state.wholesaleInsertVoItem.advanceMoney;
            wholesaleInsertVo.money = this.state.wholesaleInsertVoItem.money;
            wholesaleInsertVo.billMoney = this.state.wholesaleInsertVoItem.billMoney;
            wholesaleInsertVo.state = 1;//0stringUtil.language(1145)，1stringUtil.language(1144)
            wholesaleInsertVo.describe = this.state.wholesaleInsertVoItem.describe;
            for(let i=0;i<this.state.wholesaleInsertVoItem.costs.length;i++){
                let costsTemp = {money:0};
                costsTemp.costClauseId = this.state.wholesaleInsertVoItem.costs[i].costClauseId;
                costsTemp.money = this.state.wholesaleInsertVoItem.costs[i].money;
                wholesaleInsertVo.costs.push(costsTemp);
            }
            for(let i=0;i<this.state.wholesaleInsertVoItem.commodities.length;i++){
                let commodityTemp = {costs:[]};
                commodityTemp.cargoId = this.state.wholesaleInsertVoItem.commodities[i].cargoId;
                commodityTemp.buyerCode = this.state.wholesaleInsertVoItem.commodities[i].buyerCode;
                commodityTemp.targetType = this.state.wholesaleInsertVoItem.commodities[i].targetType;
                commodityTemp.targetId = this.state.wholesaleInsertVoItem.commodities[i].targetId;
                commodityTemp.commodityId = this.state.wholesaleInsertVoItem.commodities[i].commodityId;
                commodityTemp.cargoCommodityId = this.state.wholesaleInsertVoItem.commodities[i].cargoCommodityId;
                commodityTemp.standard = this.state.wholesaleInsertVoItem.commodities[i].standard;
                commodityTemp.number = this.state.wholesaleInsertVoItem.commodities[i].number;
                commodityTemp.totalWeight = this.state.wholesaleInsertVoItem.commodities[i].totalWeight;
                commodityTemp.skinWeight = this.state.wholesaleInsertVoItem.commodities[i].skinWeight;
                commodityTemp.reduceWeight = this.state.wholesaleInsertVoItem.commodities[i].reduceWeight;
                commodityTemp.weight = this.state.wholesaleInsertVoItem.commodities[i].weight;
                commodityTemp.unitPrice = this.state.wholesaleInsertVoItem.commodities[i].unitPrice;
                commodityTemp.ownerUnitPrice = this.state.wholesaleInsertVoItem.commodities[i].ownerUnitPrice?this.state.wholesaleInsertVoItem.commodities[i].ownerUnitPrice:0;
                commodityTemp.totalUnitPrice = this.state.wholesaleInsertVoItem.commodities[i].totalUnitPrice;
                commodityTemp.mode = this.state.wholesaleInsertVoItem.commodities[i].mode;
                commodityTemp.goodsMoney = this.state.wholesaleInsertVoItem.commodities[i].goodsMoney;
                if (this.state.wholesaleInsertVoItem.commodities[i].costs==null)this.state.wholesaleInsertVoItem.commodities[i].costs=[];
                for(let j=0;j<this.state.wholesaleInsertVoItem.commodities[i].costs.length;j++){
                    let costsSource = JSON.parse(JSON.stringify(this.state.wholesaleInsertVoItem.commodities[i].costs[j]));
                    commodityTemp.costs.push({
                        costClauseId:costsSource.costClauseId!=null?costsSource.costClauseId:0,
                        money:costsSource.money!=null?costsSource.money:0,
                    });
                }
                wholesaleInsertVo.commodities.push(commodityTemp);
            }
            if(wholesaleInsertVo.customerId==null||wholesaleInsertVo.customerId==0){
                ToastBox.error(stringUtil.language(457)+'！');return;
            }
            if(this.state.isVisiblity){return;}
			this.state.isVisiblity = true;
            stringUtil.httpRequest(urlUtil.SALE_WHOLESALE_URL,wholesaleInsertVo,['json','post',1],(successResponse)=>{
                if (successResponse.status==200) {
                    ToastBox.success(stringUtil.language(388));
                    this.state.currWholesaleItem={id:successResponse.data,customerId:wholesaleInsertVo.customerId};
                    if(this.state.isPrintTag!=1)stringUtil.httpRequest(urlUtil.SALE_WHOLESALE_PRINT_URL+'/'+successResponse.data,null,['key','get',1],(successResponse)=>{
                        if (successResponse.status==200) {
                            let printWholesaleInfo = successResponse.data;
                            if((localStorage.getItem("shopConfigInfo")!=null&&JSON.parse(localStorage.getItem("shopConfigInfo")).isRound==1)||this.state.currCustomerItem.type!=1) printWholesaleInfo.billMoney=0;
                            if(localStorage.getItem("useSetting")&&JSON.parse(localStorage.getItem("useSetting")).everyCopyNum!=null){
                                let printDataTemp = otherUtil.wholesaleNull(printWholesaleInfo);
                                for(let i=0;i<JSON.parse(localStorage.getItem("useSetting")).everyCopyNum;i++){
                                    printUtil.toComputerPrint(printDataTemp);
                                }
                            }else{printUtil.toComputerPrint(otherUtil.wholesaleNull(printWholesaleInfo));}
                        }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                    if(otherUtil.authorityFindCode("020401")!=null&&otherUtil.authorityFindCode("030102")!=null&&this.state.currCustomerItem.type==1){//{stringUtil.language(1037)}{stringUtil.language(1171)}
                        this.setState({customerCollectShowPop:true});
                    }else{
                        // if(this.state.isPrintTag!=1)stringUtil.httpRequest(urlUtil.SALE_WHOLESALE_PRINT_URL+'/'+successResponse.data,null,['key','get',1],(successResponse)=>{//0stringUtil.language(811)
                        //     if (successResponse.status==200) {
                        //         let printWholesaleInfo = successResponse.data;
                        //         if((localStorage.getItem("shopConfigInfo")!=null&&JSON.parse(localStorage.getItem("shopConfigInfo")).isRound==1)||this.state.currCustomerItem.type!=1) printWholesaleInfo.billMoney=0;
                        //         if(localStorage.getItem("useSetting")&&JSON.parse(localStorage.getItem("useSetting")).everyCopyNum!=null){
                        //             let printDataTemp = otherUtil.wholesaleNull(printWholesaleInfo);
                        //             for(let i=0;i<JSON.parse(localStorage.getItem("useSetting")).everyCopyNum;i++){
                        //                 printUtil.toComputerPrint(printDataTemp);
                        //             }
                        //         }else{printUtil.toComputerPrint(otherUtil.wholesaleNull(printWholesaleInfo));}
                        //     }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                        // },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                        global.isCloseThis=1;
                        this.props.navigationStacks.splice(this.props.navigationStacks.length-1,1);
                        this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'',mainStacks:this.props.navigationStacks}});
                    }
                }else{this.state.isVisiblity=false;ToastBox.error(successResponse.message+`(${successResponse.status})`);}
            },(errorResponse)=>{this.state.isVisiblity=false;ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
        }else if(wholesaleType==0){
            this.state.wholesaleInsertVoItem.customerId = this.state.currCustomerItem.id;
            let wholesaleInsertVo = {workerId:0,costs:[],commodities:[]};
            wholesaleInsertVo.workerId = this.state.wholesaleInsertVoItem.workerId==null?0:(this.state.wholesaleInsertVoItem.workerId==0?-1:this.state.wholesaleInsertVoItem.workerId);
            wholesaleInsertVo.customerAlias = this.state.wholesaleInsertVoItem.customerAlias;
            wholesaleInsertVo.customerId = this.state.wholesaleInsertVoItem.customerId;
            wholesaleInsertVo.advanceMoney = this.state.wholesaleInsertVoItem.advanceMoney;
            wholesaleInsertVo.money = this.state.wholesaleInsertVoItem.money;
            wholesaleInsertVo.billMoney = this.state.wholesaleInsertVoItem.billMoney;
            wholesaleInsertVo.state = 0;//0stringUtil.language(1145)，1stringUtil.language(1144)
            wholesaleInsertVo.describe = this.state.wholesaleInsertVoItem.describe;
            for(let i=0;i<this.state.wholesaleInsertVoItem.costs.length;i++){
                let costsTemp = {money:0};
                costsTemp.costClauseId = this.state.wholesaleInsertVoItem.costs[i].costClauseId;
                costsTemp.money = this.state.wholesaleInsertVoItem.costs[i].money;
                wholesaleInsertVo.costs.push(costsTemp);
            }
            for(let i=0;i<this.state.wholesaleInsertVoItem.commodities.length;i++){
                let commodityTemp = {costs:[]};
                commodityTemp.cargoId = this.state.wholesaleInsertVoItem.commodities[i].cargoId;
                commodityTemp.buyerCode = this.state.wholesaleInsertVoItem.commodities[i].buyerCode;
                commodityTemp.targetType = this.state.wholesaleInsertVoItem.commodities[i].targetType;
                commodityTemp.targetId = this.state.wholesaleInsertVoItem.commodities[i].targetId;
                commodityTemp.commodityId = this.state.wholesaleInsertVoItem.commodities[i].commodityId;
                commodityTemp.cargoCommodityId = this.state.wholesaleInsertVoItem.commodities[i].cargoCommodityId;
                commodityTemp.standard = this.state.wholesaleInsertVoItem.commodities[i].standard;
                commodityTemp.number = this.state.wholesaleInsertVoItem.commodities[i].number;
                commodityTemp.totalWeight = this.state.wholesaleInsertVoItem.commodities[i].totalWeight;
                commodityTemp.skinWeight = this.state.wholesaleInsertVoItem.commodities[i].skinWeight;
                commodityTemp.reduceWeight = this.state.wholesaleInsertVoItem.commodities[i].reduceWeight;
                commodityTemp.weight = this.state.wholesaleInsertVoItem.commodities[i].weight;
                commodityTemp.unitPrice = this.state.wholesaleInsertVoItem.commodities[i].unitPrice;
                commodityTemp.ownerUnitPrice = this.state.wholesaleInsertVoItem.commodities[i].ownerUnitPrice?this.state.wholesaleInsertVoItem.commodities[i].ownerUnitPrice:0;
                commodityTemp.totalUnitPrice = this.state.wholesaleInsertVoItem.commodities[i].totalUnitPrice;
                commodityTemp.mode = this.state.wholesaleInsertVoItem.commodities[i].mode;
                commodityTemp.goodsMoney = this.state.wholesaleInsertVoItem.commodities[i].goodsMoney;
                if (this.state.wholesaleInsertVoItem.commodities[i].costs==null)this.state.wholesaleInsertVoItem.commodities[i].costs=[];
                for(let j=0;j<this.state.wholesaleInsertVoItem.commodities[i].costs.length;j++){
                    let costsSource = JSON.parse(JSON.stringify(this.state.wholesaleInsertVoItem.commodities[i].costs[j]));
                    commodityTemp.costs.push({
                        costClauseId:costsSource.costClauseId!=null?costsSource.costClauseId:0,
                        money:costsSource.money!=null?costsSource.money:0,
                    });
                }
                wholesaleInsertVo.commodities.push(commodityTemp);
            }
            if(wholesaleInsertVo.customerId==null||wholesaleInsertVo.customerId==0){
                ToastBox.error(stringUtil.language(457)+'！');return;
            }
            if(this.state.isVisiblity){return;}
			this.state.isVisiblity = true;
            stringUtil.httpRequest(urlUtil.SALE_WHOLESALE_URL,wholesaleInsertVo,['json','post',1],(successResponse)=>{
                if (successResponse.status==200) {
                    ToastBox.success(stringUtil.language(562));
                    global.isCloseThis=1;
                    this.props.navigationStacks.splice(this.props.navigationStacks.length-1,1);
                    this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'',mainStacks:this.props.navigationStacks}});
                }else{this.state.isVisiblity=false;ToastBox.error(successResponse.message+`(${successResponse.status})`);}
            },(errorResponse)=>{this.state.isVisiblity=false;ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
        }
    }

    render(){
        this.state.wholesaleInsertVoItem.totalNumber = 0;
        this.state.wholesaleInsertVoItem.totalWeight = 0;
        this.state.wholesaleInsertVoItem.totalGoodsMoney = 0;
        this.state.wholesaleInsertVoItem.totalOtherMoneyTotal = 0;
        return(<div style={{width:'98.5%',height:'97%',padding:'0.5%'}}>
            <div style={{width:'100%',height:'6%',display:'flex',flexDirection:'row',alignItems:'center',backgroundColor:'#ECECEC'}}>
                <TabTopActivity viewHeight='100%' viewWidth='28%' context={this} navigationStacks={this.props.navigationStacks} isBackPrevPage={1}/>
                <div><DatePicker locale='zhCN' selected={new Date(this.state.wholesaleInsertVoItem.createTime)} onChange={(date) => {//showTimeSelect timeFormat="HH:mm" timeIntervals={15} 
                        this.state.wholesaleInsertVoItem.createTime = date.valueOf();
                        this.setState({wholesaleInsertVoItem:this.state.wholesaleInsertVoItem});
                    }} />
                </div>
                <div style={{marginLeft:20,display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <span>{stringUtil.language(891)}:</span>
                    <Select showSearch defaultValue={stringUtil.language(1238)} style={{width:150,height:30,fontSize:10}} onChange={(event)=>{
                        this.state.choiceBuyer = JSON.parse(event);
                        // localStorage.setItem("choiceBuyer",JSON.stringify(this.state.choiceBuyer));//设置缓存
                    }}><option value={JSON.stringify({id:-1,name:stringUtil.language(1238)})}>{stringUtil.language(1238)}</option>
                        {this.state.buyersAll.map((item,key)=>{
                        return(<option value={JSON.stringify(item)} key={key}>{item.name} ({stringUtil.language(1156)}:{item.code})</option>)
                        })}
                    </Select>
                </div>
            </div>
            <div style={{height:'94%',display:'flex',flexDirection:'row',backgroundColor:'#FFF'}}>
                <div style={{width:'35%',backgroundColor:'#FFF',paddingLeft:5,paddingRight:5,position:'relative'}}>
                    <div style={{height:'8%',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <span style={{fontSize:20,fontWeight:'bold'}}>{stringUtil.subStr(this.state.currCustomerItem.noteName,10)}</span>
                            {this.state.currCustomerItem.type==1?<input style={{height:25,padding:0}} onChange={(event)=>{
                                this.state.wholesaleInsertVoItem.customerAlias=event.target.value;
                            }} placeholder={stringUtil.language(132)}/>:
                            <span style={{color:'#F00',fontSize:12}}>{this.state.currCustomerItem.warning!=0?stringUtil.language(1066)+arithUtil.fenToYuan(this.state.currCustomerItem.quota)+','+stringUtil.language(1164)+arithUtil.fenToYuan(this.state.currCustomerItem.arrears):''}</span>}
                        </div>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <span style={{height:'40px',color:'#2B8D53',fontSize:20,lineHeight:'40px',textDecorationLine:'underline',textDecorationColor:'#2B8D53',cursor:'pointer'}} onClick={()=>{if(this.state.regIndex!=0)this.setState({customerItemDatas:this.state.customerItemAll,regIndex:0});}}>{stringUtil.language(1014)}</span>
                            <span style={{color:'#2B8D53',fontSize:15,marginLeft:15,cursor:'pointer'}} onClick={()=>{if(this.state.regIndex!=1)this.setState({regIndex:1});}}>{stringUtil.language(1122)}:{this.state.wholesaleInsertVoItem.workerName?this.state.wholesaleInsertVoItem.workerName:(localStorage.getItem("currentStallItem")!=null?(()=>{
                                for(let i=0;i<this.state.saleWorkers.length;i++)if(JSON.parse(localStorage.getItem("currentStallItem")).workerId==this.state.saleWorkers[i].id) return this.state.saleWorkers[i].noteName;
                            })():stringUtil.language(431))}</span>
                        </div>
                    </div>
                    {this.state.regIndex==1?<div style={{width:150,height:arithUtil.ProportionTo(document.body.clientHeight,0.6),borderStyle:'solid',borderColor:'#696969',borderWidth:1,backgroundColor:'#FFF',position:'absolute',top:'8%',right:0,paddingLeft:5,paddingRight:5}}>
                        <div style={{height:'7%',display:'flex',flexDirection:'row',alignItems:'center',borderBottomStyle:'solid',borderBottomColor:'#DCDCDC',borderBottomWidth: 1}}>
                            <input id='workerSerial' style={{width:100,height:25,fontSize:18,color:'#F00',fontWeight:'bold',borderStyle:'solid',borderColor:'#d0d0d0',borderWidth:1,borderRadius:2,padding:0,textAlign:'center'}} type='number' onChange={(event)=>{
                                this.state.regIndexVal=event.target.value!=''?parseInt(event.target.value):-1;
                            }} placeholder={stringUtil.language(1104)}/>
                        </div>
                        <div style={{height:'91%',overflow:'auto'}}>
                            {this.state.saleWorkers.map((item,key)=>{
                                return(<div style={{height:30,display:'flex',flexDirection:'row',alignItems:'center',paddingLeft:5,cursor:'pointer'}} key={key} onClick={()=>{
                                    this.state.wholesaleInsertVoItem.workerId=item.id;
                                    this.state.wholesaleInsertVoItem.workerName=item.noteName;
                                    this.setState({regIndex:-1});
                                }}><span>{(key+1)+'.'+stringUtil.subStr(item.noteName,5)}</span></div>)
                            })}
                        </div>
                    </div>:''}
                    {this.state.regIndex==0?<div style={{width:'60%',height:arithUtil.ProportionTo(document.body.clientHeight,0.6),borderStyle:'solid',borderColor:'#696969',borderWidth:1,backgroundColor:'#FFF',position:'absolute',left:0,top:'8%',paddingLeft:5,paddingRight:5}}>
                        <div style={{height:'7%',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',borderBottomStyle:'solid',borderBottomColor:'#DCDCDC',borderBottomWidth: 1}}>
                            <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                <input id='customerSearch' style={{display:'flex',flexGrow:1,height:25,borderStyle:'solid',borderColor:'#d0d0d0',borderWidth:1,borderRadius:2,padding:0,paddingLeft:5}} onChange={(event)=>{
                                    this.state.keySearchCus = event.target.value;
                                    if(this.state.keySearchCus==null||this.state.keySearchCus=='')this.setState({customerItemDatas:this.state.customerItemAll});
                                    else this.searchCustomers();
                                }} placeholder={stringUtil.language(32)}/>
                                <input id='customerSerial' style={{width:60,height:25,fontSize:18,color:'#F00',fontWeight:'bold',borderStyle:'solid',borderColor:'#d0d0d0',borderWidth:1,borderRadius:2,padding:0,textAlign:'center'}} type='number' onChange={(event)=>{
                                    this.state.regIndexVal=event.target.value!=''?parseInt(event.target.value):-1;
                                }} placeholder={stringUtil.language(1104)}/>
                            </div>
                            <div style={{width:40,height:25,color:'#FFF',borderRadius:3,backgroundColor:'#52A056',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                                this.state.currItem = null;
                                this.state.currDistrictA = null;
                                this.state.currDistrictB = null;
                                this.state.currDistrictC = null;
                                this.setState({addShowPopIndex:this.state.addShowPopIndex+1,customerAddShowPop:!this.state.customerAddShowPop});
                            }}>{stringUtil.language(1129)}</div>
                        </div>
                        <div style={{height:'91%',overflow:'auto'}}>
                            {(this.state.customerItemDatas?this.state.customerItemDatas:this.state.customerItemAll).map((item,key)=>{
                                return(<div style={{height:60,display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',paddingLeft:5,paddingRight:5,borderBottomStyle:'solid',borderBottomColor:'#F0F0F0',borderBottomWidth:1,cursor:'pointer'}} key={key} onClick={()=>{
                                    if (item.warning!=2) {
                                        this.state.currCustomerItem = JSON.parse(JSON.stringify(item));
                                        this.state.isPrintTag=this.state.currCustomerItem.type==1?0:(localStorage.getItem("printShareInfo")&&JSON.parse(localStorage.getItem("printShareInfo")).wholesale&&JSON.parse(localStorage.getItem("printShareInfo")).wholesale.arrearsCustomer==1?1:0);
                                        this.setState({regIndex:-1});
                                    }else{ToastBox.error(stringUtil.language(50));}
                                }}><div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                        <img style={{width:40,height:40,borderRadius:20}} loading="lazy" src={arithUtil.imageShow(item.headIcon)} title={stringUtil.language(84)}/>
                                        <div style={{marginLeft:5,display:'flex',flexDirection:'column',justifyContent:'center'}}>
                                            <span style={{color:item.warning!=2?'#696969':'#D3D3D3',fontWeight:item.warning!=2?'bold':'normal',fontSize:17}}>{(key+1)+'.'+item.noteName}</span>
                                            <span style={{color:'#FF8C00',fontSize:14}}>{item.warning==1||item.quota!=0?(item.warning==2?stringUtil.language(458)+'/':'')+stringUtil.language(1066)+arithUtil.fenToYuan(item.quota):(item.warning==2?stringUtil.language(458):'')}</span>
                                        </div>
                                    </div>
                                    <span style={{color:'#F00'}}>{arithUtil.fenToYuan(item.arrears)}</span>
                                </div>)
                            })}
                        </div>
                    </div>:''}
                    <div style={arithUtil.stylesAdd(styles.tableTitle,{height:'5%'})}>
                        <span style={{width:'7.5%',color:'#A9A9A9',textAlign:'center'}}></span>
                        <div style={{width:'82.5%',height:'100%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <span style={arithUtil.stylesAdd(styles.tableTd,{width:'37%',color:'#A9A9A9'})}>{stringUtil.language(518)}</span>
                            <span style={arithUtil.stylesAdd(styles.tableTd,{width:'15%',color:'#A9A9A9'})}>{stringUtil.language(1165)}</span>
                            <span style={arithUtil.stylesAdd(styles.tableTd,{width:'17%',color:'#A9A9A9'})}>{stringUtil.language(1022)}</span>
                            <span style={arithUtil.stylesAdd(styles.tableTd,{width:'14%',color:'#A9A9A9'})}>{stringUtil.language(1050)}</span>
                            <span style={arithUtil.stylesAdd(styles.tableTd,{width:'17%',color:'#A9A9A9'})}>{stringUtil.language(1068)}</span>
                        </div>
                        <div style={arithUtil.stylesAdd(styles.tableTd,{width:'10%',height:'100%',color:'#A9A9A9'})}>{stringUtil.language(1069)}</div>
                    </div>
                    <div style={{width:'100%',height:'57%',overflow:'auto'}}>
                        {this.state.wholesaleInsertVoItem.commodities.map((item,key)=>{
                            let otherMoneyTotal = 0;
                            for(let i=0;i<item.costs.length;i++){
                                let costsItem = item.costs[i];
                                if (costsItem.calMode==0) {//stringUtil.language(868)
                                    this.state.wholesaleInsertVoItem.commodities[key].costs[i].money = (item.number).mul(costsItem.moneyScal).round();
                                }else if (costsItem.calMode==1) {//stringUtil.language(869)
                                    this.state.wholesaleInsertVoItem.commodities[key].costs[i].money = (arithUtil.keToWeight(item.weight,0)).mul(costsItem.moneyScal).roundDouble();
                                }else if (costsItem.calMode==2) {//stringUtil.language(396)
                                    this.state.wholesaleInsertVoItem.commodities[key].costs[i].money = (item.goodsMoney).mul(costsItem.moneyScal).mul(0.01).round();
                                }else{
                                    this.state.wholesaleInsertVoItem.commodities[key].costs[i].money = parseFloat(costsItem.moneyScal).round();
                                }
                                otherMoneyTotal = otherMoneyTotal.add(costsItem.money);
                            }
                            this.state.wholesaleInsertVoItem.totalNumber = (this.state.wholesaleInsertVoItem.totalNumber).add(item.number);
                            this.state.wholesaleInsertVoItem.totalWeight = (this.state.wholesaleInsertVoItem.totalWeight).add(item.weight);
                            this.state.wholesaleInsertVoItem.totalGoodsMoney = (this.state.wholesaleInsertVoItem.totalGoodsMoney).add(item.goodsMoney).round();
                            this.state.wholesaleInsertVoItem.totalOtherMoneyTotal = (this.state.wholesaleInsertVoItem.totalOtherMoneyTotal).add(otherMoneyTotal).round();
                            return(<div style={{height:arithUtil.pxToDp(40),display:'flex',flexDirection:'row',alignItems:'center'}} key={key}>
                                <i className="iconfontIndexCss" style={{width:'7.5%',height:'100%',fontSize:15,color:'#F00',display:'flex',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                                    this.state.wholesaleInsertVoItem.commodities.splice(key, 1);
                                    this.state.vegEditShow = false;
                                    this.setState({wholesaleInsertVoItem:this.state.wholesaleInsertVoItem});
                                }}>&#xe612;</i>
                                <div style={{width:'82.5%',height:'100%',cursor:'pointer'}} onClick={()=>{
                                    this.state.currWholesaleCommodityVo = JSON.parse(JSON.stringify(item));
                                    this.state.currWholesaleCommodityVo.buyerCode=this.state.choiceBuyer?this.state.choiceBuyer.code:'';
                                    this.state.currWholesaleCommodityVo.totalWeight = arithUtil.keToWeight(this.state.currWholesaleCommodityVo.totalWeight,this.state.weightMode);
                                    this.state.currWholesaleCommodityVo.skinWeight = arithUtil.keToWeight(this.state.currWholesaleCommodityVo.skinWeight,this.state.weightMode);
                                    if(this.state.currWholesaleCommodityVo.mode==1){
                                        this.state.currWholesaleCommodityVo.unitPrice = arithUtil.fenToYuan(this.state.currWholesaleCommodityVo.unitPrice);
                                        this.state.currWholesaleCommodityVo.totalUnitPrice = arithUtil.fenToYuan(this.state.currWholesaleCommodityVo.totalUnitPrice);
                                    }else this.state.currWholesaleCommodityVo.unitPrice = arithUtil.unitPriceTo(arithUtil.fenToYuan(this.state.currWholesaleCommodityVo.unitPrice),0,this.state.weightMode);
                                    this.state.currWholesaleCommodityVoKey = key;
                                    this.state.currWholesaleCommodityVo.weight=arithUtil.keToWeight(this.state.currWholesaleCommodityVo.weight,this.state.weightMode);
                                    this.state.currCommodityEditsView='numberEdit';
                                    this.setState({vegEditShow:true},()=>{document.getElementById('commoditySerial').value='';document.getElementById('numberEdit').focus();});
                                }}><div style={{height:arithUtil.pxToDp(25),display:'flex',flexDirection:'row',alignItems:'flex-end'}}>
                                        <span style={arithUtil.stylesAdd(styles.tableTd,{width:'37%',fontSize:14,fontWeight:'bold',textAlign:'left',textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'})}>{key+1}.{stringUtil.showCode()==1||stringUtil.showCode()==2?(item.commodityCode?'('+item.commodityCode+')':''):''}{!stringUtil.showCode()||stringUtil.showCode()==2?item.commodityName:''}</span>
                                        <span style={arithUtil.stylesAdd(styles.tableTd,{width:'15%',fontSize:14,fontWeight:'bold'})}>{item.mode==2?item.slaveNumber:item.number}</span>
                                        <span style={arithUtil.stylesAdd(styles.tableTd,{width:'17%',fontSize:14,fontWeight:'bold'})}>{arithUtil.keToWeight(item.weight,this.state.weightMode)}</span>
                                        {item.mode==0?<span style={arithUtil.stylesAdd(styles.tableTd,{width:'14%'})}>{arithUtil.unitPriceTo(arithUtil.fenToYuan(item.unitPrice,2),0,this.state.weightMode)}</span>:
                                            <span style={arithUtil.stylesAdd(styles.tableTd,{width:'14%'})}>{arithUtil.fenToYuan(item.unitPrice,2)}</span>}
                                        <span style={arithUtil.stylesAdd(styles.tableTd,{width:'17%',fontSize:14,fontWeight:'bold'})}>{arithUtil.fenToYuan(item.goodsMoney)}</span>
                                    </div>
                                    <div style={{height:arithUtil.pxToDp(15),display:'flex',flexDirection:'row',alignItems:'flex-start'}}>
                                        <span style={arithUtil.stylesAdd(styles.tableTd,{width:'52%',color:'#52A056',fontSize:10,textAlign:'left'})}>{item.targetType==1?stringUtil.language(1051)+(item.targetName?'_'+stringUtil.subStr(item.targetName,5):''):stringUtil.subStr(item.targetName,5)}/{item.cargoNo}</span>
                                        {item.mode==0?<span style={arithUtil.stylesAdd(styles.tableTd,{width:'51%',color:'#52A056',fontSize:10,textAlign:'left'})}>{arithUtil.keToWeight(item.totalWeight,this.state.weightMode)}-{arithUtil.keToWeight(item.skinWeight,this.state.weightMode)}-{arithUtil.keToWeight(item.reduceWeight,this.state.weightMode)}</span>:
                                        <span style={arithUtil.stylesAdd(styles.tableTd,{width:'31%',color:'#52A056',fontSize:10,textAlign:'left'})}>{item.number+'X'+arithUtil.keToWeight(item.standard,this.state.weightMode)}</span>}
                                        <span style={{width:'17%',color:'#52A056',fontSize:10,textAlign:'right'}}>{item.buyerCode?item.buyerCode:''}</span>
                                    </div>
                                </div>
                                <div style={arithUtil.stylesAdd(styles.tableTd,{width:'10%',height:'100%',cursor:'pointer'})} onClick={()=>{
                                    this.state.currWholesaleCommodityVo = JSON.parse(JSON.stringify(item));
                                    this.setState({vegEditShow:false,costsShowPop:true});
                                }}>{arithUtil.fenToYuan(otherMoneyTotal)}</div>
                            </div>)
                        })}
                    </div>
                    <div style={{width:'100%',height:'30%'}}>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'flex-end',borderBottomStyle:'solid',borderBottomWidth:1,borderBottomColor:'#C0C0C0'}}>
                            <span style={arithUtil.stylesAdd(styles.tableTd,{width:'35.5%',color:'#D2855D',textAlign:'left'})}>{stringUtil.language(890)}</span>
                            <span style={arithUtil.stylesAdd(styles.tableTd,{width:'13.5%',color:'#D2855D'})}>{this.state.wholesaleInsertVoItem.totalNumber}</span>
                            <span style={arithUtil.stylesAdd(styles.tableTd,{color:'#D2855D'})}>{arithUtil.keToWeight(this.state.wholesaleInsertVoItem.totalWeight,this.state.weightMode)}</span>
                            <span style={arithUtil.stylesAdd(styles.tableTd,{width:'11%',color:'#D2855D'})}></span>
                            <span style={arithUtil.stylesAdd(styles.tableTd,{color:'#D2855D'})}>{arithUtil.fenToYuan(this.state.wholesaleInsertVoItem.totalGoodsMoney)}</span>
                            <span style={arithUtil.stylesAdd(styles.tableTd,{width:'10%',color:'#D2855D'})}>{arithUtil.fenToYuan(this.state.wholesaleInsertVoItem.totalOtherMoneyTotal)}</span>
                        </div>
                        <div style={{height:'28%',display:'flex',flexDirection:'row'}}>
                            <div style={{width:'70%',height:'100%'}}>
                                {this.state.wholesaleInsertVoItem.costs.length!=0?<div style={{width:'100%',height:'100%',overflow:'auto'}}>
                                <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap'}}>
                                    {this.state.wholesaleInsertVoItem.costs.map((item,key)=>{
                                        if (item.costClauseCalMode==0) {//stringUtil.language(868)
                                            this.state.wholesaleInsertVoItem.costs[key].money = (this.state.wholesaleInsertVoItem.totalNumber).mul(item.moneyScal).round();
                                        }else if (item.costClauseCalMode==1) {//stringUtil.language(869)
                                            this.state.wholesaleInsertVoItem.costs[key].money = (arithUtil.keToWeight(this.state.wholesaleInsertVoItem.totalWeight,0)).mul(item.moneyScal).roundDouble();
                                        }else if (item.costClauseCalMode==2) {//stringUtil.language(396)
                                            this.state.wholesaleInsertVoItem.costs[key].money = (this.state.wholesaleInsertVoItem.totalGoodsMoney).mul(item.moneyScal).mul(0.01).round();
                                        }else{
                                            this.state.wholesaleInsertVoItem.costs[key].money = parseFloat(item.moneyScal).round();
                                        }
                                        return(<div style={{width:'45%',height:30,display:'flex',flexDirection:'row',alignItems:'center',paddingLeft:2}} key={key}>
                                            <span style={{color:'#808080',fontSize:16}}>{stringUtil.subStr(item.costClauseName,5)}：</span>
                                            {item.costClauseCalMode==0?<div style={{width:'40%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                                                <span style={{width:'100%',fontSize:16}}>{arithUtil.fenToYuan(item.money)/*stringUtil.language(1021)*/}</span></div>:
                                            item.costClauseCalMode==1?<div style={{width:'40%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                                                <span style={{width:'100%',fontSize:16}}>{arithUtil.fenToYuan(item.money)/*stringUtil.language(1022)*/}</span></div>:
                                            item.costClauseCalMode==2?<div style={{width:'40%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                                                <span style={{width:'100%',fontSize:16}}>{arithUtil.fenToYuan(item.money)/*stringUtil.language(355)*/}</span></div>:
                                            <div style={{width:'40%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                                                <span style={{width:'100%',fontSize:16}}>{arithUtil.fenToYuan(item.money)/*stringUtil.language(1029)*/}</span></div>}
                                        </div>)
                                    })}
                                </div>
                                </div>:<div style={{width:'100%',height:'100%',display:'flex',justifyContent:'center',alignItems:'center'}}><span style={{color:'#DCDCDC'}}>{stringUtil.language(52)}</span></div>}
                            </div>
                            <div style={{width:'30%',display:'flex',flexDirection:'column',justifyContent:'center'}}>
                                {(()=>{this.state.yakuangxinxi={number:0,money:0};
                                    for(let i=0;i<this.state.wholesaleInsertVoItem.commodities.length;i++){
                                        if(this.state.wholesaleInsertVoItem.commodities[i].systemCommodityId==-1){
                                            this.state.yakuangxinxi.number=this.state.yakuangxinxi.number+this.state.wholesaleInsertVoItem.commodities[i].number;
                                            this.state.yakuangxinxi.money=(this.state.yakuangxinxi.money).add(this.state.wholesaleInsertVoItem.commodities[i].goodsMoney);
                                        }
                                    }
                                })()}
                                {this.state.yakuangxinxi.number?<span style={{fontSize:14}}>{stringUtil.language(1157)}:{this.state.yakuangxinxi.number}个,{arithUtil.fenToYuan(this.state.yakuangxinxi.money)}{stringUtil.currency(1)}</span>:''}
                                <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                    <span style={{fontSize:16}}>{stringUtil.language(1161)}:</span><input id='advance' style={arithUtil.stylesAdd(styles.textInput,{width:'50px',height:25})} type='number' placeholder='0' onChange={(event)=>{
                                        this.state.wholesaleInsertVoItem.advanceMoney = event.target.value?arithUtil.yuanToFen(event.target.value):0;
                                        this.setState({wholesaleInsertVoItem:this.state.wholesaleInsertVoItem});
                                    }}/>
                                </div>
                            </div>
                        </div>
                        <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                            <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                <span style={{fontSize:16}}>{stringUtil.language(858)}</span>
                                <input id='describe' style={arithUtil.stylesAdd(styles.textInput,{width:170,height:25,fontSize:16,padding:0,borderBottomStyle:'solid',borderBottomWidth:1,borderBottomColor:'#d0d0d0',textAlign:'left'})} placeholder={stringUtil.language(1010)+stringUtil.language(1033)+'..（非必填）'} onChange={(event)=>{
                                    this.state.wholesaleInsertVoItem.describe = event.target.value;
                                }}/>
                            </div>
                            <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginTop:arithUtil.pxToDp(7)}}>
                                <span style={{fontSize:16}}>{stringUtil.language(880)}</span>
                                {(()=>{
                                    let totalGoodsMoneyVal = this.state.wholesaleInsertVoItem.totalGoodsMoney;
                                    let totalOtherMoneyTotalVal = this.state.wholesaleInsertVoItem.totalOtherMoneyTotal;
                                    let advanceMoneyVal = this.state.wholesaleInsertVoItem.advanceMoney;
                                    let costsMoneyVal = 0;
                                    if (this.state.wholesaleInsertVoItem.costs.length>0) {
                                        for(let i=0;i<this.state.wholesaleInsertVoItem.costs.length;i++){
                                            let costsItemMoney = this.state.wholesaleInsertVoItem.costs[i].money;
                                            costsMoneyVal = costsMoneyVal.add(costsItemMoney);
                                        }
                                    }
                                    this.state.wholesaleInsertVoItem.money = totalGoodsMoneyVal.add(totalOtherMoneyTotalVal).add(advanceMoneyVal).add(costsMoneyVal).round();
                                    this.state.wholesaleInsertVoItem.billMoney = 0;
                                    this.state.wholesaleTotalMoneyTemp = this.state.wholesaleInsertVoItem.money;
                                    if((localStorage.getItem("shopConfigInfo")!=null&&JSON.parse(localStorage.getItem("shopConfigInfo")).isRound!=null&&JSON.parse(localStorage.getItem("shopConfigInfo")).isRound==0)&&this.state.currCustomerItem.type==1){//临时stringUtil.language(1048)取整
                                        let madeAllMoney = arithUtil.getRoundMoney(this.state.wholesaleInsertVoItem.money);
                                        if (madeAllMoney!=this.state.wholesaleInsertVoItem.money){//在取整规则范围内
                                            this.state.wholesaleInsertVoItem.billMoney = madeAllMoney.sub(this.state.wholesaleInsertVoItem.money,2);
                                            this.state.wholesaleTotalMoneyTemp = madeAllMoney;
                                        }
                                    }
                                })()}
                                <span style={{color:'#F00',fontSize:23,fontWeight:'bold'}}>{arithUtil.fenToYuan(this.state.wholesaleTotalMoneyTemp)}</span>
                            </div>
                        </div>
                        <div style={{display:'flex',flexDirection:'row',justifyContent:'space-around',marginTop:5}}>
                            <div style={{width:60,height:35,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{this.setState({isPrintTag:this.state.isPrintTag?0:1});/*1不{stringUtil.language(1045)}，null0{stringUtil.language(1045)}*/}}>
                                <span style={{color:this.state.isPrintTag!=1?'#D3855C':'#A9A9A9',textDecorationStyle:'solid',textDecorationLine:'underline',textDecorationColor:this.state.isPrintTag!=1?'#D3855C':'#A9A9A9'}}>{this.state.isPrintTag!=1?'✔'+stringUtil.language(1045):stringUtil.language(1045)}</span>
                            </div>
                            <span style={{width:130,height:'30px',color:'#FFF',lineHeight:'30px',borderStyle:'solid',borderColor:'#F00',borderWidth:this.state.currCommodityEditsView=='confirm'?5:0,borderRadius:3,backgroundColor:'#2B8D53',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                                this.submitWholesale(1);
                            }}>{stringUtil.language(859)}</span>
                            <span style={{width:130,height:'30px',color:'#FFF',lineHeight:'30px',borderStyle:'solid',borderColor:'#F00',borderWidth:this.state.currCommodityEditsView=='save'?5:0,borderRadius:3,backgroundColor:'#d2855d',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                                this.submitWholesale(0);
                            }}>暂 存</span>
                        </div>
                    </div>
                </div>
                {/* 右边 */}
                <div style={{width:'65%',backgroundColor:'#F5F3F3',padding:5,position:'relative'}}>
                    <div style={{height:'13%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <i className="iconfontIndexCss" style={{fontSize:30,color:'#21A056'}}>&#xe643;</i>
                        <div style={{width:'100%',overflowY:'hidden',overflow:'auto',whiteSpace:'nowrap'/*水平滚动*/,display:'flex',flexDirection:'row'}}>
                            {this.state.wholesaleCargosData.map((item,key)=>{
                                return(<div style={{display:'flex',flexDirection:'column',justifyContent:'center',marginLeft:15,cursor:'pointer'}} key={key} onClick={()=>{
                                    this.state.currCargoItem = JSON.parse(JSON.stringify(item));
                                    this.warehouseCommodityItemSearch();
                                    this.setState({currCargoItem:this.state.currCargoItem});
                                }}>
                                    <span style={{color:this.state.currCargoItem.id==item.id?'#52A056':'#A9A9A9',fontSize:16,fontWeight:'bold'}}>{item.cargoMode==1?stringUtil.language(1051)+(item.targetName?'_'+stringUtil.subStr(item.targetName,6):''):stringUtil.subStr(item.targetName,7)}</span>
                                    <span style={{color:this.state.currCargoItem.id==item.id?'#52A056':'#C0C0C0',fontSize:12}}>{item.cargoNo}{stringUtil.language(1176)}</span>
                                </div>)
                            })}
                        </div>
                    </div>
                    <div style={{height:'87%',display:'flex',flexDirection:'row'}}>
                        <div style={{width:'90%',height:'100%',display:'flex',flexDirection:'column'}} >
                            <div style={{width:'100%',display:'flex',flexDirection:'row',flexGrow:1,overflow:'auto'}}>
                            <div style={{width:'100%',height:((this.state.warehouseCommodityItemData.length).div(4,0)).mul(60),display:'flex',flexDirection:'row',flexWrap:'wrap'}}>
                                {this.state.warehouseCommodityItemData.map((item,key)=>{
                                    let isHavaChooseObj=null;let isHavaChooseObjKey=-1,chooseKind=0,chooseNumber=0;
                                    for(let i=0;i<this.state.wholesaleInsertVoItem.commodities.length;i++){
                                        let chooseItem = this.state.wholesaleInsertVoItem.commodities[i];
                                        if (chooseItem.warehouseId==item.warehouseId && chooseItem.cargoCommodityId==item.cargoCommodityId) {
                                            isHavaChooseObj = this.state.wholesaleInsertVoItem.commodities[i];
                                            isHavaChooseObjKey = i;
                                            chooseKind=chooseKind+1;chooseNumber=chooseNumber+chooseItem.number;//break;
                                        }
                                    }
                                    return(<div style={{width:'22.5%',borderStyle:'solid',borderWidth:1,borderColor:'#A9A9A9',borderRadius:5,backgroundColor:'#FFF',padding:3,marginLeft:5,marginBottom:5,position:'relative'}} key={key}>
                                        <div style={{width:'100%',display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                                            if(this.state.sort){
                                                this.state.keyTagVal = key;
                                                this.setState({sortShowPop:true});return;
                                            }
                                            let currEditWarehouseCommodityItem = JSON.parse(JSON.stringify(item));
                                            for(let i=0;i<currEditWarehouseCommodityItem.costs.length;i++){
                                                currEditWarehouseCommodityItem.costs[i].moneyScal = currEditWarehouseCommodityItem.costs[i].money;
                                                currEditWarehouseCommodityItem.costs[i].money = 0;
                                            }
                                            this.state.currWholesaleCommodityVo = {
                                                warehouseId:currEditWarehouseCommodityItem.warehouseId,
                                                cargoCommodityId:currEditWarehouseCommodityItem.cargoCommodityId,
                                                cargoId:currEditWarehouseCommodityItem.cargoId,
                                                systemCommodityId:currEditWarehouseCommodityItem.systemCommodityId,
                                                commodityId:currEditWarehouseCommodityItem.commodityId,
                                                commodityName:currEditWarehouseCommodityItem.commodityName,
                                                commodityCode:currEditWarehouseCommodityItem.commodityCode,
                                                targetType:currEditWarehouseCommodityItem.targetType,
                                                targetId:currEditWarehouseCommodityItem.targetId,
                                                targetName:currEditWarehouseCommodityItem.targetName,
                                                cargoNo:currEditWarehouseCommodityItem.cargoNo,
                                                unitMaster:currEditWarehouseCommodityItem.unitMaster,
                                                mode:currEditWarehouseCommodityItem.wholesaleMode,//0stringUtil.language(1022)，1stringUtil.language(1021)
                                                number:0,
                                                totalWeight:0,
                                                skinWeight:0,
                                                weight:0,
                                                unitPrice:currEditWarehouseCommodityItem.wholesaleMode?arithUtil.fenToYuan(currEditWarehouseCommodityItem.numMakePrice):arithUtil.unitPriceTo(arithUtil.fenToYuan(currEditWarehouseCommodityItem.weightMakePrice),0,this.state.weightMode),
                                                totalUnitPrice:arithUtil.fenToYuan(currEditWarehouseCommodityItem.numMakePrice),
                                                numMakePrice:arithUtil.fenToYuan(currEditWarehouseCommodityItem.numMakePrice),
                                                weightMakePrice:arithUtil.unitPriceTo(arithUtil.fenToYuan(currEditWarehouseCommodityItem.weightMakePrice),0,this.state.weightMode),
                                                standard:currEditWarehouseCommodityItem.standard,
                                                commodityReduce:currEditWarehouseCommodityItem.reduce,
                                                reduceWeight:0,
                                                goodsMoney:0,
                                                costs:currEditWarehouseCommodityItem.costs,
                                                buyerCode:this.state.choiceBuyer?this.state.choiceBuyer.code:''
                                            };
                                            this.state.currWholesaleCommodityVoKey = -1;
                                            // if (isHavaChooseObj!=null) {
                                            //     this.state.currWholesaleCommodityVo = JSON.parse(JSON.stringify(isHavaChooseObj));
                                            //     this.state.currWholesaleCommodityVo.totalWeight = arithUtil.keToWeight(this.state.currWholesaleCommodityVo.totalWeight,this.state.weightMode);
                                            //     this.state.currWholesaleCommodityVo.skinWeight = arithUtil.keToWeight(this.state.currWholesaleCommodityVo.skinWeight,this.state.weightMode);
                                            //     if(this.state.currWholesaleCommodityVo.mode==1){
                                            //         this.state.currWholesaleCommodityVo.unitPrice = arithUtil.fenToYuan(this.state.currWholesaleCommodityVo.unitPrice);
                                            //     }else this.state.currWholesaleCommodityVo.unitPrice = arithUtil.unitPriceTo(arithUtil.fenToYuan(this.state.currWholesaleCommodityVo.unitPrice),0,this.state.weightMode);
                                            //     this.state.currWholesaleCommodityVoKey = isHavaChooseObjKey;
                                            // }
                                            this.state.currCommodityEditsView='numberEdit';
                                            this.setState({vegEditShow:true},()=>{document.getElementById('commoditySerial').value='';document.getElementById(this.state.currCommodityEditsView).focus();});
                                        }}>{this.state.sort?<div style={{width:50,height:'100%',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',borderTopLeftRadius:3,borderBottomLeftRadius:3,backgroundColor:'rgba(0,0,0,0.5)',position:'absolute'}}>
                                                <span style={{color:'#FFF',fontWeight:'bold'}}>{key+1}</span>
                                                <span style={{width:45,color:'#FFF',fontSize:8,borderStyle:'solid',borderColor:'#FFF',borderWidth:1,borderRadius:50,textAlign:'center',marginTop:5}}>{stringUtil.language(1092)}</span>
                                            </div>:''}
                                            <div style={{display:'flex',flexDirection:'column',}}>
                                                <div style={{width:50,position:'relative',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                                                    <span style={{width:50,height:50,color:'#F00',fontWeight:'bold',textAlign:'center',textOverflow:'ellipsis',overflow:'hidden',backgroundColor:'rgba(255,255,255,0.2)',position:'absolute'}}>{stringUtil.showCode()==2?(item.commodityCode?item.commodityCode:''):''}</span>
                                                    {localStorage.getItem("currentStallItem")&&JSON.parse(localStorage.getItem("currentStallItem")).showImg?'':<img style={{width:50,height:50}} loading="lazy" src={arithUtil.imageShow(item.thumbnail,1)} title={stringUtil.language(84)}/>}
                                                </div>
                                                <span style={{color:'#F00',fontSize:10}}>{item.number+((item.unitMaster!=null&&item.unitMaster!='')?item.unitMaster:stringUtil.language(1173))}</span>
                                            </div>
                                            <div style={{width:0,flexGrow:1,marginLeft:3,display:'flex',flexDirection:'column',justifyContent:'center'}}>
                                                <span style={{color:'#52A056',fontSize:16,fontWeight:'bold',textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}>{key+1}.{!stringUtil.showCode()||stringUtil.showCode()==2?item.commodityName:stringUtil.showCode()==1?(item.commodityCode?item.commodityCode:''):''}</span>
                                                <span style={{color:'#A9A9A9',fontSize:11,marginTop:4,textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}>{item.targetName?item.targetName:item.targetType==1?stringUtil.language(426):''}</span>
                                                <span style={{color:'#A9A9A9',fontSize:11,marginTop:4}}>{stringUtil.language(1175)}{item.cargoNo}{stringUtil.language(1176)}</span>
                                            </div>
                                        </div>
                                        {/* <i className="iconfontIndexCss" style={{fontSize:20,color:'#F00',position:'absolute',right:0,top:0,paddingTop:3,paddingRight:3,paddingLeft:15,paddingBottom:15,cursor:'pointer'}} onClick={()=>{
                                            this.state.wholesaleInsertVoItem.commodities.splice(isHavaChooseObjKey,1);
                                            this.state.vegEditShow = false;
                                            this.setState({wholesaleInsertVoItem:this.state.wholesaleInsertVoItem});
                                        }}>&#xe612;</i> */}
                                        {isHavaChooseObj!=null?<div style={{height:16,backgroundColor:'rgba(0,0,0,0.3)',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',position:'absolute',right:0,bottom:0,borderBottomRightRadius:5}}>
                                            <span style={{fontSize:10,fontWeight:'bold',color:'#F00'}}>{chooseKind+stringUtil.language(1177)+','+chooseNumber+stringUtil.language(1173)}</span>
                                        </div>:''}
                                    </div>);
                                })}
                            </div>
                            </div>
                            <div style={{borderStyle:'solid',borderColor:'#52A056',borderWidth:1,backgroundColor:'#929292',paddingLeft:7,paddingRight:7,paddingTop:5,marginRight:10}}>
                                <div style={{height:45,display:'flex',flexDirection:'row',alignItems:'center',borderStyle:'solid',borderColor:'#d0d0d0',borderWidth:1,borderRadius:2,backgroundColor:'#FFF',marginBottom:5}}>
                                    <i className="iconfontIndexCss" style={{fontSize:25,color:'#D3D3D3'}}>&#xe62c;</i>
                                    <input style={{height:'100%',display:'flex',flexGrow:1,padding:0}} id='commoditySearch' placeholder={stringUtil.language(32)} onChange={(event)=>{
                                        this.state.keySearch = event.target.value;
                                        this.warehouseCommodityItemSearch();
                                        this.setState({warehouseCommodityItemData:this.state.warehouseCommodityItemData});
                                    }}/>
                                    <input id='commoditySerial' style={{width:130,height:'100%',fontSize:18,color:'#F00',fontWeight:'bold',borderLeftStyle:'solid',borderLeftColor:'#929292',borderLeftWidth:1,padding:0,textAlign:'center'}} type='number' onChange={(event)=>{
                                        this.state.regIndexVal=event.target.value!=''?parseInt(event.target.value):-1;
                                    }} placeholder={stringUtil.language(1104)}/>
                                </div>
                                {/* 右边-编辑菜品 */}
                                {this.state.vegEditShow?<div style={{height:150}}>
                                    <div style={{position:'absolute',right:'12%',bottom:110,display:'flex',flexDirection:'row',alignItems:'center'}}>
                                        <div style={{height:35,display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                                            this.state.currWholesaleCommodityVo.mode = 0;//stringUtil.language(1022)
                                            this.state.currWholesaleCommodityVo.unitPrice=this.state.currWholesaleCommodityVo.weightMakePrice;
                                            this.refs.unitPriceVal.value=this.state.currWholesaleCommodityVo.unitPrice;
                                            this.state.currCommodityEditsView='numberEdit';
                                            this.setState({currWholesaleCommodityVo:this.state.currWholesaleCommodityVo},()=>{document.getElementById('numberEdit').focus();});
                                        }}>
                                            {this.state.currWholesaleCommodityVo.mode==0?<i className="iconfontIndexCss" style={{color:'#FF4500',fontSize:20}}>&#xe71f;</i>:
                                            <i className="iconfontIndexCss" style={{color:'#CECECE',fontSize:20}}>&#xe641;</i>}
                                            <span style={{color:this.state.currWholesaleCommodityVo.mode==0?'#FF4500':'#CECECE',fontSize:20}}> {stringUtil.language(1022)}</span>
                                        </div>
                                        <div style={{height:35,display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                                            this.state.currWholesaleCommodityVo.mode = 1;//stringUtil.language(1021)
                                            this.state.currWholesaleCommodityVo.unitPrice=this.state.currWholesaleCommodityVo.numMakePrice;
                                            this.refs.unitPriceVal.value=this.state.currWholesaleCommodityVo.unitPrice;
                                            this.state.currCommodityEditsView='numberEdit';
                                            this.setState({currWholesaleCommodityVo:this.state.currWholesaleCommodityVo},()=>{document.getElementById('numberEdit').focus();});
                                        }}>
                                            {this.state.currWholesaleCommodityVo.mode==1?<i className="iconfontIndexCss" style={{color:'#FF4500',fontSize:20}}>&#xe71f;</i>:
                                            <i className="iconfontIndexCss" style={{color:'#CECECE',fontSize:20}}>&#xe641;</i>}
                                            <span style={{color:this.state.currWholesaleCommodityVo.mode==1?'#FF4500':'#CECECE',fontSize:20}}> {stringUtil.language(1021)}</span>
                                        </div>
                                        <i id='closeEdit' className="iconfontIndexCss" style={{fontSize:25,color:'#F00',padding:15,borderStyle:'solid',borderColor:'#F00',borderWidth:this.state.currCommodityEditsView=='closeEdit'?5:0,cursor:'pointer'}} onClick={()=>{this.setState({vegEditShow:false});}}>&#xe631;</i>
                                    </div>
                                    <div style={{display:'flex',flexDirection:'row',alignItems:'flex-end'}}>
                                        <span style={{color:'#000',fontSize:25,fontWeight:'bold'}}>{stringUtil.showCode()==1||stringUtil.showCode()==2?(this.state.currWholesaleCommodityVo.commodityCode?'('+this.state.currWholesaleCommodityVo.commodityCode+')':''):''}{!stringUtil.showCode()||stringUtil.showCode()==2?this.state.currWholesaleCommodityVo.commodityName:''}</span>
                                        <span style={{color:'#FFF',fontWeight:'bold'}}>  {this.state.currWholesaleCommodityVo.targetType==1?stringUtil.language(1051)+(this.state.currWholesaleCommodityVo.targetName?'_'+stringUtil.subStr(this.state.currWholesaleCommodityVo.targetName,7):''):stringUtil.subStr(this.state.currWholesaleCommodityVo.targetName,8)}/{this.state.currWholesaleCommodityVo.cargoNo}</span>
                                    </div>
                                    {(()=>{
                                        let numberVal = parseFloat(this.state.currWholesaleCommodityVo.number);
                                        // let totalWeightVal = parseFloat(this.state.currWholesaleCommodityVo.totalWeight);
                                        // let skinWeightVal = parseFloat(this.state.currWholesaleCommodityVo.skinWeight);
                                        // let reduceWeightVal = numberVal.mul(this.state.currWholesaleCommodityVo.commodityReduce);//克
                                        // let standardVal = this.state.currWholesaleCommodityVo.standard;//克/stringUtil.language(1173)
                                        // let weightVal = standardVal?numberVal.mul(arithUtil.keToWeight(standardVal,this.state.weightMode)):this.state.currWholesaleCommodityVo.weight,goodsMoneyVal = 0;
                                        // if (this.state.currWholesaleCommodityVo.mode==0) {
                                        //     this.state.currWholesaleCommodityVo.reduceWeight = reduceWeightVal;
                                        //     if (this.state.currEditProps=='numberUpdate'&&this.state.workerHabitInfo.defWeight==0) {
                                        //         this.state.currWholesaleCommodityVo.totalWeight = weightVal.add(arithUtil.keToWeight(reduceWeightVal,this.state.weightMode)).add(skinWeightVal);
                                        //     }else{
                                        //         weightVal = totalWeightVal.sub(skinWeightVal).sub(arithUtil.keToWeight(reduceWeightVal,this.state.weightMode));
                                        //     }
                                        //     this.state.currEditProps = 'number';
                                        //     goodsMoneyVal = (parseFloat(this.state.currWholesaleCommodityVo.unitPrice)).mul(weightVal);
                                        // }else{
                                        //     goodsMoneyVal = (parseFloat(this.state.currWholesaleCommodityVo.unitPrice)).mul(numberVal);
                                        // }
                                        // this.state.currWholesaleCommodityVo.weight = weightVal;
                                        let skinWeightVal = parseFloat(this.state.currWholesaleCommodityVo.skinWeight);
                                        let reduceWeightVal = numberVal.mul(this.state.currWholesaleCommodityVo.commodityReduce);//克
                                        let standardVal = this.state.currWholesaleCommodityVo.standard,goodsMoneyVal = 0;//克/stringUtil.language(1173)
                                        if(standardVal!=null&&standardVal!=0){//有stringUtil.language(1024)情况
                                            if (this.state.currWholesaleCommodityVo.mode==0) {
                                                this.state.currWholesaleCommodityVo.reduceWeight = reduceWeightVal;
                                                if (this.state.currEditProps=='numberUpdate'&&this.state.workerHabitInfo.defWeight==0) {//0stringUtil.language(1024)，1stringUtil.language(1168)
                                                    let totalWeightVal = numberVal.mul(arithUtil.keToWeight(standardVal,this.state.weightMode));
                                                    this.state.currWholesaleCommodityVo.totalWeight = totalWeightVal;
                                                    this.state.currWholesaleCommodityVo.weight=totalWeightVal.sub(skinWeightVal).sub(arithUtil.keToWeight(reduceWeightVal,this.state.weightMode));
                                                }else{
                                                    let totalWeightVal = parseFloat(this.state.currWholesaleCommodityVo.totalWeight);
                                                    this.state.currWholesaleCommodityVo.weight = totalWeightVal.sub(skinWeightVal).sub(arithUtil.keToWeight(reduceWeightVal,this.state.weightMode));
                                                }
                                                this.state.currEditProps = 'number';
                                                goodsMoneyVal = (parseFloat(this.state.currWholesaleCommodityVo.unitPrice)).mul(this.state.currWholesaleCommodityVo.weight);
                                            }else{
                                                let totalWeightVal = numberVal.mul(arithUtil.keToWeight(standardVal,this.state.weightMode));
                                                this.state.currWholesaleCommodityVo.totalWeight = totalWeightVal;
                                                this.state.currWholesaleCommodityVo.weight=totalWeightVal.sub(skinWeightVal).sub(arithUtil.keToWeight(reduceWeightVal,this.state.weightMode));
                                                goodsMoneyVal = (parseFloat(this.state.currWholesaleCommodityVo.unitPrice)).mul(numberVal);
                                            }
                                        }else{//无stringUtil.language(1024)情况
                                            if (this.state.currWholesaleCommodityVo.mode==0) {
                                                this.state.currWholesaleCommodityVo.reduceWeight = reduceWeightVal;
                                                this.state.currWholesaleCommodityVo.weight=(parseFloat(this.state.currWholesaleCommodityVo.totalWeight)).sub(skinWeightVal).sub(arithUtil.keToWeight(reduceWeightVal,this.state.weightMode));
                                                this.state.currEditProps = 'number';
                                                goodsMoneyVal = (parseFloat(this.state.currWholesaleCommodityVo.unitPrice)).mul(this.state.currWholesaleCommodityVo.weight);
                                            }else{
                                                this.state.currWholesaleCommodityVo.weight=(parseFloat(this.state.currWholesaleCommodityVo.totalWeight)).sub(skinWeightVal).sub(arithUtil.keToWeight(reduceWeightVal,this.state.weightMode));
                                                goodsMoneyVal = (parseFloat(this.state.currWholesaleCommodityVo.unitPrice)).mul(numberVal);
                                            }
                                        }
                                        this.state.currWholesaleCommodityVo.goodsMoney = goodsMoneyVal.round(0);
                                        if(this.state.currWholesaleCommodityVo.mode==1&&localStorage.getItem('wholesalePriceType')==1){//stringUtil.language(1016)
                                            this.state.currWholesaleCommodityVo.totalGoodMoney=(parseFloat(this.state.currWholesaleCommodityVo.totalUnitPrice)).mul(numberVal);
                                        }else{}//{stringUtil.language(1015)}
                                    })()}
                                    <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginTop:10,color:'#FFF',position:'relative'}} key={this.state.currWholesaleCommodityVo.cargoCommodityId}>
                                        <input id='numberEdit' style={arithUtil.stylesAdd(styles.textText,{width:'15%',color:'#696969'})} type='number' defaultValue={this.state.currWholesaleCommodityVo.number==0?'':this.state.currWholesaleCommodityVo.number} placeholder={stringUtil.language(1165)} onChange={(event)=>{
                                            if(event.target.value==null || event.target.value==''){
                                                this.state.currWholesaleCommodityVo.number = 0;
                                                this.state.currEditProps = 'numberUpdate';
                                                this.setState({currWholesaleCommodityVo:this.state.currWholesaleCommodityVo});
                                            }else if(stringUtil.numberCheck(event.target.value)){
                                                this.state.currWholesaleCommodityVo.number = event.target.value;
                                                this.state.currEditProps = 'numberUpdate';
                                                this.setState({currWholesaleCommodityVo:this.state.currWholesaleCommodityVo});
                                            }
                                        }}/>&nbsp;{this.state.currWholesaleCommodityVo.unitMaster}，
                                        {this.state.currWholesaleCommodityVo.mode==0||(this.state.currWholesaleCommodityVo.mode==1&&!this.state.currWholesaleCommodityVo.standard)?<div style={{display:'flex',flexDirection:'row',alignItems:'center',color:'#FFF'}}>
                                            {stringUtil.language(1166)}&nbsp;<input id='totalWeightEdit' style={arithUtil.stylesAdd(styles.textText,{width:'120px',color:'#696969'})} type='number' defaultValue={this.state.currWholesaleCommodityVo.totalWeight==0?'':this.state.currWholesaleCommodityVo.totalWeight} placeholder='0' onChange={(event)=>{
                                                if(isNaN(event.target.value)||event.target.value==null||event.target.value==''){
                                                    this.state.currWholesaleCommodityVo.totalWeight = 0;
                                                    this.setState({currWholesaleCommodityVo:this.state.currWholesaleCommodityVo});
                                                }else if(stringUtil.numberCheck(event.target.value)){
                                                    this.state.currWholesaleCommodityVo.totalWeight = event.target.value;
                                                    this.setState({currWholesaleCommodityVo:this.state.currWholesaleCommodityVo});
                                                }
                                            }}/>，
                                        </div>:''}
                                        {this.state.currWholesaleCommodityVo.mode==0?<div style={{display:'flex',flexDirection:'row',alignItems:'center',color:'#FFF'}}>
                                            {stringUtil.language(1167)}&nbsp;<input id='skinWeightEdit' style={arithUtil.stylesAdd(styles.textText,{width:'80px',color:'#696969'})} type='number' defaultValue={this.state.currWholesaleCommodityVo.skinWeight==0?'':this.state.currWholesaleCommodityVo.skinWeight} placeholder='0' onChange={(event)=>{
                                                if(isNaN(event.target.value)||event.target.value==null||event.target.value==''){
                                                    this.state.currWholesaleCommodityVo.skinWeight = 0;
                                                    this.setState({currWholesaleCommodityVo:this.state.currWholesaleCommodityVo});
                                                }else if(stringUtil.numberCheck(event.target.value)){
                                                    this.state.currWholesaleCommodityVo.skinWeight = event.target.value;
                                                    this.setState({currWholesaleCommodityVo:this.state.currWholesaleCommodityVo});
                                                }
                                            }}/>，
                                        </div>:''}
                                        <input id='unitPriceEdit' ref='unitPriceVal' style={arithUtil.stylesAdd(styles.textText,{width:'13%',color:'#696969'})} type='number' defaultValue={this.state.currWholesaleCommodityVo.mode==1&&localStorage.getItem('wholesalePriceType')==1?(this.state.currWholesaleCommodityVo.totalUnitPrice==0?'':this.state.currWholesaleCommodityVo.totalUnitPrice):(this.state.currWholesaleCommodityVo.unitPrice==0?'':this.state.currWholesaleCommodityVo.unitPrice)} placeholder={stringUtil.language(1050)} onChange={(event)=>{
                                            if(isNaN(event.target.value)||event.target.value==null||event.target.value==''){
                                                this.state.currWholesaleCommodityVo.unitPrice = 0;
                                            }else if(stringUtil.numberCheck(event.target.value)){
                                                this.state.currWholesaleCommodityVo.unitPrice = event.target.value;
                                            }
                                            this.state.currWholesaleCommodityVo.totalUnitPrice = this.state.currWholesaleCommodityVo.unitPrice;
                                            this.setState({currWholesaleCommodityVo:this.state.currWholesaleCommodityVo});
                                        }}/>/{this.state.currWholesaleCommodityVo.mode==0?(this.state.weightMode==0?stringUtil.language(1023):stringUtil.language(1172)):this.state.currWholesaleCommodityVo.unitMaster}
                                        <input id='buyerEdit' style={arithUtil.stylesAdd(styles.textText,{width:80,color:'#696969'})} defaultValue={this.state.currWholesaleCommodityVo.buyerCode?this.state.currWholesaleCommodityVo.buyerCode:''} placeholder={stringUtil.language(1156)} onChange={(event)=>{
                                            this.state.currWholesaleCommodityVo.buyerCode=event.target.value;
                                        }}/>
                                        {localStorage.getItem("currentStallItem")&&!JSON.parse(localStorage.getItem("currentStallItem")).wholesaleConfig.showOwnerPrice?<input style={arithUtil.stylesAdd(styles.textText,{width:80,color:'#696969',marginLeft:5})} type='number' defaultValue={this.state.currWholesaleCommodityVo.ownerUnitPrice?this.state.currWholesaleCommodityVo.ownerUnitPrice:''} placeholder={stringUtil.language(1255)} onChange={(event)=>{
                                            if(isNaN(event.target.value)||event.target.value==null||event.target.value==''){
                                                this.state.currWholesaleCommodityVo.ownerUnitPrice = 0;
                                            }else if(stringUtil.numberCheck(event.target.value)){
                                                this.state.currWholesaleCommodityVo.ownerUnitPrice = event.target.value;
                                            }
                                            this.setState({currWholesaleCommodityVo:this.state.currWholesaleCommodityVo});
                                        }}/>:''}
                                        {this.state.currWholesaleCommodityVo.mode==1?<div style={{display:'flex',flexDirection:'row',alignItems:'center',marginLeft:15}}>
                                            <div style={{height:35,display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                                                localStorage.setItem("wholesalePriceType",0);//设置缓存
                                                this.refs.unitPriceVal.value=(this.state.currWholesaleCommodityVo.mode==1&&localStorage.getItem('wholesalePriceType')==1?this.state.currWholesaleCommodityVo.totalUnitPrice:this.state.currWholesaleCommodityVo.unitPrice);
                                                this.setState({});
                                            }}>{localStorage.getItem('wholesalePriceType')!=1?<i className="iconfontIndexCss" style={{color:'#FF4500',fontSize:18}}>&#xe71f;</i>:
                                                <i className="iconfontIndexCss" style={{color:'#CECECE',fontSize:18}}>&#xe641;</i>}
                                                <span style={{color:localStorage.getItem('wholesalePriceType')!=1?'#FF4500':'#CECECE',fontSize:18}}> {stringUtil.language(1015)}</span>
                                            </div>
                                            <div style={{height:35,display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                                                localStorage.setItem("wholesalePriceType",1);//设置缓存
                                                this.refs.unitPriceVal.value=(this.state.currWholesaleCommodityVo.mode==1&&localStorage.getItem('wholesalePriceType')==1?this.state.currWholesaleCommodityVo.totalUnitPrice:this.state.currWholesaleCommodityVo.unitPrice);
                                                this.setState({});
                                            }}>
                                                {localStorage.getItem('wholesalePriceType')==1?<i className="iconfontIndexCss" style={{color:'#FF4500',fontSize:18}}>&#xe71f;</i>:
                                                <i className="iconfontIndexCss" style={{color:'#CECECE',fontSize:18}}>&#xe641;</i>}
                                                <span style={{color:localStorage.getItem('wholesalePriceType')==1?'#FF4500':'#CECECE',fontSize:18}}> {stringUtil.language(1016)}</span>
                                            </div>
                                        </div>:''}
                                    </div>
                                    <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',marginTop:20}}>
                                        {this.state.currWholesaleCommodityVo.mode==0||this.state.currWholesaleCommodityVo.standard?<span style={{color:'#FFF',fontWeight:'bold'}}>{this.state.currWholesaleCommodityVo.mode==0?this.state.currWholesaleCommodityVo.totalWeight+'-'+this.state.currWholesaleCommodityVo.skinWeight+'-'+arithUtil.keToWeight(this.state.currWholesaleCommodityVo.reduceWeight,this.state.weightMode)+'='+this.state.currWholesaleCommodityVo.weight+(this.state.weightMode==0?stringUtil.language(1023):stringUtil.language(1172)):
                                            this.state.currWholesaleCommodityVo.number+this.state.currWholesaleCommodityVo.unitMaster+'X'+arithUtil.keToWeight(this.state.currWholesaleCommodityVo.standard,this.state.weightMode)+','+stringUtil.language(1168)+':'+this.state.currWholesaleCommodityVo.weight+(this.state.weightMode==0?stringUtil.language(1023):stringUtil.language(1172))}</span>:''}
                                        <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                            <span style={{color:'#FFF',fontWeight:'bold'}}>{stringUtil.language(890)}</span><span style={{color:'#F00',fontSize:25,fontWeight:'bold'}}>{this.state.currWholesaleCommodityVo.mode==1&&localStorage.getItem('wholesalePriceType')==1?this.state.currWholesaleCommodityVo.totalGoodMoney:this.state.currWholesaleCommodityVo.goodsMoney}</span><span style={{color:'#FFF',fontWeight:'bold'}}>{stringUtil.currency(1)}</span>
                                        </div>
                                        <span id='enterEdit' style={{width:100,height:'30px',color:'#FFF',lineHeight:'30px',borderRadius:3,backgroundColor:'#D2855D',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderColor:'#F00',borderWidth:this.state.currCommodityEditsView=='enterEdit'?5:0,cursor:'pointer'}} onClick={()=>{
                                            this.submitCommodityItem();
                                            this.setState({vegEditShow:false},()=>{document.getElementById('commoditySearch').focus();});
                                        }}>{stringUtil.language(1027)}</span>
                                    </div>
                                </div>:''}
                                {/* 右边-{stringUtil.language(1092)}菜品-结束 */}
                            </div>
                        </div>
                        <div style={{width:'10%',backgroundColor:'#145F33',display:'flex',flexDirection:'column',alignItems:'center',overflow:'auto'}}>
                            {this.state.commodityTypesAll.map((item,key)=>{
                                return(<span style={{width:'100%',height:40,color:this.state.currShopCommodityTypeItem.id==item.id?'#145F33':'#FFF',fontSize:16,backgroundColor:this.state.currShopCommodityTypeItem.id==item.id?'#8AEC90':'#145F33',display:'flex',justifyContent:'center',alignItems:'center',cursor:'pointer'}} key={key} onClick={()=>{
                                    this.state.currShopCommodityTypeItem = JSON.parse(JSON.stringify(item));
                                    this.warehouseCommodityItemSearch();
                                    this.setState({currShopCommodityTypeItem:this.state.currShopCommodityTypeItem});
                                }}>{item.name}</span>)
                            })}
                            {/* <div style={{width:65,height:30,position:'absolute',bottom:0,marginBottom:10,color:'#FFF',fontSize:12,borderStyle:'solid',borderColor:'#FFF',borderWidth:1,borderRadius:50,display:'flex',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                                if(!this.state.sort)this.state.warehouseCommodityItemData = this.state.warehouseCommodityItemAll;
                                this.setState({sort:!this.state.sort});
                            }}>{stringUtil.language(828)}</div> */}
                        </div>
                    </div>
                </div>
            </div>
            <MaskModal title={stringUtil.language(828)} visible={this.state.sortShowPop?true:false} width={300}
                content={<div style={{width:'100%',height:'100%',display:'flex',flexDirection:'column',justifyContent:'center'}} key={this.state.warehouseCommodityItemData[this.state.keyTagVal]?this.state.warehouseCommodityItemData[this.state.keyTagVal].id:''}>
                    <div style={{height:40,display:'flex',flexDirection:'row',alignItems:'center',paddingLeft:10,paddingRight:10}}>
                        <span>{stringUtil.language(1017)}：</span><input style={{height:30,fontWeight:'bold',borderStyle:'solid',borderColor:'#d0d0d0',borderWidth:1,borderRadius:2,display:'flex',flexGrow:1,textAlign:'center'}} type='number' placeholder='0' onChange={(event)=>{
                            this.state.commoditySortVal = parseInt(event.target.value+'');
                        }}/>
                    </div>
                </div>}
                okBnEvents={(event) => {
                    if(!(this.state.commoditySortVal>0)){ToastBox.error(stringUtil.language(133));return;}
                    this.state.sortShowPop=false;
                    let currSortItem=JSON.parse(JSON.stringify(this.state.warehouseCommodityItemData[this.state.keyTagVal]));
                    let resultIds=[];
                    for(let i=0;i<this.state.warehouseCommodityItemData.length;i++){
                        if(this.state.warehouseCommodityItemData[i].id!=this.state.warehouseCommodityItemData[this.state.keyTagVal].id)
                        resultIds.push(this.state.warehouseCommodityItemData[i].id);
                    }
                    resultIds.splice(this.state.commoditySortVal-1,0,currSortItem.id);
                    stringUtil.httpRequest(urlUtil.SALE_WAREHOUSE_COMMODITY_SORT_URL,resultIds,['json','post',1],(successResponse)=>{
                        if (successResponse.status==200) {
                            ToastBox.success(stringUtil.language(388));
                            this.initData();
                        }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                }} closeBnEvents={() => { this.setState({ sortShowPop: false, }); }} />
            <MaskModal title={stringUtil.language(691)} visible={this.state.customerCollectShowPop} width={700} height={arithUtil.ProportionTo(document.body.clientHeight,0.6)}
                content={<div style={{width:'100%',height:'100%',paddingLeft:10,paddingRight:10}}>
                    <CustomerCollectView context={this} paramsInfo={{wholesaleItem:JSON.stringify(this.state.currWholesaleItem)}} successEvent={(result1)=>{
                        if(result1==0||result1==1||result1==-1){//0stringUtil.language(1027)按钮，1stringUtil.language(1028)按钮，-1不选中
                            this.setState({backResultVal:result1})
                        }else if(result1=='submitResult0'||result1=='submitResult1'){//0stringUtil.language(1027)stringUtil.language(1038)，1stringUtil.language(1028)stringUtil.language(1038)
                            if(result1=='submitResult0')this.collectionEvent(0);
                            if(result1=='submitResult1')this.collectionEvent(1);
                        }else{this.state.customerCollectInsertItem = result1;}
                    }}/>
                </div>} okBnColor={this.state.backResultVal==0?'#F00':'#52A056'} closeBnColor={this.state.backResultVal==1?'#F00':'#F5F5F5'}
                okBnEvents={(event) => {
                    this.collectionEvent(0);
                }} closeBnEvents={() => {this.collectionEvent(1);}}/>
            <MaskModal title={stringUtil.language(635)} visible={this.state.customerAddShowPop} width={580} height={arithUtil.ProportionTo(document.body.clientHeight,0.6)}
              content={<div style={{width:'100%',height:'100%',paddingLeft:arithUtil.pxToDp(10),paddingRight:arithUtil.pxToDp(10)}} key={this.state.addShowPopIndex}>
                    <div style={{width:'100%',height:'100%',overflow:'auto'}}>
                        <CustomerAddActivityView context={this} successEvent={(result1,result2)=>{
                            this.state.currItem = result1;
                            this.state.currDistrictA = result2.currDistrictA;
                            this.state.currDistrictB = result2.currDistrictB;
                            this.state.currDistrictC = result2.currDistrictC;
                        }}/>
                    </div>
                </div>}
                okBnEvents={(event) => {
                    if (this.state.currItem==null||this.state.currItem.noteName==null || this.state.currItem.noteName=='') {
                        ToastBox.error(stringUtil.language(198));return;
                    }
                    let shengStr = this.state.currDistrictA!=null?this.state.currDistrictA.name:'';
                    let shiStr = this.state.currDistrictB!=null?this.state.currDistrictB.name:'';
                    let xianStr = this.state.currDistrictC!=null?this.state.currDistrictC.name:'';
                    if (shengStr!='' || shiStr!='' || xianStr!='') {
                        if (this.state.currDistrictB!=null) {
                            if (this.state.currDistrictC!=null) {
                                this.state.currItem.adcode = this.state.currDistrictC.adcode;
                            }else{this.state.currItem.adcode = this.state.currDistrictB.adcode;}
                        }else{this.state.currItem.adcode = this.state.currDistrictA.adcode;}
                        this.state.currItem.adname = shengStr+shiStr+xianStr;
                    }
                    stringUtil.httpRequest(urlUtil.OAUTH_CUSTOMER_URL,this.state.currItem,['json','post',1],(successResponse)=>{
                        if (successResponse.status==200) {
                            this.state.customerAddShowPop = false;
                            this.initCustomers();
                        }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                }} closeBnEvents={() => {this.setState({customerAddShowPop:false})}}/>
            <MaskModal title={stringUtil.language(280)} visible={this.state.costsShowPop} width={arithUtil.pxToDp(450)} height={arithUtil.ProportionTo(document.body.clientHeight,0.6)}
              content={<div style={{paddingLeft:arithUtil.pxToDp(10),paddingRight:arithUtil.pxToDp(10)}}>
                    <div style={{height:'100%',overflow:'auto'}}>
                        {this.state.currWholesaleCommodityVo.costs!=null?this.state.currWholesaleCommodityVo.costs.map((item,key)=>{
                            return(<div style={{height:arithUtil.pxToDp(30),display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}} key={key}>
                                <span>{item.costClauseName}</span>
                                <span>{(item.payee==0?stringUtil.language(1049):item.payee==1?stringUtil.language(1030):stringUtil.language(1031))+stringUtil.language(1032)}</span>
                                <span>{item.calMode==0?stringUtil.language(868):item.calMode==1?stringUtil.language(869):item.calMode==2?stringUtil.language(396):stringUtil.language(1029)}</span>
                                <span style={{color:'#F00'}}>{arithUtil.fenToYuan(item.money)} {stringUtil.currency(1)}</span>
                            </div>)
                        }):<div/>}
                    </div>
                </div>}closeBnTopEvents={() => {this.setState({ costsShowPop: false, });}}/>
        </div>)
    }
    collectionEvent(tag){
        if(tag==0){
            if (this.state.customerCollectInsertItem==null||(this.state.customerCollectInsertItem.collectMoney==0&&this.state.customerCollectInsertItem.discountMoney==0)) {
                ToastBox.error(stringUtil.language(96));return;
            }
            if (this.state.customerCollectInsertItem.accountId==null) {
                ToastBox.error(stringUtil.language(95));return;
            }
            let customerCollectInsertVo = {collects:[]};
            customerCollectInsertVo.time = arithUtil.timeToNumber(this.state.customerCollectInsertItem.time);
            customerCollectInsertVo.customerId = this.state.currWholesaleItem.customerId;
            customerCollectInsertVo.accountId = this.state.customerCollectInsertItem.accountId;
            customerCollectInsertVo.discountMoney = this.state.customerCollectInsertItem.discountMoney;
            customerCollectInsertVo.collectMoney = this.state.customerCollectInsertItem.collectMoney;
            customerCollectInsertVo.describe = this.state.customerCollectInsertItem.describe!=null?this.state.customerCollectInsertItem.describe:'';
            for(let i=0;i<this.state.customerCollectInsertItem.collects.length;i++){
                let temp = this.state.customerCollectInsertItem.collects[i];
                if ((temp.collectMoney!=null&&temp.collectMoney!=0) || (temp.discountMoney!=null&&temp.discountMoney!=0)) {
                    let collectDetailedTemp = {
                        collectMode:1,//0.stringUtil.language(913) 1stringUtil.language(1185)stringUtil.language(865)
                        wholesaleId:temp.wholesaleId,
                        targetType:temp.targetType,
                        targetId:temp.targetId,
                        discountMoney:temp.discountMoney,
                        collectMoney:temp.collectMoney
                    };
                    customerCollectInsertVo.collects.push(collectDetailedTemp);
                }
            }
            if(this.state.isVisiblityCollect){return;}
			this.state.isVisiblityCollect = true;
            stringUtil.httpRequest(urlUtil.FINANCE_CUSTOMER_COLLECT_URL,customerCollectInsertVo,['json','post',1],(successResponse)=>{
                if (successResponse.status==200) {
                    ToastBox.success(stringUtil.language(388));
                    this.setState({customerCollectShowPop:false},()=>{
                        // if(this.state.isPrintTag!=1)stringUtil.httpRequest(urlUtil.SALE_WHOLESALE_PRINT_URL+'/'+this.state.currWholesaleItem.id,null,['key','get',1],(successResponse)=>{
                        //     if (successResponse.status==200) {
                        //         let printWholesaleInfo = successResponse.data;
                        //         if((localStorage.getItem("shopConfigInfo")!=null&&JSON.parse(localStorage.getItem("shopConfigInfo")).isRound==1)||this.state.currCustomerItem.type!=1) printWholesaleInfo.billMoney=0;
                        //         if(localStorage.getItem("useSetting")&&JSON.parse(localStorage.getItem("useSetting")).everyCopyNum!=null){
                        //             let printDataTemp = otherUtil.wholesaleNull(printWholesaleInfo);
                        //             for(let i=0;i<JSON.parse(localStorage.getItem("useSetting")).everyCopyNum;i++){
                        //                 printUtil.toComputerPrint(printDataTemp);
                        //             }
                        //         }else{printUtil.toComputerPrint(otherUtil.wholesaleNull(printWholesaleInfo));}
                        //     }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                        // },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                        global.isCloseThis=1;
                        this.props.navigationStacks.splice(this.props.navigationStacks.length-1,1);
                        this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'',mainStacks:this.props.navigationStacks}});
                    });
                }else{this.state.isVisiblityCollect=false;ToastBox.error(successResponse.message+`(${successResponse.status})`);}
            },(errorResponse)=>{this.state.isVisiblityCollect=false;ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
        }else if(tag==1){
            this.setState({customerCollectShowPop:false},()=>{
                // if(this.state.isPrintTag!=1)stringUtil.httpRequest(urlUtil.SALE_WHOLESALE_PRINT_URL+'/'+this.state.currWholesaleItem.id,null,['key','get',1],(successResponse)=>{
                //     if (successResponse.status==200) {
                //         let printWholesaleInfo = successResponse.data;
                //         if((localStorage.getItem("shopConfigInfo")!=null&&JSON.parse(localStorage.getItem("shopConfigInfo")).isRound==1)||this.state.currCustomerItem.type!=1) printWholesaleInfo.billMoney=0;
                //         if(localStorage.getItem("useSetting")&&JSON.parse(localStorage.getItem("useSetting")).everyCopyNum!=null){
                //             let printDataTemp = otherUtil.wholesaleNull(printWholesaleInfo);
                //             for(let i=0;i<JSON.parse(localStorage.getItem("useSetting")).everyCopyNum;i++){
                //                 printUtil.toComputerPrint(printDataTemp);
                //             }
                //         }else{printUtil.toComputerPrint(otherUtil.wholesaleNull(printWholesaleInfo));}
                //     }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                // },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                global.isCloseThis=1;
                this.props.navigationStacks.splice(this.props.navigationStacks.length-1,1);
                this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'',mainStacks:this.props.navigationStacks}});
            });
        }
    }
    submitCommodityItem(){
        let tempValnum = this.state.currWholesaleCommodityVo.number;
            if ((tempValnum+'').length>0) {
                this.state.currWholesaleCommodityVo.number = parseFloat(tempValnum);
            }else{
                this.state.currWholesaleCommodityVo.number = 0;
            }
        let tempValtot = this.state.currWholesaleCommodityVo.totalWeight;
            if ((tempValtot+'').length>0) {
                this.state.currWholesaleCommodityVo.totalWeight = parseFloat(tempValtot);
            }else{
                this.state.currWholesaleCommodityVo.totalWeight = 0;
            }
            this.state.currWholesaleCommodityVo.totalWeight = arithUtil.weightToKe(this.state.currWholesaleCommodityVo.totalWeight,this.state.weightMode);
        let tempValski = this.state.currWholesaleCommodityVo.skinWeight;
            if ((tempValski+'').length>0) {
                this.state.currWholesaleCommodityVo.skinWeight = parseFloat(tempValski);
            }else{
                this.state.currWholesaleCommodityVo.skinWeight = 0;
            }
            this.state.currWholesaleCommodityVo.skinWeight = arithUtil.weightToKe(this.state.currWholesaleCommodityVo.skinWeight,this.state.weightMode);
            this.state.currWholesaleCommodityVo.weight = arithUtil.weightToKe(this.state.currWholesaleCommodityVo.weight,this.state.weightMode);
        let tempValuni = this.state.currWholesaleCommodityVo.unitPrice;
            if (!this.state.currWholesaleCommodityVo.supplementaryCommodity&&(tempValuni+'').length>0) {
                this.state.currWholesaleCommodityVo.unitPrice = parseFloat(tempValuni);
            }else{
                this.state.currWholesaleCommodityVo.unitPrice = 0;
            }
        let tempValtotUni = this.state.currWholesaleCommodityVo.totalUnitPrice;
            if (!this.state.currWholesaleCommodityVo.supplementaryCommodity&&(tempValtotUni+'').length>0) {
                this.state.currWholesaleCommodityVo.totalUnitPrice = parseFloat(tempValtotUni);
            }else{
                this.state.currWholesaleCommodityVo.totalUnitPrice = 0;
            }
        let tempValOwnerUni = this.state.currWholesaleCommodityVo.ownerUnitPrice;
            if (!this.state.currWholesaleCommodityVo.supplementaryCommodity&&(tempValOwnerUni+'').length>0) {
                this.state.currWholesaleCommodityVo.ownerUnitPrice = parseFloat(tempValOwnerUni);
            }else{
                this.state.currWholesaleCommodityVo.ownerUnitPrice = 0;
            }
            this.state.currWholesaleCommodityVo.goodsMoney = arithUtil.yuanToFen(this.state.currWholesaleCommodityVo.goodsMoney).round();
            if(this.state.currWholesaleCommodityVo.mode==0){
                this.state.currWholesaleCommodityVo.unitPrice = arithUtil.unitPriceTo(arithUtil.yuanToFen(this.state.currWholesaleCommodityVo.unitPrice),this.state.weightMode,0);
                this.state.currWholesaleCommodityVo.ownerUnitPrice = arithUtil.unitPriceTo(arithUtil.yuanToFen(this.state.currWholesaleCommodityVo.ownerUnitPrice),this.state.weightMode,0);
                this.state.currWholesaleCommodityVo.totalUnitPrice = 0;
            }else{
                this.state.currWholesaleCommodityVo.unitPrice = arithUtil.yuanToFen(this.state.currWholesaleCommodityVo.unitPrice);
                this.state.currWholesaleCommodityVo.ownerUnitPrice = arithUtil.yuanToFen(this.state.currWholesaleCommodityVo.ownerUnitPrice);
                this.state.currWholesaleCommodityVo.totalUnitPrice = arithUtil.yuanToFen(this.state.currWholesaleCommodityVo.totalUnitPrice);
                if(localStorage.getItem('wholesalePriceType')==1){//stringUtil.language(1016)
                    this.state.currWholesaleCommodityVo.totalGoodMoney=(this.state.currWholesaleCommodityVo.totalUnitPrice).mul(this.state.currWholesaleCommodityVo.number);
                    let otherMoneyTotal = 0;
                    if(this.state.currWholesaleCommodityVo.costs==null)this.state.currWholesaleCommodityVo.costs=[];
                    for(let i=0;i<this.state.currWholesaleCommodityVo.costs.length;i++){
                        let costsItem = this.state.currWholesaleCommodityVo.costs[i];
                        if(costsItem.moneyScal==null)costsItem.moneyScal=0;
                        if (costsItem.calMode==0) {//stringUtil.language(868)
                            costsItem.money = (this.state.currWholesaleCommodityVo.number).mul(costsItem.moneyScal).round();
                        }else if (costsItem.calMode==1) {//stringUtil.language(869)
                            costsItem.money = (arithUtil.keToWeight(this.state.currWholesaleCommodityVo.weight,0)).mul(costsItem.moneyScal).roundDouble();
                        }else if (costsItem.calMode==2) {//stringUtil.language(396)
                            let otherMoneyTotal=0,scalTemp=0,goodsMoneyTemp=0;
                            for(let i=0;i<this.state.currWholesaleCommodityVo.costs.length;i++){
                                let costsItemTemp = this.state.currWholesaleCommodityVo.costs[i];
                                if(costsItemTemp.moneyScal==null)costsItemTemp.moneyScal=0;
                                if (costsItemTemp.calMode==0) {//stringUtil.language(868)
                                    otherMoneyTotal=otherMoneyTotal.add((this.state.currWholesaleCommodityVo.number).mul(costsItemTemp.moneyScal).round());
                                }else if (costsItemTemp.calMode==1) {//stringUtil.language(869)
                                    otherMoneyTotal=otherMoneyTotal.add((arithUtil.keToWeight(this.state.currWholesaleCommodityVo.weight,0)).mul(costsItemTemp.moneyScal).roundDouble());
                                }else if (costsItemTemp.calMode==3) {
                                    otherMoneyTotal=otherMoneyTotal.add(parseFloat(costsItemTemp.moneyScal).round());
                                }else{
                                    scalTemp=scalTemp+costsItem.moneyScal;
                                }
                            }
                            goodsMoneyTemp=((this.state.currWholesaleCommodityVo.totalGoodMoney).sub(otherMoneyTotal)).div(scalTemp.mul(0.01).add(1));
                            costsItem.money = goodsMoneyTemp.mul(costsItem.moneyScal).mul(0.01).round();
                        }else{
                            costsItem.money = parseFloat(costsItem.moneyScal).round();
                        }
                        otherMoneyTotal = otherMoneyTotal.add(costsItem.money);
                    }
                    this.state.currWholesaleCommodityVo.goodsMoney=(this.state.currWholesaleCommodityVo.totalGoodMoney).sub(otherMoneyTotal);/*20220524吕总说{stringUtil.language(1069)}费只有{stringUtil.language(1115)}的，不管了，就直接用总款减{stringUtil.language(1069)}费 */
                    this.state.currWholesaleCommodityVo.unitPrice=(this.state.currWholesaleCommodityVo.goodsMoney).div(this.state.currWholesaleCommodityVo.number);
                }else{}//{stringUtil.language(1015)}
            }
        if (this.state.currWholesaleCommodityVoKey==-1) {//-1{stringUtil.language(1129)}，不是-1{stringUtil.language(1092)}左边{stringUtil.language(1063)}
            this.state.wholesaleInsertVoItem.commodities.push(this.state.currWholesaleCommodityVo);
        }else{
            this.state.wholesaleInsertVoItem.commodities[this.state.currWholesaleCommodityVoKey] = this.state.currWholesaleCommodityVo;
        }
    }
}

const styles = {
    tableTitle:{
        borderStyle:'solid',
        borderColor:'#F0F0F0',
        borderWidth: 1,
        backgroundColor:'#F2F2F2',
        display:'flex',
        flexDirection:'row',
        alignItems:'center'
    },
    tableTd:{
        width:'15%',
        fontSize:12,
        display:'flex',
        alignItems:'center',
        textAlign:'right'
    },
    inputText:{
        height:arithUtil.pxToDp(35),
        borderStyle:'solid',
        borderColor:'#d0d0d0',
        borderWidth:1,
        borderRadius:2,
        padding:0,
        paddingLeft:arithUtil.pxToDp(5)
    },
    textInput:{
        height:arithUtil.pxToDp(35),
        borderStyle:'solid',
        borderColor:'#d0d0d0',
        borderWidth:1,
        borderRadius:2,
        padding:0,
        paddingLeft:arithUtil.pxToDp(5),
        textAlign:'center',
        textAlignVertical:'center'
    },
    textText:{
        width:'20%',
        height:35,
        borderStyle:'solid',
        borderColor:'#A1CEB9',
        borderWidth:1,
        borderRadius:2,
        backgroundColor:'#FFF',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        textAlign:'center'
    },
};