'use strict';
import React,{Component} from 'react';
 import * as arithUtil from '../utils/arithUtil.js';
 import * as stringUtil from '../utils/stringUtil.js';
 import * as otherUtil from '../utils/otherUtil.js';
 import urlUtil from '../utils/urlUtil.js';
 import ToastBox from '../components/ToastBox';
 import MaskModal from '../components/MaskModal';

 export default class AddStallEmployee extends Component{
    constructor(props) {
      super(props);
      this.state={
        stallName:'',
        showReplyPop:false,
        currShopItem:null,
        stallsAll:[],
      };
    }
    componentDidMount(){
        this.getDataList();
    }
    getDataList(){
      stringUtil.httpRequest(urlUtil.SHOP_QUERY_URL,{
        keyword:this.state.stallName
      },['key','get',0],(successResponse)=>{
        if (successResponse.status==200) {
          this.setState({stallsAll:successResponse.data});
        }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
      },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    }

    render(){
        return(<div style={{width:window.screen.width,height:document.body.clientHeight,backgroundColor:'#ECECEC'}}>
            <div style={{height:arithUtil.pxToDp(60),display:'flex',alignItems:'center',marginLeft:'1%'}}>
                <img style={{width:'40px',height:'40px'}} loading="lazy" src={require('../images/app_logo.png').default} title={stringUtil.language(84)}/>
                <span style={{fontSize:'25px',color:'#D2855D',marginLeft:arithUtil.pxToDp(10)}}>{stringUtil.language(6)}</span>
            </div>
            <div style={{height:'78%',marginLeft:arithUtil.pxToDp(15),marginRight:arithUtil.pxToDp(15),backgroundColor:'#FFF',padding:15}}>
                <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                  <input style={styles.phoneInputText} onChange={(event)=>{
                      let phoneCheck = (event.target.value).phoneCheck();
                      if(phoneCheck){
                        this.state.mobile = event.target.value;
                        this.state.stallName = '';
                      }else{
                        this.state.mobile = '';
                        this.state.stallName = event.target.value;
                      }
                  }} placeholder={stringUtil.language(16)}/>
                  <span style={{width:arithUtil.pxToDp(70),height:'30px',color:'#FFF',borderRadius:5,backgroundColor:'#d2855d',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer',marginLeft:15,cursor:'pointer'}} onClick={()=>{
                        this.getDataList();
                      }}>{stringUtil.language(1034)}</span>
                </div>
                <div style={{width:'100%',height:'100%',marginTop:arithUtil.pxToDp(20),display:'flex',flexDirection:'row',flexWrap:'wrap',overflow:'auto'}}>
                    {this.state.stallsAll.map((item,key)=>{
                        let showView = null;
                        if (item.state == 0) {
                          showView = <div style={styles.searchStallItem} key={key}>
                            <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                <img style={{width:'50px',height:'50px'}} loading="lazy" src={arithUtil.imageShow(item.shopLogo,2)} title={stringUtil.language(84)}/>
                                <div style={{marginLeft:arithUtil.pxToDp(5)}}>
                                  <span style={{fontSize:16}}>{item.shopName}</span><br/>
                                  <span style={{fontSize:12,color:'#A9A9A9',marginTop:arithUtil.pxToDp(10)}}>{(item.userMobile).strReplace()}</span>
                                </div>
                            </div>
                            <span style={{color:'#D2855D'}}>{stringUtil.language(399)}</span>
                          </div>;
                        }else if (item.state == 1){
                          showView = <div style={styles.searchStallItem} key={key}>
                            <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <img style={{width:'50px',height:'50px'}} loading="lazy" src={arithUtil.imageShow(item.shopLogo,2)} title={stringUtil.language(84)}/>
                            <div style={{marginLeft:arithUtil.pxToDp(5)}}>
                              <span style={{fontSize:16}}>{item.shopName}</span><br/>
                              <span style={{fontSize:12,color:'#A9A9A9',marginTop:arithUtil.pxToDp(10)}}>{(item.userMobile).strReplace()}</span>
                            </div></div>
                            <span style={{color:'#D2855D'}}>{stringUtil.language(400)}</span>
                          </div>;
                        }else if (item.state == 2){
                          showView = <div style={styles.searchStallItem} key={key}>
                            <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <img style={{width:'50px',height:'50px'}} loading="lazy" src={arithUtil.imageShow(item.shopLogo,2)} title={stringUtil.language(84)}/>
                            <div style={{marginLeft:arithUtil.pxToDp(5)}}>
                              <span style={{fontSize:16}}>{item.shopName}</span><br/>
                              <span style={{fontSize:12,color:'#A9A9A9',marginTop:arithUtil.pxToDp(10)}}>{(item.userMobile).strReplace()}</span>
                            </div></div>
                            <span style={{color:'#A9A9A9'}}>{stringUtil.language(279)}</span>
                          </div>;
                        }else if (item.state == 3) {
                          showView = <div style={styles.searchStallItem} key={key}>
                            <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <img style={{width:'50px',height:'50px'}} loading="lazy" src={arithUtil.imageShow(item.shopLogo,2)} title={stringUtil.language(84)}/>
                            <div style={{marginLeft:arithUtil.pxToDp(5)}}>
                              <span style={{fontSize:16}}>{item.shopName}</span><br/>
                              <span style={{fontSize:12,color:'#A9A9A9',marginTop:arithUtil.pxToDp(10)}}>{(item.userMobile).strReplace()}</span>
                            </div></div>
                            <span style={{width:60,height:'30px',color:'#FFF',lineHeight:'30px',borderRadius:5,backgroundColor:'#D2855D',marginTop:arithUtil.pxToDp(35),display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                                this.setState({
                                    currShopItem: item,
                                    showReplyPop: !this.state.showReplyPop,
                                });
                            }}>{stringUtil.language(1035)}</span>
                          </div>;
                        }
                        return(showView);
                    })}
                </div>
            </div>
            <MaskModal title={stringUtil.language(401)} visible={this.state.showReplyPop} content={<div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                            {stringUtil.language(13)}
                      </div>}
              okBnEvents={(event) => {
                  this.setState({showReplyPop: false});
                  stringUtil.httpRequest(urlUtil.WORKER_APPLY_URL,{
                      shopId:this.state.currShopItem.shopId,
                    },['key','post',0],(successResponse)=>{
                      if (successResponse.status==200) {
                        this.getDataList();
                        ToastBox.success(successResponse.message);
                      }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
              }} closeBnEvents={() => {this.setState({showReplyPop: false});}}/>
        </div>)
    }
 }

 const styles = {
    phoneInputText:{
      width:450,
      height:arithUtil.pxToDp(30),
      borderStyle:'solid',
      borderColor:'#d0d0d0',
      borderWidth:1,
      borderRadius:5,
      color:'#808080',
      paddingLeft:arithUtil.pxToDp(10),
    },
    searchStallItem:{
      width:'22.7%',
      height:60,
      display:'flex',
      flexDirection:'row',
      justifyContent:'space-between',
      alignItems:'center',
      padding:5,
      marginBottom:10,
      marginRight:10,
      borderStyle:'solid',
      borderColor:'#DCDCDC',
      borderWidth: 1,
      borderRadius:5,
    },
 };