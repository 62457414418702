import React,{Component} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import * as arithUtil from '../utils/arithUtil.js';
import * as stringUtil from '../utils/stringUtil.js';
import * as otherUtil from '../utils/otherUtil.js';
import urlUtil from '../utils/urlUtil.js';
import ToastBox from '../components/ToastBox';
import MaskModal from '../components/MaskModal';
import TabTopActivity from '../components/TabTopActivity';
import Pagination from '../components/Pagination';

 //经营日报表
 export default class SaleReportDailyActivity extends Component{
    constructor(props) {
      super(props);
      let paramsPage = this.props.navigationStacks[this.props.navigationStacks.length-1].paramsPage;
      this.state={
        weightMode:0,//0stringUtil.language(1023)，1市stringUtil.language(1172)
        saleReportDaily:{},
        dateTimeStr:arithUtil.getCurrDateTime(1),
        uiObj:{
            costRow:[],
            cargoFySy:{},
            tatolSy:0,
            inMoneyMx:[],
            outMoneyMx:[],
            inOutColumnNum:0,
            tatolCollectMoney:0,
            tatolPayMoney:0
        }
      };
      if(localStorage.getItem("shopConfigInfo")!=null&&JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit!=null) this.state.weightMode = JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit;
    }
    componentDidMount(){
        this.initData();
    }
    initUIObj (){
        this.state.uiObj={costRow:[],
            cargoFySy:{},
            tatolSy:0,
            inMoneyMx:[],
            outMoneyMx:[],
            inOutColumnNum:0,
            tatolCollectMoney:0,
            tatolPayMoney:0,
            isShowDiff:false,isShowOwne:false};
        for(let i=0;i< this.state.saleReportDaily.cargos.length;i++){
            let cargo =  this.state.saleReportDaily.cargos[i];
            let taltalStallsMoney = 0;
            for(let j=0;j<cargo.commodities.length;j++){
                let commodity = cargo.commodities[j];
                if(!this.state.uiObj.isShowDiff&&commodity.diffGoodsMoney!=0){
                    this.state.uiObj.isShowDiff = true;
                }
                if(!this.state.uiObj.isShowOwne&&commodity.ownerDeductGoodsMoney!=0){
                    this.state.uiObj.isShowOwne = true;
                }
                taltalStallsMoney = taltalStallsMoney + commodity.stallsMoney;
                 for(let k=0;k<commodity.costs.length;k++){
                      let isExits = false;
                      for(let m=0;m<this.state.uiObj.costRow.length;m++){
                          if(this.state.uiObj.costRow[m].id==commodity.costs[k].costClauseId){
                            isExits = true;
                          }
                      }
                      if(!isExits){
                         let obj = {};
                         obj.id = commodity.costs[k].costClauseId;
                         obj.name = commodity.costs[k].costClauseName;
                         this.state.uiObj.costRow.push(obj);
                      }
                 }
            }
            let cargoFySyItem = {};
            cargoFySyItem.fy = [];
            cargoFySyItem.fyMoney = 0;
            cargoFySyItem.sy = [];
            cargoFySyItem.syMoney = 0;
            cargoFySyItem.columnNum = cargo.commodities.length;
               if(cargo.entrustMoney!=0){
                 cargoFySyItem.fy.push({name:stringUtil.language(941),val:cargo.entrustMoney});
                 cargoFySyItem.fyMoney = cargoFySyItem.fyMoney + cargo.entrustMoney;
               }
               if(cargo.disburdenMoney!=0){
                cargoFySyItem.fy.push({name:JSON.parse(localStorage.getItem("currentStallItem")).disburdenText,val:cargo.disburdenMoney});
                cargoFySyItem.fyMoney = cargoFySyItem.fyMoney + cargo.disburdenMoney;
              }
              if(cargo.discountMoney!=0){
                cargoFySyItem.fy.push({name:stringUtil.language(1042),val:cargo.discountMoney});
                cargoFySyItem.fyMoney = cargoFySyItem.fyMoney + cargo.discountMoney;
              }
            for(let j=0;j<cargo.expends.length;j++){
                cargoFySyItem.fy.push({name:cargo.expends[j].ownerExpendClauseName,val:cargo.expends[j].money});
                cargoFySyItem.fyMoney = cargoFySyItem.fyMoney + cargo.expends[j].money;
            }
            if(cargo.cargoMode == 0){
                cargoFySyItem.sy.push({name:stringUtil.language(601),val:taltalStallsMoney});
                cargoFySyItem.syMoney = cargoFySyItem.syMoney + taltalStallsMoney;
                if(cargo.entrustMoney!=0){
                    cargoFySyItem.sy.push({name:stringUtil.language(941),val:cargo.entrustMoney});
                    cargoFySyItem.syMoney = cargoFySyItem.syMoney + cargo.entrustMoney;
                  }
                if(cargo.disburdenMoney!=0){
                    cargoFySyItem.sy.push({name:JSON.parse(localStorage.getItem("currentStallItem")).disburdenText,val:cargo.disburdenMoney});
                    cargoFySyItem.syMoney = cargoFySyItem.syMoney + cargo.disburdenMoney;
                  }
                if(cargo.rebateMoney!=0){
                    cargoFySyItem.sy.push({name:stringUtil.language(541),val:-cargo.rebateMoney});
                    cargoFySyItem.syMoney = cargoFySyItem.syMoney - cargo.rebateMoney;
                }
            }else if(cargo.cargoMode == 1){
                cargoFySyItem.sy.push({name:stringUtil.language(601),val:taltalStallsMoney});
                cargoFySyItem.syMoney = cargoFySyItem.syMoney + taltalStallsMoney;
                if(cargo.entrustMoney!=0){
                    cargoFySyItem.sy.push({name:stringUtil.language(941),val:cargo.entrustMoney});
                    cargoFySyItem.syMoney = cargoFySyItem.syMoney + cargo.entrustMoney;
                  }
                if(cargo.disburdenMoney!=0){
                    cargoFySyItem.sy.push({name:JSON.parse(localStorage.getItem("currentStallItem")).disburdenText,val:cargo.disburdenMoney});
                    cargoFySyItem.syMoney = cargoFySyItem.syMoney + cargo.disburdenMoney;
                  }
                  if(cargo.purchaseCostMoney!=0){
                    cargoFySyItem.sy.push({name:stringUtil.language(602),val:-cargo.purchaseCostMoney});
                    cargoFySyItem.syMoney = cargoFySyItem.syMoney - cargo.purchaseCostMoney;
                }
                  if(cargoFySyItem.fyMoney!=0){
                    cargoFySyItem.sy.push({name:stringUtil.language(603),val:-cargoFySyItem.fyMoney});
                    cargoFySyItem.syMoney = cargoFySyItem.syMoney - cargoFySyItem.fyMoney;
                  }
                  
            }else if(cargo.cargoMode == 2){
                cargoFySyItem.sy.push({name:stringUtil.language(601),val:taltalStallsMoney});
                cargoFySyItem.syMoney = cargoFySyItem.syMoney + taltalStallsMoney;
                if(cargo.entrustMoney!=0){
                    cargoFySyItem.sy.push({name:stringUtil.language(941),val:cargo.entrustMoney});
                    cargoFySyItem.syMoney = cargoFySyItem.syMoney + cargo.entrustMoney;
                  }
                if(cargo.disburdenMoney!=0){
                    cargoFySyItem.sy.push({name:JSON.parse(localStorage.getItem("currentStallItem")).disburdenText,val:cargo.disburdenMoney});
                    cargoFySyItem.syMoney = cargoFySyItem.syMoney + cargo.disburdenMoney;
                  }
                if(cargo.rebateMoney!=0){
                    cargoFySyItem.sy.push({name:stringUtil.language(604),val:-cargo.rebateMoney});
                    cargoFySyItem.syMoney = cargoFySyItem.syMoney - cargo.rebateMoney;
                }
            }
            
            

            if(cargoFySyItem.fy.length>cargoFySyItem.columnNum){
                cargoFySyItem.columnNum = cargoFySyItem.fy.length;
            }
            if(cargoFySyItem.sy.length>cargoFySyItem.columnNum){
                cargoFySyItem.columnNum = cargoFySyItem.sy.length;
            }
            this.state.uiObj.cargoFySy[cargo.cargoId] = cargoFySyItem;
            this.state.uiObj.tatolSy = this.state.uiObj.tatolSy + cargoFySyItem.syMoney;
        }
        this.state.uiObj.inMoneyMx.push({name:stringUtil.language(605),val:this.state.uiObj.tatolSy});
        for(let i=0;i<this.state.saleReportDaily.saleCosts.length;i++){
            let item = this.state.saleReportDaily.saleCosts[i];
            if(item.payee==1){
                this.state.uiObj.inMoneyMx.push({name:item.costClauseName,val:item.money}); 
            }
        }
        for(let i=0;i< this.state.saleReportDaily.shopCollectSettle.length;i++){
            let item = this.state.saleReportDaily.shopCollectSettle[i];
            this.state.uiObj.inMoneyMx.push({name:item.clauseName,val:item.money});    
        }
        for(let i=0;i< this.state.saleReportDaily.shopExpendSettle.length;i++){
            let item = this.state.saleReportDaily.shopExpendSettle[i];
            this.state.uiObj.outMoneyMx.push({name:item.clauseName,val:item.money});    
        }
        if( this.state.uiObj.inMoneyMx.length>this.state.uiObj.outMoneyMx.length){
            this.state.uiObj.inOutColumnNum = this.state.uiObj.inMoneyMx.length;
        }else{
            this.state.uiObj.inOutColumnNum =   this.state.uiObj.outMoneyMx.length;
        }
        this.state.uiObj.tatolCollectMoney = this.state.saleReportDaily.customerCollect + this.state.saleReportDaily.shopCollect;
        this.state.uiObj.tatolPayMoney = this.state.saleReportDaily.ownerExpend + this.state.saleReportDaily.shopExpend;
        this.state.uiObj.tatolPayMoney = this.state.uiObj.tatolPayMoney + this.state.saleReportDaily.supplierPay;
        this.state.uiObj.tatolPayMoney = this.state.uiObj.tatolPayMoney + this.state.saleReportDaily.workerPay;
        this.state.uiObj.tatolPayMoney = this.state.uiObj.tatolPayMoney + this.state.saleReportDaily.ownerPayPay;
        this.setState({});
    }
    initData(){
        stringUtil.httpRequest(urlUtil.SUMM_SALE_REPORT_DAILY_URL,{
            date:this.state.dateTimeStr
        },['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.state.saleReportDaily = successResponse.data;
                this.initUIObj();
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    }
     getCostMoney(costs,id){
        let money = 0;
        for(let i=0;i<costs.length;i++){
          if(costs[i].costClauseId==id){
            money = costs[i].money;
            break;
          }
        }
        return money;
     } 

     renderMx(){
        let views = [];
        let tabHead = <tr style={{backgroundColor:'#E9F1F7'}}>
            <td style={{height:'30px',paddingLeft:'5px',paddingRight:'5px',textAlign:'center',borderBottomColor:'#000',borderBottomWidth:'1px',borderBottomStyle:'solid'}}>{stringUtil.language(606)}</td>
            <td style={{height:'30px',paddingLeft:'5px',paddingRight:'5px',textAlign:'center',borderBottomColor:'#000',borderBottomWidth:'1px',borderBottomStyle:'solid'}}>{stringUtil.language(607)}</td>
            <td style={{height:'30px',paddingLeft:'5px',paddingRight:'5px',textAlign:'center',borderBottomColor:'#000',borderBottomWidth:'1px',borderBottomStyle:'solid'}}>{stringUtil.language(1120)}</td>
        </tr>
        views.push(tabHead);
        let totalIn = 0;
        let totalOut = 0;
        for(let i=0;i<this.state.uiObj.inOutColumnNum;i++){
            let inLabel = '';
            let inMoney = '';
            let outLabel = '';
            let outMoney = '';
            if(i<this.state.uiObj.inMoneyMx.length){
                inLabel = this.state.uiObj.inMoneyMx[i].name;
                totalIn = totalIn + this.state.uiObj.inMoneyMx[i].val;
                inMoney = arithUtil.fenToYuan(this.state.uiObj.inMoneyMx[i].val);
            }
            if(i<this.state.uiObj.outMoneyMx.length){
                outLabel = this.state.uiObj.outMoneyMx[i].name;
                totalOut = totalOut + this.state.uiObj.outMoneyMx[i].val;
                outMoney = arithUtil.fenToYuan(this.state.uiObj.outMoneyMx[i].val);
            }
            let tabMid = <tr>
                <td style={{height:'30px',paddingLeft:'5px',paddingRight:'5px',textAlign:'left'}}>{inLabel}&ensp;&ensp;&ensp;&ensp;{inMoney}</td>
            <td style={{height:'30px',paddingLeft:'5px',paddingRight:'5px',textAlign:'left'}}>{outLabel}&ensp;&ensp;&ensp;&ensp;{outMoney}</td>
            <td style={{height:'30px',paddingLeft:'5px',paddingRight:'5px',textAlign:'center'}}></td>
            </tr>;
             views.push(tabMid);
        }
        let ykMoney = totalIn - totalOut;
        let tabBottom = <tr>
              <td style={{height:'30px',paddingLeft:'5px',paddingRight:'5px',textAlign:'center'}}>{stringUtil.language(890)}{arithUtil.fenToYuan(totalIn)}</td>
            <td style={{height:'30px',paddingLeft:'5px',paddingRight:'5px',textAlign:'center'}}>{stringUtil.language(890)}{arithUtil.fenToYuan(totalOut)}</td>
            <td style={{height:'30px',paddingLeft:'5px',paddingRight:'5px',textAlign:'center'}}>{arithUtil.fenToYuan(ykMoney)}</td>
        </tr>;
         views.push(tabBottom);
         return views;
     }

    renderLeftCargo(){
        let views = [];
        if(this.state.saleReportDaily.cargos){
        for(let i=0;i<this.state.saleReportDaily.cargos.length;i++){
            let item = this.state.saleReportDaily.cargos[i];
            let cargoFySyItem = this.state.uiObj.cargoFySy[item.cargoId];
            let tabTop = <tr style={{backgroundColor:'#E9F1F7'}}>
                 <td style={{height:'30px',paddingLeft:'5px',paddingRight:'5px',fontWeight:'bold',borderTopColor:'#000',borderTopWidth:'1px',borderTopStyle:'solid'}} colSpan={(this.state.uiObj.isShowDiff?9:8)+this.state.uiObj.costRow.length}>{item.cargoMode==1?(stringUtil.language(426)+(item.targetId==0?'':item.targetName)):item.targetName}&ensp;&ensp;&ensp;&ensp;{stringUtil.language(1175)}{item.cargoNo}{stringUtil.language(1176)}</td>
            </tr>;
            views.push(tabTop);
            let tabHead = <tr style={{backgroundColor:'#E9F1F7'}}>
            <td style={{height:'30px',paddingLeft:'5px',paddingRight:'5px',textAlign:'center',borderBottomColor:'#000',borderBottomWidth:'1px',borderBottomStyle:'solid'}}>{stringUtil.language(608)}</td>
            <td style={{height:'30px',paddingLeft:'5px',paddingRight:'5px',textAlign:'center',borderBottomColor:'#000',borderBottomWidth:'1px',borderBottomStyle:'solid'}}>{stringUtil.language(1021)}</td>
            <td style={{height:'30px',paddingLeft:'5px',paddingRight:'5px',textAlign:'center',borderBottomColor:'#000',borderBottomWidth:'1px',borderBottomStyle:'solid'}}>{stringUtil.language(1022)}</td>
            <td style={{height:'30px',paddingLeft:'5px',paddingRight:'5px',textAlign:'center',borderBottomColor:'#000',borderBottomWidth:'1px',borderBottomStyle:'solid'}}>{stringUtil.language(966)}</td>
            <td style={{height:'30px',paddingLeft:'5px',paddingRight:'5px',textAlign:'center',borderBottomColor:'#000',borderBottomWidth:'1px',borderBottomStyle:'solid'}}>{stringUtil.language(961)}</td>
            {this.state.uiObj.isShowDiff?<td style={{height:'30px',paddingLeft:'5px',paddingRight:'5px',textAlign:'center',borderBottomColor:'#000',borderBottomWidth:'1px',borderBottomStyle:'solid'}}>{stringUtil.language(538)}</td>:""}
            {this.state.uiObj.isShowOwne?<td style={{height:'30px',paddingLeft:'5px',paddingRight:'5px',textAlign:'center',borderBottomColor:'#000',borderBottomWidth:'1px',borderBottomStyle:'solid'}}>{stringUtil.language(1257)}</td>:""}
             {
                 this.state.uiObj.costRow.map((subItem,subKey)=>{
                     return(
                         <td style={{height:'30px',paddingLeft:'5px',paddingRight:'5px',textAlign:'center',borderBottomColor:'#000',borderBottomWidth:'1px',borderBottomStyle:'solid'}}>{subItem.name}</td>
                     )
                 })
             }
             <td style={{height:'30px',paddingLeft:'5px',paddingRight:'5px',textAlign:'center',borderBottomColor:'#000',borderBottomWidth:'1px',borderBottomStyle:'solid'}}>{stringUtil.language(1061)}</td>
             <td style={{height:'30px',paddingLeft:'5px',paddingRight:'5px',textAlign:'center',borderBottomColor:'#000',borderBottomWidth:'1px',borderBottomStyle:'solid'}}>{item.cargoMode==0?stringUtil.language(609):(item.cargoMode==1?stringUtil.language(610):stringUtil.language(611))}</td>
             <td style={{height:'30px',paddingLeft:'5px',paddingRight:'5px',textAlign:'center',borderBottomColor:'#000',borderBottomWidth:'1px',borderBottomStyle:'solid'}}>{stringUtil.language(601)}</td>
            </tr>;
            views.push(tabHead);
            let totalNumber = 0;
            let totalWeight = 0;
            let totalCost = {};
            let totalMoney = 0;
            let totalOwnerMoney =0;
            let totalPurchaseMoney = 0;
            let totalWorkerMoney = 0;
            let totalStallsMoney = 0 ;
            let totalDiffGoodsMoney = 0;
            let totalOwnerDeductGoodsMoney = 0;
            for(let j=0;j<cargoFySyItem.columnNum;j++){
                if(j<item.commodities.length){
                    totalNumber = totalNumber + item.commodities[j].number;
                    totalWeight = totalWeight + item.commodities[j].weight;
                    totalMoney = totalMoney + item.commodities[j].money;
                    totalOwnerMoney = totalOwnerMoney + item.commodities[j].ownerMoney;
                    totalPurchaseMoney = totalPurchaseMoney + item.commodities[j].purchaseMoney;
                    totalWorkerMoney = totalWorkerMoney + item.commodities[j].workerMoney;
                    totalStallsMoney = totalStallsMoney + item.commodities[j].stallsMoney;
                    totalDiffGoodsMoney = totalDiffGoodsMoney + item.commodities[j].diffGoodsMoney;
                    totalOwnerDeductGoodsMoney = totalOwnerDeductGoodsMoney + item.commodities[j].ownerDeductGoodsMoney;
                    let junjiaNumber=0,junjiaWeight=0;
                    if(item.commodities[j].number!=0)junjiaNumber = item.commodities[j].goodsMoney.div(item.commodities[j].number);
                    if(item.commodities[j].weight!=0)junjiaWeight = item.commodities[j].goodsMoney.div(arithUtil.keToWeight(item.commodities[j].weight,this.state.weightMode));
                    let tabMid = <tr>
                      <td style={{height:'30px',paddingLeft:'5px',paddingRight:'5px',textAlign:'left'}}>{item.commodities[j].commodityName}</td>
                      <td style={{height:'30px',paddingLeft:'5px',paddingRight:'5px',textAlign:'center'}}>{item.commodities[j].number}</td>
                      <td style={{height:'30px',paddingLeft:'5px',paddingRight:'5px',textAlign:'center'}}>{arithUtil.keToWeight(item.commodities[j].weight,this.state.weightMode)}</td>   
                      <td style={{height:'30px',paddingLeft:'5px',paddingRight:'5px',textAlign:'center'}}>{arithUtil.fenToYuan(item.commodities[j].jianJunJia)}</td>  
                      <td style={{height:'30px',paddingLeft:'5px',paddingRight:'5px',textAlign:'center'}}>{arithUtil.unitPriceTo(arithUtil.fenToYuan(item.commodities[j].zhongJunJia),0,this.state.weightMode)}</td>
                      {this.state.uiObj.isShowDiff?<td style={{height:'30px',paddingLeft:'5px',paddingRight:'5px',textAlign:'center'}}>{arithUtil.fenToYuan(item.commodities[j].diffGoodsMoney)}</td>:""}
                      {this.state.uiObj.isShowOwne?<td style={{height:'30px',paddingLeft:'5px',paddingRight:'5px',textAlign:'center'}}>{arithUtil.fenToYuan(item.commodities[j].ownerDeductGoodsMoney)}</td>:""}
                      {
                      this.state.uiObj.costRow.map((subItem,subKey)=>{
                        let costMoney = this.getCostMoney(item.commodities[j].costs,subItem.id);
                        if(totalCost[subKey]){
                            totalCost[subKey]=totalCost[subKey]+costMoney;
                        }else{
                            totalCost[subKey]=costMoney;
                        }
                          return(
                                <td style={{height:'30px',paddingLeft:'5px',paddingRight:'5px',textAlign:'center'}}>{arithUtil.fenToYuan(costMoney)}</td>
                            )
                       })
                      }   
                      <td style={{height:'30px',paddingLeft:'5px',paddingRight:'5px',textAlign:'center'}}>{arithUtil.fenToYuan(item.commodities[j].money)}</td>
                      <td style={{height:'30px',paddingLeft:'5px',paddingRight:'5px',textAlign:'center'}}>{item.cargoMode==0?arithUtil.fenToYuan(item.commodities[j].ownerMoney):(item.cargoMode==1?arithUtil.fenToYuan(item.commodities[j].purchaseMoney):arithUtil.fenToYuan(item.commodities[j].workerMoney))}</td>
                      <td style={{height:'30px',paddingLeft:'5px',paddingRight:'5px',textAlign:'center'}}>{arithUtil.fenToYuan(item.commodities[j].stallsMoney)}</td> 
                   </tr>;
                   views.push(tabMid);
                }else{
                    let tabMid = <tr>
                        <td style={{height:'30px'}} colSpan={6+this.state.uiObj.costRow.length}></td>
                   </tr>;
                   views.push(tabMid);
                }
            }
            let tabBottom = <tr>
                <td style={{height:'30px',paddingLeft:'5px',paddingRight:'5px',textAlign:'center'}}>{stringUtil.language(1072)}</td>
                      <td style={{height:'30px',paddingLeft:'5px',paddingRight:'5px',textAlign:'center'}}>{totalNumber}</td>
                      <td style={{height:'30px',paddingLeft:'5px',paddingRight:'5px',textAlign:'center'}}>{arithUtil.keToWeight(totalWeight,this.state.weightMode)}</td>  
                      <td style={{height:'30px',paddingLeft:'5px',paddingRight:'5px',textAlign:'center'}}></td>
                      <td style={{height:'30px',paddingLeft:'5px',paddingRight:'5px',textAlign:'center'}}></td>
                      {this.state.uiObj.isShowDiff?<td style={{height:'30px',paddingLeft:'5px',paddingRight:'5px',textAlign:'center'}}>{arithUtil.fenToYuan(totalDiffGoodsMoney)}</td>:""}
                      {this.state.uiObj.isShowOwne?<td style={{height:'30px',paddingLeft:'5px',paddingRight:'5px',textAlign:'center'}}>{arithUtil.fenToYuan(totalOwnerDeductGoodsMoney)}</td>:""}
                      {this.state.uiObj.costRow.map((subItem,subKey)=>{
                         return(
                            <td style={{height:'30px',paddingLeft:'5px',paddingRight:'5px',textAlign:'center'}}>{arithUtil.fenToYuan(totalCost[subKey])}</td>
                              )
                      })}
                      <td style={{height:'30px',paddingLeft:'5px',paddingRight:'5px',textAlign:'center'}}>{arithUtil.fenToYuan(totalMoney)}</td>
                      <td style={{height:'30px',paddingLeft:'5px',paddingRight:'5px',textAlign:'center'}}>{item.cargoMode==0?arithUtil.fenToYuan(totalOwnerMoney):(item.cargoMode==1?arithUtil.fenToYuan(totalPurchaseMoney):arithUtil.fenToYuan(totalWorkerMoney))}</td>
                      <td style={{height:'30px',paddingLeft:'5px',paddingRight:'5px',textAlign:'center'}}>{arithUtil.fenToYuan(totalStallsMoney)}</td> 
            </tr>;
            views.push(tabBottom);
        }
      }
        return views;
    }

    renderRightCargo(){
        let views = [];
        if(this.state.saleReportDaily.cargos){
            for(let i=0;i<this.state.saleReportDaily.cargos.length;i++){
                let item = this.state.saleReportDaily.cargos[i];
                let cargoFySyItem = this.state.uiObj.cargoFySy[item.cargoId];
                let tabTop = <tr style={{backgroundColor:'#E9F1F7'}}>
                 <td style={{height:'30px',paddingLeft:'5px',paddingRight:'5px',borderTopColor:'#000',borderTopWidth:'1px',borderTopStyle:'solid'}} colSpan={2}></td>
                </tr>;
                 views.push(tabTop);
                 let tabHead = <tr style={{backgroundColor:'#E9F1F7'}}>
                 <td style={{height:'30px',paddingLeft:'5px',paddingRight:'5px',textAlign:'center',borderBottomColor:'#000',borderBottomWidth:'1px',borderBottomStyle:'solid'}}>{stringUtil.language(603)}</td>
                 <td style={{height:'30px',paddingLeft:'5px',paddingRight:'5px',textAlign:'center',borderBottomColor:'#000',borderBottomWidth:'1px',borderBottomStyle:'solid'}}>{stringUtil.language(612)}</td></tr>;
                 views.push(tabHead);
                 for(let j=0;j<cargoFySyItem.columnNum;j++){
                    let fyLabel = '';
                    let fyMoney = '';
                    let syLabel = '';
                    let syMoney = '';
                    if(j<cargoFySyItem.fy.length){
                        fyLabel = cargoFySyItem.fy[j].name;
                        fyMoney = arithUtil.fenToYuan(cargoFySyItem.fy[j].val);
                    }
                    if(j<cargoFySyItem.sy.length){
                        syLabel = cargoFySyItem.sy[j].name;
                        syMoney = arithUtil.fenToYuan(cargoFySyItem.sy[j].val);
                    }
                    let tabMid = <tr>
                       <td style={{height:'30px',paddingLeft:'5px',paddingRight:'5px',textAlign:'left'}}>{fyLabel}&ensp;&ensp;&ensp;&ensp;{fyMoney}</td>
                        <td style={{height:'30px',paddingLeft:'5px',paddingRight:'5px',textAlign:'left'}}>{syLabel}&ensp;&ensp;&ensp;&ensp;{syMoney}</td>
                    </tr>;
                    views.push(tabMid);
                 }
                 let tabBottom = <tr>
                        <td style={{height:'30px',paddingLeft:'5px',paddingRight:'5px',textAlign:'center'}}>{stringUtil.language(890)}{arithUtil.fenToYuan(cargoFySyItem.fyMoney)}</td>
                        <td style={{height:'30px',paddingLeft:'5px',paddingRight:'5px',textAlign:'center'}}>{stringUtil.language(890)}{arithUtil.fenToYuan(cargoFySyItem.syMoney)}</td>
                 </tr>
                 views.push(tabBottom);
            }
        }
        return views;
    }

    render(){
        return(<div style={{width:'98.5%',height:'97%',padding:'0.5%'}}>
            <TabTopActivity viewHeight='6%' context={this.props.context} navigationStacks={this.props.navigationStacks}/>
            <div style={{height:'94%',paddingLeft:arithUtil.pxToDp(10),paddingRight:arithUtil.pxToDp(10),backgroundColor:'#FFF'}}>
                <div style={{height:'8%',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',borderBottomStyle:'solid',borderBottomWidth:1,borderBottomColor:'#A9A9A9'}}>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <span style={{width:60}}>{stringUtil.language(888)}</span>
                        <div style={styles.time}><DatePicker locale='zhCN' selected={arithUtil.strToDate(this.state.dateTimeStr)} onChange={(date) => {
                            this.state.dateTimeStr = arithUtil.formatToTimeStr(date,'Y-M-D');
                            this.initData();
                        }} /></div>
                    </div>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                            this.setState({isShowTabTitle:true},()=>{
                                const printArea = document.getElementById('printArea');
                                const iframe = document.createElement('IFRAME');
                                let doc = null;
                                iframe.setAttribute('style', 'position:absolute;width:0px;height:0px;left:500px;top:500px;-webkit-print-color-adjust: exact');
                                document.body.appendChild(iframe);
                                doc = iframe.contentWindow.document;
                                doc.write(printArea.innerHTML);
                                doc.close();
                                iframe.contentWindow.focus();// 获取iframe的焦stringUtil.language(1198)，stringUtil.language(1190)iframe开始stringUtil.language(1045)
                                iframe.contentWindow.print();
                                if (navigator.userAgent.indexOf("MSIE")>0){document.body.removeChild(iframe);}
                                this.setState({isShowTabTitle:false});
                            });
                        }}>
                            <i className="iconfontIndexCss" style={{fontSize:17,color:'#52A056'}}>&#xe867;</i>
                            <span style={{color:'#52A056',fontSize:15,marginLeft:5}}>{stringUtil.language(1045)}</span>
                        </div>
                    </div>
                </div>
                <div style={{width:'100%',height:'92%',overflowY:'auto'}} id='printArea'>
                    {this.state.isShowTabTitle?<div style={{width:'100%',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'flex-start'}}>
                        <span style={{fontSize:25,fontWeight:'bold'}}>{localStorage.getItem("currentStallItem")?(JSON.parse(localStorage.getItem("currentStallItem")).marketName +JSON.parse(localStorage.getItem("currentStallItem")).name):''}<label style={{fontWeight:'normal'}}>{stringUtil.language(162)}</label></span>
                        <span style={{fontSize:17}}>{this.state.dateTimeStr}&ensp;&ensp;&ensp;&ensp;{stringUtil.language(967)}{this.state.saleReportDaily.cargos?this.state.saleReportDaily.cargos.length:0}{stringUtil.language(1123)}</span>
                    </div>:''}
                    <div style={{width:'100%',marginTop:'10px',display:'flex',flexDirection:'row'}}>
                      <table cellSpacing={1} style={{width:'65%',borderWidth:"1px",borderColor:'#000',borderStyle:'solid'}}>
                        {this.renderLeftCargo()}
                      </table>
                      <table cellSpacing={1} style={{width:'34%',marginLeft:'1%',borderWidth:"1px",borderColor:'#000',borderStyle:'solid'}}>
                         {this.renderRightCargo()}
                      </table>
                    </div>
                    <div style={{width:'100%',marginTop:'10px',display:'flex',flexDirection:'row'}}>
                       <div style={{width:'65%',display:'flex',flexDirection:'column',borderWidth:"1px",borderColor:'#000',borderStyle:'solid'}}>
                          <div style={{minHeight:'45px',width:'99.8%',display:'flex',flexDirection:'row',backgroundColor:'#E9F1F7'}}>
                             <div style={{width:'16%',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:"center"}}>
                                <div>{stringUtil.language(613)}</div>
                                <div>{this.state.saleReportDaily.beforeArrears?arithUtil.fenToYuan(this.state.saleReportDaily.beforeArrears):0}</div>
                             </div>
                             <div style={{width:'16%',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:"center"}}>
                                <div>{stringUtil.language(614)}</div>
                                <div>{this.state.saleReportDaily.wholesaleMoney?arithUtil.fenToYuan(this.state.saleReportDaily.wholesaleMoney):0}</div>
                             </div>
                             <div style={{width:'16%',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:"center"}}>
                                <div>{stringUtil.language(915)}</div>
                                <div>{this.state.saleReportDaily.nowCollectMoney?arithUtil.fenToYuan(this.state.saleReportDaily.nowCollectMoney):0}</div>
                             </div>
                             <div style={{width:'16%',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:"center"}}>
                                <div>{stringUtil.language(916)}</div>
                                <div>{this.state.saleReportDaily.beforeCollectMoney?arithUtil.fenToYuan(this.state.saleReportDaily.beforeCollectMoney):0}</div>
                             </div>
                             <div style={{width:'16%',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:"center"}}>
                                <div>{stringUtil.language(488)}</div>
                                <div>{this.state.saleReportDaily.discountMoney?arithUtil.fenToYuan(this.state.saleReportDaily.discountMoney):0}</div>
                             </div>
                             <div style={{width:'16%',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:"center"}}>
                                <div>{stringUtil.language(476)}</div>
                                <div>{this.state.saleReportDaily.recordArrears?arithUtil.fenToYuan(this.state.saleReportDaily.recordArrears):0}</div>
                             </div>  
                            </div>
                            <div style={{minHeight:'45px',width:'100%',display:'flex',flexDirection:'row',borderTopColor:'#000',borderTopWidth:'1px',borderTopStyle:'solid'}}>
                            <div style={{width:'14%',marginLeft:'1%',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:"center"}}>
                                <div>{stringUtil.language(894)}</div>
                                <div>{arithUtil.fenToYuan(this.state.uiObj.tatolCollectMoney)}</div>
                             </div>
                             <div style={{width:'2%',textAlign:'center'}}>=</div>
                             <div style={{width:'14%',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:"center"}}>
                                <div>{stringUtil.language(615)}</div>
                                <div>{this.state.saleReportDaily.customerCollect?arithUtil.fenToYuan(this.state.saleReportDaily.customerCollect):0}</div>
                             </div>
                             <div style={{width:'2%',textAlign:'center'}}>+</div>
                             <div style={{width:'14%',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:"center"}}>
                                <div>{stringUtil.language(477)}</div>
                                <div>{this.state.saleReportDaily.shopCollect?arithUtil.fenToYuan(this.state.saleReportDaily.shopCollect):0}</div>
                             </div>
                            </div>
                            <div style={{minHeight:'45px',width:'99.8%',display:'flex',flexDirection:'row',flexWrap:'wrap',backgroundColor:'#E9F1F7',borderTopColor:'#000',borderTopWidth:'1px',borderTopStyle:'solid'}}>
                            <div style={{width:'16%',textAlign:'center'}}>{stringUtil.language(1083)}：</div>
                            { this.state.saleReportDaily.collectAccounts?this.state.saleReportDaily.collectAccounts.map((item,key)=>{
                                return(<div style={{width:'16%',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:"center"}}>
                                <div>{item.accountName}</div>
                                <div>{arithUtil.fenToYuan(item.money)}</div>
                             </div> )
                            }):""
                            }
                            </div>
                            <div style={{minHeight:'45px',width:'100%',display:'flex',flexDirection:'row',borderTopColor:'#000',borderTopWidth:'1px',borderTopStyle:'solid'}}>
                            <div style={{width:'14%',marginLeft:'1%',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:"center"}}>
                                <div>{stringUtil.language(895)}</div>
                                <div>{arithUtil.fenToYuan(this.state.uiObj.tatolPayMoney)}</div>
                             </div>
                             <div style={{width:'2%',textAlign:'center'}}>=</div>
                             <div style={{width:'14%',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:"center"}}>
                                <div>{stringUtil.language(616)}</div>
                                <div>{this.state.saleReportDaily.shopExpend?arithUtil.fenToYuan(this.state.saleReportDaily.shopExpend):0}</div>
                             </div>
                             <div style={{width:'2%',textAlign:'center'}}>+</div>
                             <div style={{width:'14%',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:"center"}}>
                                <div>{stringUtil.language(510)}</div>
                                <div>{this.state.saleReportDaily.ownerExpend?arithUtil.fenToYuan(this.state.saleReportDaily.ownerExpend):0}</div>
                             </div>
                             <div style={{width:'2%',textAlign:'center'}}>+</div>
                             <div style={{width:'14%',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:"center"}}>
                                <div>{stringUtil.language(444)}</div>
                                <div>{this.state.saleReportDaily.supplierPay?arithUtil.fenToYuan(this.state.saleReportDaily.supplierPay):0}</div>
                             </div>
                             <div style={{width:'2%',textAlign:'center'}}>+</div>
                             <div style={{width:'14%',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:"center"}}>
                                <div>{stringUtil.language(930)}</div>
                                <div>{this.state.saleReportDaily.ownerPay?arithUtil.fenToYuan(this.state.saleReportDaily.ownerPay):0}</div>
                             </div>
                             <div style={{width:'2%',textAlign:'center'}}>+</div>
                             <div style={{width:'14%',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:"center"}}>
                                <div>{stringUtil.language(931)}</div>
                                <div>{this.state.saleReportDaily.workerPay?arithUtil.fenToYuan(this.state.saleReportDaily.workerPay):0}</div>
                             </div>
                            </div>
                            <div style={{minHeight:'45px',width:'99.8%',display:'flex',flexDirection:'row',flexWrap:'wrap',backgroundColor:'#E9F1F7',borderTopColor:'#000',borderTopWidth:'1px',borderTopStyle:'solid'}}>
                            <div style={{width:'16%',textAlign:'center'}}>{stringUtil.language(1083)}：</div>
                            { this.state.saleReportDaily.payAccounts?this.state.saleReportDaily.payAccounts.map((item,key)=>{
                                return(<div style={{width:'16%',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:"center"}}>
                                <div>{item.accountName}</div>
                                <div>{arithUtil.fenToYuan(item.money)}</div>
                             </div> )
                            }):""
                            }
                            </div>
                            <div style={{minHeight:'45px',width:'100%',display:'flex',flexDirection:'row',flexWrap:'wrap',borderTopColor:'#000',borderTopWidth:'1px',borderTopStyle:'solid'}}>
                               <div style={{width:'16%',textAlign:'center'}}>{stringUtil.language(447)}:</div>
                               { this.state.saleReportDaily.handMoneySettle?this.state.saleReportDaily.handMoneySettle.map((item,key)=>{
                                return(<div style={{width:'16%',textAlign:'center'}}>
                                       {item.accountOutName}{stringUtil.language(1181)}{item.accountInName}{arithUtil.fenToYuan(item.money)}
                             </div> )
                            }):""
                            }
                            </div>  
                            <div style={{minHeight:'45px',width:'99.8%',display:'flex',flexDirection:'row',flexWrap:'wrap',backgroundColor:'#E9F1F7',borderTopColor:'#000',borderTopWidth:'1px',borderTopStyle:'solid'}}>
                            <div style={{width:'16%',textAlign:'center'}}>{stringUtil.language(1062)}：</div>
                            { this.state.saleReportDaily.balanceAccounts?this.state.saleReportDaily.balanceAccounts.map((item,key)=>{
                                return(<div style={{width:'16%',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:"center"}}>
                                <div>{item.accountName}</div>
                                <div>{arithUtil.fenToYuan(item.money)}</div>
                             </div> )
                            }):""
                            }
                            </div>
                        </div>
                        <div style={{width:'34%',marginLeft:'1%',display:'flex',flexDirection:'column'}}>
                              <table cellSpacing={1} style={{width:'100%',marginBottom:'10px',borderWidth:"1px",borderColor:'#000',borderStyle:'solid'}}>
                                {this.renderMx()}
                              </table>
                              {(this.state.saleReportDaily.advanceMoney&&this.state.saleReportDaily.advanceMoney!=0)?<div>
                                {stringUtil.language(617)}：{arithUtil.fenToYuan(this.state.saleReportDaily.advanceMoney)}
                              </div>:''}
                              {this.state.saleReportDaily.saleCosts?
                              this.state.saleReportDaily.saleCosts.map((item,key)=>{
                                return (<div>
                                    {item.costClauseName}：{arithUtil.fenToYuan(item.money)}
                                  </div>)
                              })
                              :""}
                        </div>
                    </div>
                </div>
            </div>
        </div>)
    }
}

const styles = {
    time:{
        height:arithUtil.pxToDp(30),
        display:'flex',
        flexDirection:'row',
        justifyContent:'center',
        alignItems:'center',
        borderStyle:'solid',
        borderWidth:1,
        borderColor:'#A9A9A9',
        borderRadius:3,
    }
};