import React,{Component} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import * as arithUtil from '../utils/arithUtil.js';
import * as stringUtil from '../utils/stringUtil.js';
import * as otherUtil from '../utils/otherUtil.js';
import urlUtil from '../utils/urlUtil.js';
import ToastBox from '../components/ToastBox';
import MaskModal from '../components/MaskModal';
import TabTopActivity from '../components/TabTopActivity';
import Pagination from '../components/Pagination';

 export default class CargosFinishActivity extends Component{
    constructor(props) {
      super(props);
      this.state={
        weightMode:localStorage.getItem("shopConfigInfo")&&JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit?JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit:0,
        discounts:{},
        wholesaleCargosAll:[],wholesaleCargosData:[]
      };
    }
    componentDidMount(){
        stringUtil.httpRequest(urlUtil.SALE_CARGO_DISCOUNT_BATCH_URL,null,['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.setState({discounts:successResponse.data});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
        stringUtil.httpRequest(urlUtil.CARGO_SEARCH_URL,{
            state:1,//-1stringUtil.language(1097) 0 待接 1 售卖 2 stringUtil.language(1053)
            cargoMode:0,//-1全部，0代销，1自营
            currPage:0,
            pageSize:1000
        },['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.state.wholesaleCargosAll=successResponse.data.items;
                this.setState({wholesaleCargosData:JSON.parse(JSON.stringify(this.state.wholesaleCargosAll))});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    }

    render(){
        return(<div style={{width:'98.5%',height:'97%',padding:'0.5%'}}>
            <TabTopActivity viewHeight='6%' context={this.props.context} navigationStacks={this.props.navigationStacks}/>
            <div style={{height:'93%',paddingLeft:8,paddingRight:8,backgroundColor:'#FFF',display:'flex',flexDirection:'column'}}>
                <div style={{height:35,backgroundColor:'#F2F2F2',display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <div style={{width:0,flexGrow:1,height:'100%',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                        if(this.state.isAllChoice){this.state.isAllChoice=0;
                            this.state.wholesaleCargosData=JSON.parse(JSON.stringify(this.state.wholesaleCargosAll));
                        }else{this.state.isAllChoice=1;
                            let tag={};
                            for(let i=0;i<this.state.wholesaleCargosData.length;i++){
                                this.state.wholesaleCargosData[i].isChoiceChenpinglong=1;
                                this.state.wholesaleCargosData[i].entrustMoneyChenpinglong=0;
                                this.state.wholesaleCargosData[i].disburdenMoneyChenpinglong=0;
                                this.state.wholesaleCargosData[i].discountMoneyChenpinglong=0;
                                if(!this.state.wholesaleCargosData[i].cargoMode&&!tag[this.state.wholesaleCargosData[i].cargoMode+'_'+this.state.wholesaleCargosData[i].targetId]){tag[this.state.wholesaleCargosData[i].cargoMode+'_'+this.state.wholesaleCargosData[i].targetId]=1;
                                    this.state.wholesaleCargosData[i].discountMoneyChenpinglong=this.state.discounts[this.state.wholesaleCargosData[i].cargoMode+'_'+this.state.wholesaleCargosData[i].targetId]?this.state.discounts[this.state.wholesaleCargosData[i].cargoMode+'_'+this.state.wholesaleCargosData[i].targetId]:0;
                                }
                            }
                        }this.setState({isAllChoice:this.state.isAllChoice});
                    }}>{this.state.isAllChoice?<i className="iconfontIndexCss" style={{fontSize:25,color:'#009A61'}}>&#xe698;</i>:<i className="iconfontIndexCss" style={{fontSize:25,color:'#696969'}}>&#xe6a7;</i>}</div>
                    <span style={{width:0,flexGrow:3,color:'#A9A9A9',fontSize:13,textAlign:'center'}}>{stringUtil.language(519)}</span>
                    <span style={{width:0,flexGrow:2,color:'#A9A9A9',fontSize:13,textAlign:'center'}}>{stringUtil.language(535)}</span>
                    <span style={{width:0,flexGrow:2,color:'#A9A9A9',fontSize:13,textAlign:'center'}}>{stringUtil.language(536)}</span>
                    <span style={{width:0,flexGrow:2,color:'#A9A9A9',fontSize:13,textAlign:'center'}}>{stringUtil.language(1259)}</span>
                    <span style={{width:0,flexGrow:2,color:'#A9A9A9',fontSize:13,textAlign:'center'}}>{stringUtil.language(1260)}</span>
                    <span style={{width:0,flexGrow:2,color:'#A9A9A9',fontSize:13,textAlign:'center'}}>{stringUtil.language(1261)}</span>
                    <span style={{width:0,flexGrow:2,color:'#A9A9A9',fontSize:13,textAlign:'center'}}>{stringUtil.language(963)}</span>
                    <span style={{width:0,flexGrow:2,color:'#A9A9A9',fontSize:13,textAlign:'center'}}>{stringUtil.language(543)}</span>
                    <span style={{width:0,flexGrow:2,color:'#A9A9A9',fontSize:13,textAlign:'center'}}>{stringUtil.language(1069)}</span>
                    <span style={{width:0,flexGrow:2,color:'#A9A9A9',fontSize:13,textAlign:'center'}}>{stringUtil.language(1262)}</span>
                </div>
                {(()=>{this.state.totalInfo={saleNumber:0,saleWeight:0,saleMoney:0,ownerMoney:0,entrustMoney:0,disburdenMoney:0,discountMoney:0,othersMoney:0,payOwner:0,choiceCount:0};})()}
                <div style={{height:0,flexGrow:1,borderStyle:'solid',borderColor:'#F0F0F0',borderWidth: 1,overflow:'auto'}}>
                    {this.state.wholesaleCargosData.map((item,key)=>{
                        if(item.isChoiceChenpinglong){this.state.totalInfo.choiceCount=this.state.totalInfo.choiceCount+1;}
                        this.state.totalInfo.saleNumber=(this.state.totalInfo.saleNumber).add(item.saleNumber);
                        this.state.totalInfo.saleWeight=(this.state.totalInfo.saleWeight).add(item.saleWeight);
                        this.state.totalInfo.saleMoney=(this.state.totalInfo.saleMoney).add(item.saleMoney);
                        let ownerMoney=item.balance;if(item.expends){for(let i=0;i<item.expends.length;i++){ownerMoney=ownerMoney+item.expends[i].money;}}
                        this.state.totalInfo.ownerMoney=(this.state.totalInfo.ownerMoney).add(ownerMoney);
                        this.state.totalInfo.entrustMoney=(this.state.totalInfo.entrustMoney).add(item.isChoiceChenpinglong&&item.entrustMoneyChenpinglong?item.entrustMoneyChenpinglong:0);
                        this.state.totalInfo.disburdenMoney=(this.state.totalInfo.disburdenMoney).add(item.isChoiceChenpinglong&&item.disburdenMoneyChenpinglong?item.disburdenMoneyChenpinglong:0);
                        this.state.totalInfo.discountMoney=(this.state.totalInfo.discountMoney).add(item.isChoiceChenpinglong&&item.discountMoneyChenpinglong?item.discountMoneyChenpinglong:0);
                        this.state.totalInfo.othersMoney=(this.state.totalInfo.othersMoney).add((item.saleMoney).sub(ownerMoney));
                        this.state.totalInfo.payOwner=(this.state.totalInfo.payOwner).add(ownerMoney.sub(item.isChoiceChenpinglong&&item.entrustMoneyChenpinglong?item.entrustMoneyChenpinglong:0)
                            .sub(item.isChoiceChenpinglong&&item.disburdenMoneyChenpinglong?item.disburdenMoneyChenpinglong:0)
                            .sub(item.isChoiceChenpinglong&&item.discountMoneyChenpinglong?item.discountMoneyChenpinglong:0)
                            .sub((item.saleMoney).sub(ownerMoney)));
                        return(<div style={{height:55,backgroundColor:key%2?'rgba(0,196,255,0.1)':'#FFF',display:'flex',flexDirection:'row',alignItems:'center'}} key={key}>
                            <div style={{width:0,flexGrow:1,height:'100%',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                                if(item.isChoiceChenpinglong){item.isChoiceChenpinglong=0;
                                }else{item.isChoiceChenpinglong=1;
                                    let currOwnerCount=0;
                                    for(let i=0;i<this.state.wholesaleCargosData.length;i++){
                                        if(this.state.wholesaleCargosData[i].isChoiceChenpinglong&&this.state.wholesaleCargosData[i].cargoMode==item.cargoMode&&this.state.wholesaleCargosData[i].targetId==item.targetId){
                                            currOwnerCount=currOwnerCount+1;
                                            if(currOwnerCount>1)break;
                                        }
                                    }
                                    item.entrustMoneyChenpinglong=0;
                                    item.disburdenMoneyChenpinglong=0;
                                    item.discountMoneyChenpinglong=0;
                                    if(!item.cargoMode&&currOwnerCount<2){item.discountMoneyChenpinglong=this.state.discounts[item.cargoMode+'_'+item.targetId]?this.state.discounts[item.cargoMode+'_'+item.targetId]:0;}
                                }this.setState({wholesaleCargosData:this.state.wholesaleCargosData});
                            }}>{item.isChoiceChenpinglong?<i className="iconfontIndexCss" style={{fontSize:25,color:'#009A61'}}>&#xe698;</i>:<i className="iconfontIndexCss" style={{fontSize:25,color:'#696969'}}>&#xe6a7;</i>}</div>
                            <div style={{width:0,flexGrow:3,display:'flex',flexDirection:'column',justifyContent:'center'}}>
                                <span style={{width:'100%',textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden',fontSize:18}}>{item.targetName?item.targetName:item.cargoMode==1?stringUtil.language(426):''}</span>
                                <span style={{width:'100%',textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden',color:'#A9A9A9',fontSize:10}}>{stringUtil.language(1237,item.cargoNo)}</span>
                            </div>
                            <span style={{width:0,flexGrow:2,fontSize:18,textAlign:'center'}}>{item.saleNumber}</span>
                            <span style={{width:0,flexGrow:2,fontSize:18,textAlign:'center'}}>{arithUtil.keToWeight(item.saleWeight,this.state.weightMode)}</span>
                            <span style={{width:0,flexGrow:2,fontSize:18,textAlign:'center'}}>{arithUtil.fenToYuan(item.saleMoney)}</span>
                            <span style={{width:0,flexGrow:2,fontSize:18,textAlign:'center'}}>{arithUtil.fenToYuan(ownerMoney)}</span>
                            <div style={{width:0,flexGrow:2,height:'100%',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                                {!item.cargoMode&&item.isChoiceChenpinglong?<input style={{width:'99%',height:'98%',fontSize:18,fontWeight:'bold',backgroundColor:'transparent',borderStyle:'solid',borderColor:'#d0d0d0',borderWidth:1,borderRadius:2,padding:0,textAlign:'center'}} value={this.state.wholesaleCargosData[key].entrustMoneyChenpinglong?arithUtil.fenToYuan(this.state.wholesaleCargosData[key].entrustMoneyChenpinglong):''} type='number' onChange={(event)=>{
                                    this.state.wholesaleCargosData[key].entrustMoneyChenpinglong=event.target.value!=''?arithUtil.yuanToFen(parseFloat(event.target.value)):0;
                                    this.setState({wholesaleCargosData:this.state.wholesaleCargosData});
                                }} placeholder='0'/>:''}
                            </div>
                            <div style={{width:0,flexGrow:2,height:'100%',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                                {!item.cargoMode&&item.isChoiceChenpinglong?<input style={{width:'99%',height:'98%',fontSize:18,fontWeight:'bold',backgroundColor:'transparent',borderStyle:'solid',borderColor:'#d0d0d0',borderWidth:1,borderRadius:2,padding:0,textAlign:'center'}} value={this.state.wholesaleCargosData[key].disburdenMoneyChenpinglong?arithUtil.fenToYuan(this.state.wholesaleCargosData[key].disburdenMoneyChenpinglong):''} type='number' onChange={(event)=>{
                                    this.state.wholesaleCargosData[key].disburdenMoneyChenpinglong=event.target.value!=''?arithUtil.yuanToFen(parseFloat(event.target.value)):0;
                                    this.setState({wholesaleCargosData:this.state.wholesaleCargosData});
                                    // let item=this.state.wholesaleCargosData[key];
                                    // this.refs['payOwnerRef'+key].innerHTML=arithUtil.fenToYuan(ownerMoney.sub(item.isChoiceChenpinglong&&item.entrustMoneyChenpinglong?item.entrustMoneyChenpinglong:0)
                                    //     .sub(item.isChoiceChenpinglong&&item.disburdenMoneyChenpinglong?item.disburdenMoneyChenpinglong:0)
                                    //     .sub(item.isChoiceChenpinglong&&item.discountMoneyChenpinglong?item.discountMoneyChenpinglong:0)
                                    //     .sub((item.saleMoney).sub(ownerMoney)));
                                }} placeholder='0'/>:''}
                            </div>
                            <div style={{width:0,flexGrow:2,height:'100%',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                                {!item.cargoMode&&item.isChoiceChenpinglong?<input style={{width:'99%',height:'98%',fontSize:18,fontWeight:'bold',backgroundColor:'transparent',borderStyle:'solid',borderColor:'#d0d0d0',borderWidth:1,borderRadius:2,padding:0,textAlign:'center'}} value={this.state.wholesaleCargosData[key].discountMoneyChenpinglong?arithUtil.fenToYuan(this.state.wholesaleCargosData[key].discountMoneyChenpinglong):''} type='number' onChange={(event)=>{
                                    this.state.wholesaleCargosData[key].discountMoneyChenpinglong=event.target.value!=''?arithUtil.yuanToFen(parseFloat(event.target.value)):0;
                                    this.setState({wholesaleCargosData:this.state.wholesaleCargosData});
                                }} placeholder='0'/>:''}
                            </div>
                            <span style={{width:0,flexGrow:2,fontSize:18,textAlign:'center'}}>{arithUtil.fenToYuan((item.saleMoney).sub(ownerMoney))}</span>
                            <span style={{width:0,flexGrow:2,fontSize:18,textAlign:'center'}} ref={'payOwnerRef'+key}>{arithUtil.fenToYuan(ownerMoney.sub(item.isChoiceChenpinglong&&item.entrustMoneyChenpinglong?item.entrustMoneyChenpinglong:0).sub(item.isChoiceChenpinglong&&item.disburdenMoneyChenpinglong?item.disburdenMoneyChenpinglong:0).sub(item.isChoiceChenpinglong&&item.discountMoneyChenpinglong?item.discountMoneyChenpinglong:0).sub((item.saleMoney).sub(ownerMoney)))}</span>
                        </div>);
                    })}
                </div>
                <div style={{height:60,backgroundColor:'#F2F2F2',display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <span style={{width:0,flexGrow:1,color:'#CD853F',textAlign:'center'}}>{stringUtil.language(890)}</span>
                    <span style={{width:0,flexGrow:3,color:'#CD853F',textAlign:'center'}}>{this.state.wholesaleCargosData.length} {stringUtil.language(1176)}</span>
                    <span style={{width:0,flexGrow:2,color:'#CD853F',textAlign:'center'}}>{this.state.totalInfo.saleNumber}</span>
                    <span style={{width:0,flexGrow:2,color:'#CD853F',textAlign:'center'}}>{arithUtil.keToWeight(this.state.totalInfo.saleWeight,this.state.weightMode)}</span>
                    <span style={{width:0,flexGrow:2,color:'#CD853F',textAlign:'center'}}>{arithUtil.fenToYuan(this.state.totalInfo.saleMoney)}</span>
                    <span style={{width:0,flexGrow:2,color:'#CD853F',textAlign:'center'}}>{arithUtil.fenToYuan(this.state.totalInfo.ownerMoney)}</span>
                    <div style={{width:0,flexGrow:10,height:'100%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <span style={{width:0,flexGrow:1,color:'#F00',fontSize:18,fontWeight:'bold',textAlign:'right',textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden',marginRight:10}}>{stringUtil.language(1263,this.state.totalInfo.choiceCount)}</span>
                        <div style={{width:150,height:'85%',borderRadius:4,backgroundColor:'#009A61',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{{
                            let result=[];
                            for(let i=0;i<this.state.wholesaleCargosData.length;i++){
                                if(this.state.wholesaleCargosData[i].isChoiceChenpinglong){
                                    result.push({cargoId:this.state.wholesaleCargosData[i].id,
                                        entrustMoney:this.state.wholesaleCargosData[i].entrustMoneyChenpinglong?this.state.wholesaleCargosData[i].entrustMoneyChenpinglong:0,
                                        disburdenMoney:this.state.wholesaleCargosData[i].disburdenMoneyChenpinglong?this.state.wholesaleCargosData[i].disburdenMoneyChenpinglong:0,
                                        discountMoney:this.state.wholesaleCargosData[i].discountMoneyChenpinglong?this.state.wholesaleCargosData[i].discountMoneyChenpinglong:0,
                                    });
                                }
                            }
                            if(!result.length){ToastBox.error(stringUtil.language(131));return;}
                            this.setState({result:result,submitPop:true});
                        }}}><span style={{color:'#FFF',fontSize:25,fontWeight:'bold'}}>{stringUtil.language(1264)}</span></div>
                    </div>
                </div>
            </div>
            <MaskModal visible={this.state.submitPop?true:false} content={<div style={{width:'100%',height:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}>
                                {stringUtil.language(1027)+stringUtil.language(1264)}？
                        </div>}
                okBnEvents={(event) => {
                    stringUtil.httpRequest(urlUtil.SALE_CARGO_FINISH_BATCH_URL,this.state.result,['json','put',1],(successResponse)=>{
                        if (successResponse.status==200) {
                            ToastBox.success(stringUtil.language(388));
                            this.setState({submitPop:false},()=>{
                                this.props.navigationStacks.splice(this.props.navigationStacks.length-1,1);
                                this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'',mainStacks:this.props.navigationStacks}});
                            });
                        }else{ToastBox.error(successResponse.message+`(${successResponse.status})`)}
                    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`)});
                }} closeBnEvents={()=>{this.setState({submitPop: false});}}/>
        </div>)
    }
}

const styles = {};