import React,{Component} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import * as arithUtil from '../utils/arithUtil.js';
import * as stringUtil from '../utils/stringUtil.js';
import * as otherUtil from '../utils/otherUtil.js';
import urlUtil from '../utils/urlUtil.js';
import ToastBox from '../components/ToastBox';
import MaskModal from '../components/MaskModal';
import TabTopActivity from '../components/TabTopActivity';
import Pagination from '../components/Pagination';
import { Select } from 'antd';
import style from 'antd/dist/antd.css';

 export default class SaleDateActivity extends Component{
    constructor(props) {
      super(props);
      let paramsPage = this.props.navigationStacks[this.props.navigationStacks.length-1].paramsPage;
      this.state={
        weightMode:0,//0stringUtil.language(1023)，1市stringUtil.language(1172)
        nWmode:0,
        searchs:{
            targetType:-1,
            targetId:-1,
            startDate:parseInt(arithUtil.timeToNumber(arithUtil.getCurrDateTime(1))+''),
            endDate:parseInt(arithUtil.timeToNumber(arithUtil.getCurrDateTime(1))+''),
        },
        personsAll:[],
        saleStatisticsDO:null
      };
      if(localStorage.getItem("shopConfigInfo")!=null&&JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit!=null) this.state.weightMode = JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit;
    }
    componentDidMount(){
        stringUtil.httpRequest(urlUtil.OAUTH_OWNER_URL,{state:1},['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                let ownersAll = successResponse.data;
                this.state.personsAll = [];
                for(let i=0;i<ownersAll.length;i++){
                    this.state.personsAll.push({targetType:0,targetId:ownersAll[i].id,noteName:ownersAll[i].noteName});
                }
                this.state.personsAll.push({targetType:1,targetId:-1,noteName:stringUtil.language(426)});
                stringUtil.httpRequest(urlUtil.OAUTH_WORKER_URL,{state:0},['key','get',1],(successResponse)=>{
                    if (successResponse.status==200) {
                        let workersAll = successResponse.data;
                        for(let i=0;i<workersAll.length;i++){
                            this.state.personsAll.push({targetType:2,targetId:workersAll[i].id,noteName:workersAll[i].noteName});
                        }
                        this.state.searchs.targetType = this.state.personsAll[0].targetType;
                        this.state.searchs.targetId = this.state.personsAll[0].targetId;
                        this.state.searchs.targetName = this.state.personsAll[0].noteName;
                        this.initData();
                    }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    }
    initData(){
        stringUtil.httpRequest(urlUtil.SUMM_SALE_STATISTICS_RANGE_URL,{
            targetType:this.state.searchs.targetType,
            targetId:this.state.searchs.targetId,
            startDate:arithUtil.formatToTimeStr(new Date(this.state.searchs.startDate),'Y-M-D'),
            endDate:arithUtil.formatToTimeStr(new Date(this.state.searchs.endDate),'Y-M-D'),
        },['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.setState({saleStatisticsDO:successResponse.data});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    }

    render(){
        if(this.state.saleStatisticsDO==null)return(<div/>);
        return(<div style={{width:'98.5%',height:'97%',padding:'0.5%'}}>
            <TabTopActivity viewHeight='6%' context={this.props.context} navigationStacks={this.props.navigationStacks}/>
            <div style={{height:'94%',paddingLeft:arithUtil.pxToDp(10),paddingRight:arithUtil.pxToDp(10),backgroundColor:'#FFF'}}>
                <div style={{height:'8%',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',borderBottomStyle:'solid',borderBottomWidth:1,borderBottomColor:'#A9A9A9'}}>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <span style={{width:50}}>{stringUtil.language(918)}</span>
                            <Select showSearch defaultValue={this.state.searchs.targetName} style={{width:150,height:30}} onChange={(value)=>{
                                this.state.searchs.targetType = JSON.parse(value).targetType;
                                this.state.searchs.targetId = JSON.parse(value).targetId;
                                this.state.searchs.targetName = JSON.parse(value).noteName;
                                this.initData();
                            }}>
                            {this.state.personsAll.map((item,key)=>{
                                return(<option value={JSON.stringify(item)} key={key}>{item.noteName}</option>)
                            })}
                            </Select>
                        </div>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginLeft:15}}>
                            <div style={styles.time}><DatePicker locale='zhCN' selected={arithUtil.strToDate(arithUtil.formatToTimeStr(new Date(this.state.searchs.startDate),'Y-M-D'))} onChange={(date) => {
                                this.state.searchs.startDate = date.valueOf();
                                this.initData();
                            }} /></div><span> {stringUtil.language(1174)} </span>
                            <div style={styles.time}><DatePicker locale='zhCN' selected={arithUtil.strToDate(arithUtil.formatToTimeStr(new Date(this.state.searchs.endDate),'Y-M-D'))} onChange={(date) => {
                                this.state.searchs.endDate = date.valueOf();
                                this.initData();
                            }} /></div>
                        </div>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginLeft:15}}>
                            <div style={{justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                                this.setState({nWmode:0});
                            }}>{this.state.nWmode==0?<i className="iconfontIndexCss" style={{fontSize:15,color:'#FF8C00'}}>&#xe71f; {stringUtil.language(590)}</i>:
                            <i className="iconfontIndexCss" style={{fontSize:15,color:'#C0C0C0'}}>&#xe641; {stringUtil.language(590)}</i>}</div>
                            <div style={{justifyContent:'center',alignItems:'center',paddingLeft:10,cursor:'pointer'}} onClick={()=>{
                                this.setState({nWmode:2});
                            }}>{this.state.nWmode==2?<i className="iconfontIndexCss" style={{fontSize:15,color:'#FF8C00'}}>&#xe71f; {stringUtil.language(432)}</i>:
                            <i className="iconfontIndexCss" style={{fontSize:15,color:'#C0C0C0'}}>&#xe641; {stringUtil.language(432)}</i>}</div>
                        </div>
                    </div>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                            this.setState({isShowTabTitle:true},()=>{
                                const printArea = document.getElementById('printArea');
                                const iframe = document.createElement('IFRAME');
                                let doc = null;
                                iframe.setAttribute('style', 'position:absolute;width:0px;height:0px;left:500px;top:500px;');
                                document.body.appendChild(iframe);
                                doc = iframe.contentWindow.document;
                                doc.write(printArea.innerHTML);
                                doc.close();
                                iframe.contentWindow.focus();// 获取iframe的焦stringUtil.language(1198)，stringUtil.language(1190)iframe开始stringUtil.language(1045)
                                iframe.contentWindow.print();
                                if (navigator.userAgent.indexOf("MSIE")>0){document.body.removeChild(iframe);}
                                this.setState({isShowTabTitle:false});
                            });
                        }}>
                            <i className="iconfontIndexCss" style={{fontSize:17,color:'#52A056'}}>&#xe867;</i>
                            <span style={{color:'#52A056',fontSize:15,marginLeft:5}}>{stringUtil.language(1045)}</span>
                        </div>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginLeft:15,cursor:'pointer'}} onClick={()=>{
                            this.getExcel({
                                startDate:this.state.searchs.startDate,
                                endDate:this.state.searchs.endDate,
                                targetName:this.state.searchs.targetName,
                                targetType:this.state.searchs.targetType,
                                saleStatisticsDO:this.state.saleStatisticsDO
                            });
                        }}>
                            <i className="iconfontIndexCss" style={{fontSize:17,color:'#52A056'}}>&#xe644;</i>
                            <span style={{color:'#52A056',fontSize:15,marginLeft:5}}>{stringUtil.language(1071)}Excel</span>
                        </div>
                    </div>
                </div>
                <div style={{width:'100%',height:'92%',display:'flex',flexDirection:'column',justifyContent:'flex-start',paddingTop:5,overflow:'auto'}} id='printArea'>
                    {this.state.isShowTabTitle?<div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                        <span style={{fontSize:25,fontWeight:'bold'}}>{this.state.searchs.targetName?this.state.searchs.targetName:stringUtil.language(425)}{stringUtil.language(330)}</span>
                        <span style={{fontSize:17}}>{arithUtil.formatToTimeStr(new Date(this.state.searchs.startDate),'Y-M-D')} {stringUtil.language(1174)} {arithUtil.formatToTimeStr(new Date(this.state.searchs.endDate),'Y-M-D')}</span>
                    </div>:''}
                    {(()=>{this.state.totalInfo={number:0,weight:0,goodsMoney:0,diffGoodsMoney:0,ownerDeductGoodsMoney:0,totalMoney:0};
                        this.state.otherCosts=[];
                        if(this.state.saleStatisticsDO.commodities==null)this.state.saleStatisticsDO.commodities=[];
                        for(let i=0;i<this.state.saleStatisticsDO.commodities.length;i++){
                            this.state.totalInfo.diffGoodsMoney =  this.state.totalInfo.diffGoodsMoney.add(this.state.saleStatisticsDO.commodities[i].diffGoodsMoney);
                            this.state.totalInfo.ownerDeductGoodsMoney =  this.state.totalInfo.ownerDeductGoodsMoney.add(this.state.saleStatisticsDO.commodities[i].ownerDeductGoodsMoney);
                            if(this.state.saleStatisticsDO.commodities[i].costs==null)this.state.saleStatisticsDO.commodities[i].costs=[];
                            for(let j=0;j<this.state.saleStatisticsDO.commodities[i].costs.length;j++){
                                let isHave=0;let costsItem = this.state.saleStatisticsDO.commodities[i].costs[j];
                                for(let k=0;k<this.state.otherCosts.length;k++){
                                    if(costsItem.costClauseId==this.state.otherCosts[k].costClauseId){isHave=1;break;}
                                }
                                if(!isHave)this.state.otherCosts.push(costsItem);
                            }
                        }
                    })()}
                    {this.state.nWmode==0?<div style={{height:30,backgroundColor:'#F2F2F2',display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <span style={{width:'15%',color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(1020)}</span>
                        <span style={{width:0,flexGrow:1,color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(1021)}</span>
                        <span style={{width:0,flexGrow:1,color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(1022)}</span>
                        <span style={{width:0,flexGrow:1,color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(1068)}</span>
                        {this.state.totalInfo.diffGoodsMoney!=0?<span style={{width:0,flexGrow:1,color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(538)}</span>:""}
                        {this.state.totalInfo.ownerDeductGoodsMoney!=0?<span style={{width:0,flexGrow:1,color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(1257)}</span>:""}
                        {this.state.otherCosts.map((item,key)=>{
                            this.state.totalInfo['cpl'+item.costClauseId]=0;
                            return(<span style={{width:0,flexGrow:1,color:'#A9A9A9',textAlign:'center'}} key={key}>{item.costClauseName}</span>);
                        })}
                        <span style={{width:0,flexGrow:1,color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(1061)}</span>
                        <span style={{width:0,flexGrow:1,color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(961)}</span>
                    </div>:''}
                    {this.state.nWmode==0?<div style={{width:'100%',overflow:'auto'}}>
                        <div style={{width:'100%'}}>
                            {this.state.saleStatisticsDO.commodities.map((item,key)=>{
                                this.state.totalInfo.number = this.state.totalInfo.number+item.number;
                                this.state.totalInfo.weight = (this.state.totalInfo.weight).add(item.weight);
                                this.state.totalInfo.goodsMoney = (this.state.totalInfo.goodsMoney).add(item.goodsMoney.sub(item.diffGoodsMoney).sub(item.ownerDeductGoodsMoney));
                                let totalMoney=item.goodsMoney;
                                for(let i=0;i<item.costs.length;i++){
                                    totalMoney=totalMoney+item.costs[i].money;
                                }
                                this.state.totalInfo.totalMoney = (this.state.totalInfo.totalMoney).add(totalMoney);
                                return(<div style={{height:40,display:'flex',flexDirection:'row',alignItems:'center'}} key={key}>
                                    <span style={{width:'15%',textAlign:'center',textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}>{item.commodityName}</span>
                                    <span style={{width:0,flexGrow:1,textAlign:'center'}}>{item.number}</span>
                                    <span style={{width:0,flexGrow:1,textAlign:'center'}}>{arithUtil.keToWeight(item.weight,this.state.weightMode)}</span>
                                    <span style={{width:0,flexGrow:1,textAlign:'center'}}>{arithUtil.fenToYuan(item.goodsMoney.sub(item.diffGoodsMoney).sub(item.ownerDeductGoodsMoney))}</span>
                                    {this.state.totalInfo.diffGoodsMoney!=0?<span style={{width:0,flexGrow:1,textAlign:'center'}}>{arithUtil.fenToYuan(item.diffGoodsMoney)}</span>:""}
                                    {this.state.totalInfo.ownerDeductGoodsMoney!=0?<span style={{width:0,flexGrow:1,textAlign:'center'}}>{arithUtil.fenToYuan(item.ownerDeductGoodsMoney)}</span>:""}
                                    {this.state.otherCosts.map((itemcpl,key)=>{
                                        let moneyVal=0;
                                        for(let i=0;i<item.costs.length;i++){
                                            if(item.costs[i].costClauseId==itemcpl.costClauseId)moneyVal=item.costs[i].money;
                                        }
                                        this.state.totalInfo['cpl'+itemcpl.costClauseId] = (this.state.totalInfo['cpl'+itemcpl.costClauseId]!=null?this.state.totalInfo['cpl'+itemcpl.costClauseId]:0).add(moneyVal);
                                        return(<span style={{width:0,flexGrow:1,textAlign:'center'}} key={key}>{arithUtil.fenToYuan(moneyVal)}</span>);
                                    })}
                                    <span style={{width:0,flexGrow:1,textAlign:'center'}}>{arithUtil.fenToYuan(totalMoney)}</span>
                                    <span style={{width:0,flexGrow:1,textAlign:'center'}}>{arithUtil.fenToYuan(totalMoney).div(arithUtil.keToWeight(item.weight,this.state.weightMode),2)}</span>
                                </div>)
                            })}
                        </div>
                        <div style={{height:30,display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <span style={{width:'15%',color:'#FF8C00',textAlign:'center'}}>{stringUtil.language(890)}</span>
                            <span style={{width:0,flexGrow:1,color:'#FF8C00',textAlign:'center'}}>{this.state.totalInfo.number}</span>
                            <span style={{width:0,flexGrow:1,color:'#FF8C00',textAlign:'center'}}>{arithUtil.keToWeight(this.state.totalInfo.weight,this.state.weightMode)}</span>
                            <span style={{width:0,flexGrow:1,color:'#FF8C00',textAlign:'center'}}>{arithUtil.fenToYuan(this.state.totalInfo.goodsMoney)}</span>
                            {this.state.totalInfo.diffGoodsMoney!=0?<span style={{width:0,flexGrow:1,color:'#FF8C00',textAlign:'center'}}>{arithUtil.fenToYuan(this.state.totalInfo.diffGoodsMoney)}</span>:""}
                            {this.state.totalInfo.ownerDeductGoodsMoney!=0?<span style={{width:0,flexGrow:1,color:'#FF8C00',textAlign:'center'}}>{arithUtil.fenToYuan(this.state.totalInfo.ownerDeductGoodsMoney)}</span>:""}
                            {this.state.otherCosts.map((item,key)=>{
                                return(<span style={{width:0,flexGrow:1,color:'#FF8C00',textAlign:'center'}} key={key}>{arithUtil.fenToYuan(this.state.totalInfo['cpl'+item.costClauseId])}</span>);
                            })}
                            <span style={{width:0,flexGrow:1,color:'#FF8C00',textAlign:'center'}}>{arithUtil.fenToYuan(this.state.totalInfo.totalMoney)}</span>
                            <span style={{width:0,flexGrow:1,color:'#FF8C00',textAlign:'center'}}></span>
                        </div>
                    </div>:''}
                    {this.state.nWmode==2?<div style={{height:30,backgroundColor:'#F2F2F2',display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <span style={{width:'17%',color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(1020)}</span>
                        <div style={{width:'83%',display:'flex',flexDirection:'row'}}>
                            <span style={{width:'23%',color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(1048)}</span>
                            <span style={{width:'11%',color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(1021)}</span>
                            <span style={{width:'15%',color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(1022)}</span>
                            <span style={{width:'14%',color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(962)}</span>
                            <span style={{width:'22%',color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(1050)}</span>
                            <span style={{width:'15%',color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(1041)}</span>
                        </div>
                    </div>:''}
                    {this.state.nWmode==2?<div style={{overflow:'auto'}}>
                        {this.state.saleStatisticsDO.commodities.map((item,key)=>{
                            let totalItemInfo={number:0,weight:0,money:0};
                            if(item.detaileds==null) item.detaileds=[];
                            for(let i=0;i<item.detaileds.length;i++){
                                totalItemInfo.number = (totalItemInfo.number).add(item.detaileds[i].number);
                                totalItemInfo.weight = (totalItemInfo.weight).add(item.detaileds[i].weight);
                                item.detaileds[i].money = item.detaileds[i].goodsMoney;
                                for(let j=0;j<item.detaileds[i].costs.length;j++){
                                    item.detaileds[i].money = (item.detaileds[i].money).add(item.detaileds[i].costs[j].money);
                                }
                                totalItemInfo.money = (totalItemInfo.money).add(item.detaileds[i].money);
                            }
                            return(<div style={{display:'flex',flexDirection:'row',borderBottomStyle:'solid',borderBottomWidth:1,borderBottomColor:'#F2F2F2',paddingLeft:5}} key={key}>
                                <span style={{width:'17%',color:'#000',fontSize:15,paddingTop:7}}>{item.commodityName}</span>
                                <div style={{width:'83%',display:'flex',flexDirection:'column'}}>
                                <span style={{height:30,color:'#52A056',fontSize:15,fontWeight:'bold',paddingTop:7}}>{stringUtil.language(1182)}{totalItemInfo.number}{stringUtil.language(1173)}，{arithUtil.keToWeight(totalItemInfo.weight,this.state.weightMode)+(this.state.weightMode==0?stringUtil.language(1023):stringUtil.language(1172))}，{arithUtil.fenToYuan(totalItemInfo.money)}{stringUtil.currency(1)}</span>
                                {item.detaileds.map((itemb,keyb)=>{
                                    return(<div style={{height:30,display:'flex',flexDirection:'row',alignItems:'center'}} key={keyb}>
                                        <span style={{width:'23%',color:'#808080'}}>{itemb.customerName}</span>
                                        <span style={{width:'11%',color:'#808080',textAlign:'center'}}>{itemb.number}</span>
                                        <span style={{width:'15%',color:'#808080',textAlign:'center'}}>{arithUtil.keToWeight(itemb.weight,this.state.weightMode)}</span>
                                        <span style={{width:'14%',color:'#808080',textAlign:'center'}}>{(arithUtil.keToWeight(itemb.weight,this.state.weightMode)).div(itemb.number,2)}</span>
                                        <span style={{width:'22%',color:'#808080',textAlign:'center'}}>{itemb.mode==0?(arithUtil.unitPriceTo(arithUtil.fenToYuan(itemb.unitPrice,2),0,this.state.weightMode)+(this.state.weightMode==0?'/'+stringUtil.language(1023):'/'+stringUtil.language(1172))):(arithUtil.fenToYuan(itemb.unitPrice,2)+'/'+stringUtil.language(1173))}</span>
                                        <span style={{width:'15%',color:'#808080',textAlign:'center'}}>{arithUtil.fenToYuan(itemb.money)}</span>
                                    </div>);
                                })}
                                </div>
                            </div>)
                        })}
                    </div>:''}
                    <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',marginTop:20}}>
                        {(()=>{this.state.otherMoneyItems = [];
                            this.state.otherMoneyItems.push({costClauseName:stringUtil.language(591),money:this.state.saleStatisticsDO.saleTotalMoney});
                            if(this.state.searchs.targetType==0)this.state.otherMoneyItems.push({costClauseName:stringUtil.language(592),money:this.state.saleStatisticsDO.deservedMoney});
                            else if(this.state.searchs.targetType==1)this.state.otherMoneyItems.push({costClauseName:stringUtil.language(593),money:this.state.saleStatisticsDO.deservedMoney});
                            else if(this.state.searchs.targetType==2)this.state.otherMoneyItems.push({costClauseName:stringUtil.language(594),money:this.state.saleStatisticsDO.deservedMoney});
                            // if(this.state.saleStatisticsDO.rebateMoney!=0)this.state.otherMoneyItems.push({costClauseName:'stringUtil.language(1150)',money:this.state.saleStatisticsDO.rebateMoney});
                            // if(this.state.saleStatisticsDO.disburdenMoney!=0)this.state.otherMoneyItems.push({costClauseName:'stringUtil.language(963)',money:this.state.saleStatisticsDO.disburdenMoney});
                            // if(this.state.saleStatisticsDO.entrustMoney!=0)this.state.otherMoneyItems.push({costClauseName:'stringUtil.language(941)',money:this.state.saleStatisticsDO.entrustMoney});
                            // if(this.state.saleStatisticsDO.discountMoney!=0)this.state.otherMoneyItems.push({costClauseName:'stringUtil.language(543)',money:this.state.saleStatisticsDO.discountMoney});
                            // if(this.state.saleStatisticsDO.expends)for(let i=0;i<this.state.saleStatisticsDO.expends.length;i++){
                            //     this.state.otherMoneyItems.push({costClauseName:this.state.saleStatisticsDO.expends[i].ownerExpendClauseName,money:this.state.saleStatisticsDO.expends[i].money});
                            // }
                        })()}
                        {this.state.otherMoneyItems.map((item,key)=>{
                            return(<div style={{width:'33.3%',height:30,display:'flex',flexDirection:'row',alignItems:'center'}} key={key}>
                                <span style={{fontSize:15}}>{item.costClauseName+':'+arithUtil.fenToYuan(item.money)}</span>
                            </div>);
                        })}
                        {/* <div style={{width:'33.3%',height:30,display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <span style={{fontSize:15}}>{stringUtil.language(754)}:</span><span style={{color:'#F00',fontSize:20,fontWeight:'bold'}}>{arithUtil.fenToYuan(this.state.saleStatisticsDO.balance)}</span>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>)
    }
    getExcel(data){let saleStatisticsDO=data.saleStatisticsDO;
        let otherCosts=[];
        if(saleStatisticsDO.commodities==null)saleStatisticsDO.commodities=[];
        for(let i=0;i<saleStatisticsDO.commodities.length;i++){
            if(saleStatisticsDO.commodities[i].costs==null)saleStatisticsDO.commodities[i].costs=[];
            for(let j=0;j<saleStatisticsDO.commodities[i].costs.length;j++){
                let isHave=0;let costsItem = saleStatisticsDO.commodities[i].costs[j];
                for(let k=0;k<otherCosts.length;k++){
                    if(costsItem.costClauseId==otherCosts[k].costClauseId){isHave=1;break;}
                }
                if(!isHave)otherCosts.push(costsItem);
            }
        }
        let resArr=new Array();let colspans=(otherCosts.length==0?7:6)+otherCosts.length;
        resArr.push(`<tr style="height: 50px"><td align="center" colspan="${colspans}"><b><font size="3">${data.targetName}${stringUtil.language(595)}（${arithUtil.formatToTimeStr(new Date(data.startDate),'Y-M-D')}{stringUtil.language(1174)}${arithUtil.formatToTimeStr(new Date(data.endDate),'Y-M-D')}）</font></b></td></tr>
        <tr><td align="right" colspan="${colspans}"><b><font size="3">${stringUtil.language(914)}${stringUtil.currency(1)}、${this.state.weightMode==0?'stringUtil.language(1023)':'stringUtil.language(1172)'}</font></b></td></tr>
        <tr><td></td></tr>`);
        let totalInfo={number:0,weight:0,goodsMoney:0,totalMoney:0};
        resArr.push(`<tr><td align="center" colspan="${colspans}"><b><font size="3">${stringUtil.language(590)}</font></b></td></tr>`);
        resArr.push(`<tr><td align="left"><b><font size="3">${stringUtil.language(1020)}</font></b></td>
          <td align="left"><b><font size="3">${stringUtil.language(1165)}</font></b></td>
          <td align="left"><b><font size="3">${stringUtil.language(1022)}</font></b></td>
          <td align="left"><b><font size="3">${stringUtil.language(1068)}</font></b></td>`);
          for(let key=0;key<otherCosts.length;key++){
            totalInfo['costs'+otherCosts[key].costClauseId]=0;
            resArr.push(`<td align="left"><b><font size="3">${otherCosts[key].costClauseName}</font></b></td>`);
          }resArr.push(`<td align="left"><b><font size="3">${stringUtil.language(1061)}</font></b></td>
          <td align="left"><b><font size="3">${stringUtil.language(961)}</font></b></td></tr>`);
        for(let key=0;key<saleStatisticsDO.commodities.length;key++){let item=saleStatisticsDO.commodities[key];
            totalInfo.number = totalInfo.number+item.number;
            totalInfo.weight = (totalInfo.weight).add(item.weight);
            totalInfo.goodsMoney = (totalInfo.goodsMoney).add(item.goodsMoney);
            let totalMoney=item.goodsMoney;
            for(let i=0;i<item.costs.length;i++){
                totalMoney=totalMoney+item.costs[i].money;
            }
            totalInfo.totalMoney = (totalInfo.totalMoney).add(totalMoney);
            resArr.push(`<tr><td align="left"><font size="3">${item.commodityName}</font></td>
            <td align="left"><font size="3">${item.number}</font></td>
            <td align="left"><font size="3">${arithUtil.keToWeight(item.weight,this.state.weightMode)}</font></td>
            <td align="left"><font size="3">${arithUtil.fenToYuan(item.goodsMoney)}</font></td>`);
            for(let key=0;key<otherCosts.length;key++){let itemcpl=otherCosts[key];
                let moneyVal=0;
                for(let i=0;i<item.costs.length;i++){
                    if(item.costs[i].costClauseId==itemcpl.costClauseId)moneyVal=item.costs[i].money;
                }
                totalInfo['cpl'+itemcpl.costClauseId] = (totalInfo['cpl'+itemcpl.costClauseId]!=null?totalInfo['cpl'+itemcpl.costClauseId]:0).add(moneyVal);
                if(moneyVal)resArr.push(`<td align="left"><font size="3">${arithUtil.fenToYuan(moneyVal)}</font></td>`);
                else resArr.push(`<td align="left"><font size="3"></font></td>`);
            }
            resArr.push(`<td align="left"><font size="3">${arithUtil.fenToYuan(totalMoney)}</font></td>
            <td align="left"><font size="3">${arithUtil.fenToYuan(totalMoney).div(arithUtil.keToWeight(item.weight,this.state.weightMode),2)}</font></td></tr>`);
        }
        resArr.push(`<tr><td align="left"><b><font size="3">${stringUtil.language(890)}</font></b></td>
          <td align="left"><b><font size="3">${totalInfo.number}</font></b></td>
          <td align="left"><b><font size="3">${arithUtil.keToWeight(totalInfo.weight,this.state.weightMode)}</font></b></td>
          <td align="left"><b><font size="3">${arithUtil.fenToYuan(totalInfo.goodsMoney)}</font></b></td>`);
          for(let key=0;key<otherCosts.length;key++){
            resArr.push(`<td align="left"><b><font size="3">${arithUtil.fenToYuan(totalInfo['cpl'+otherCosts[key].costClauseId])}</font></b></td>`);
          }resArr.push(`<td align="left"><b><font size="3">${arithUtil.fenToYuan(totalInfo.totalMoney)}</font></b></td>
          <td align="left"><b><font size="3"></font></b></td></tr><tr style="height: 50px"><td></td></tr>`);

        resArr.push(`<tr><td align="center" colspan="7"><b><font size="3">${stringUtil.language(432)}</font></b></td></tr>`);
        resArr.push(`<tr><td align="left"><b><font size="3">${stringUtil.language(1020)}</font></b></td>
          <td align="left"><b><font size="3">${stringUtil.language(1048)}</font></b></td>
          <td align="left"><b><font size="3">${stringUtil.language(1021)}</font></b></td>
          <td align="left"><b><font size="3">${stringUtil.language(1022)}</font></b></td>
          <td align="left"><b><font size="3">${stringUtil.language(962)}</font></b></td>
          <td align="left"><b><font size="3">${stringUtil.language(1050)}</font></b></td>
          <td align="left"><b><font size="3">${stringUtil.language(1041)}</font></b></td></tr>`);
        if(saleStatisticsDO.commodities)for(let key=0;key<saleStatisticsDO.commodities.length;key++){let item=saleStatisticsDO.commodities[key];
            let totalItemInfo={number:0,weight:0,money:0};
            if(item.detaileds==null) item.detaileds=[];
            for(let i=0;i<item.detaileds.length;i++){
                totalItemInfo.number = (totalItemInfo.number).add(item.detaileds[i].number);
                totalItemInfo.weight = (totalItemInfo.weight).add(item.detaileds[i].weight);
                item.detaileds[i].money = item.detaileds[i].goodsMoney;
                for(let j=0;j<item.detaileds[i].costs.length;j++){
                    item.detaileds[i].money = (item.detaileds[i].money).add(item.detaileds[i].costs[j].money);
                }
                totalItemInfo.money = (totalItemInfo.money).add(item.detaileds[i].money);
            }
          resArr.push(`<tr><td align="left" rowspan="${item.detaileds.length+1}"><b><font size="3">${item.commodityName}</font></b></td>
          <td align="left" colspan="6" style="color: #52A056"><b><font size="3">${stringUtil.language(1182)}${totalItemInfo.number}${stringUtil.language(1173)}，${arithUtil.keToWeight(totalItemInfo.weight,this.state.weightMode)+(this.state.weightMode==0?stringUtil.language(1023):stringUtil.language(1172))}，${arithUtil.fenToYuan(totalItemInfo.money)}${stringUtil.currency(1)}</font></b></td></tr>`);
          for(let keyb=0;keyb<item.detaileds.length;keyb++){let itemb=item.detaileds[keyb];
            resArr.push(`<tr><td align="left"><b><font size="3">${itemb.customerName}</font></b></td>
            <td align="left"><b><font size="3">${itemb.number}</font></b></td>
            <td align="left"><b><font size="3">${arithUtil.keToWeight(itemb.weight,this.state.weightMode)}</font></b></td>
            <td align="left"><b><font size="3">${(arithUtil.keToWeight(itemb.weight,this.state.weightMode)).div(itemb.number,2)}</font></b></td>
            <td align="left"><b><font size="3">${itemb.mode==0?(arithUtil.unitPriceTo(arithUtil.fenToYuan(itemb.unitPrice,2),0,this.state.weightMode)+(this.state.weightMode==0?'/'+stringUtil.language(1023):'/'+stringUtil.language(1172))):(arithUtil.fenToYuan(itemb.unitPrice,2)+'/'+stringUtil.language(1173))}</font></b></td>
            <td align="left"><b><font size="3">${arithUtil.fenToYuan(itemb.money)}</font></b></td></tr>`);
          }
        }
        resArr.push(`<tr style="height: 50px"><td></td></tr>`);
        let otherMoneyItems=[];
        otherMoneyItems.push({costClauseName:stringUtil.language(591),money:saleStatisticsDO.saleTotalMoney});
        if(data.targetType==0)otherMoneyItems.push({costClauseName:stringUtil.language(592),money:saleStatisticsDO.deservedMoney});
        else if(data.targetType==1)otherMoneyItems.push({costClauseName:stringUtil.language(593),money:saleStatisticsDO.deservedMoney});
        else if(data.targetType==2)otherMoneyItems.push({costClauseName:stringUtil.language(594),money:saleStatisticsDO.deservedMoney});
        // if(saleStatisticsDO.rebateMoney!=0)otherMoneyItems.push({costClauseName:'stringUtil.language(1150)',money:saleStatisticsDO.rebateMoney});
        // if(saleStatisticsDO.disburdenMoney!=0)otherMoneyItems.push({costClauseName:'stringUtil.language(963)',money:saleStatisticsDO.disburdenMoney});
        // if(saleStatisticsDO.entrustMoney!=0)otherMoneyItems.push({costClauseName:'stringUtil.language(941)',money:saleStatisticsDO.entrustMoney});
        // if(saleStatisticsDO.discountMoney!=0)otherMoneyItems.push({costClauseName:'stringUtil.language(543)',money:saleStatisticsDO.discountMoney});
        // if(saleStatisticsDO.expends)for(let i=0;i<saleStatisticsDO.expends.length;i++){
        //     otherMoneyItems.push({costClauseName:saleStatisticsDO.expends[i].ownerExpendClauseName,money:saleStatisticsDO.expends[i].money});
        // }
        for(let key=0,keyb=0;key<otherMoneyItems.length;key=key+4,keyb++){
          let item0 = otherMoneyItems[key];
          resArr.push(`<tr><td align="left"><b><font size="3">${item0.costClauseName+'：'+arithUtil.fenToYuan(item0.money)}</font></b></td>
          <td><b><font size="3"></font></b></td>`);
          if((key+1)<otherMoneyItems.length){
            let item1 = otherMoneyItems[key+1];
            resArr.push(`<td align="left"><b><font size="3">${item1.costClauseName+'：'+arithUtil.fenToYuan(item1.money)}</font></b></td>
            <td><b><font size="3"></font></b></td>`);
          }
          if((key+2)<otherMoneyItems.length){
            let item2 = otherMoneyItems[key+2];
            resArr.push(`<td align="left"><b><font size="3">${item2.costClauseName+'：'+arithUtil.fenToYuan(item2.money)}</font></b></td>
            <td><b><font size="3"></font></b></td>`);
          }
          if((key+3)<otherMoneyItems.length){
            let item3 = otherMoneyItems[key+3];
            resArr.push(`<td align="left"><b><font size="3">${item3.costClauseName+'：'+arithUtil.fenToYuan(item3.money)}</font></b></td>
            <td><b><font size="3"></font></b></td>`);
          }
          resArr.push(`</tr>`);
        }
        //resArr.push(`<tr style="height: 50px"><td align="right" colspan="${colspans}" style="color: #F00"><b><font size="6">${(data.targetType!=0?'stringUtil.language(756)：':'{stringUtil.language(754)}：')+arithUtil.fenToYuan(saleStatisticsDO.balance)}</font></b></td></tr>`);
        otherUtil.createExcel(resArr.join(''),data.targetName+stringUtil.language(330)+'（'+arithUtil.formatToTimeStr(new Date(data.startDate),'Y-M-D')+' '+stringUtil.language(1174)+' ' +arithUtil.formatToTimeStr(new Date(data.endDate),'Y-M-D')+'）');
    }
}

const styles = {
    time:{
        height:30,
        display:'flex',
        flexDirection:'row',
        justifyContent:'center',
        alignItems:'center',
        borderStyle:'solid',
        borderWidth:1,
        borderColor:'#A9A9A9',
        borderRadius:3,
    },
};