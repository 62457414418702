import React,{Component} from 'react';
import * as arithUtil from '../utils/arithUtil.js';
 import * as stringUtil from '../utils/stringUtil.js';
 import * as otherUtil from '../utils/otherUtil.js';
 import urlUtil from '../utils/urlUtil.js';
 import ToastBox from '../components/ToastBox';
 import TabTopActivity from '../components/TabTopActivity';
 import MaskModal from '../components/MaskModal';
 import Pagination from '../components/Pagination';

  export default class WholesaleInfoView extends Component{
    constructor(props) {
      super(props);
      this.state={
        weightMode:0,//0stringUtil.language(1023)，1市stringUtil.language(1172)
        currWholesaleInfoItem:{},//cargoMode，-1库存 0代销 1{stringUtil.language(1051)} 2炒货
        inputTextTagVal:'',
        keyTagVal:-1,
      };
      if(localStorage.getItem("shopConfigInfo")!=null&&JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit!=null) this.state.weightMode = JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit;
    }
    componentDidMount(){
        stringUtil.httpRequest(urlUtil.SALE_WHOLESALE_URL+'/'+this.props.wholesaleItem.id,null,['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.setState({currWholesaleInfoItem:successResponse.data})
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    }

    render(){
        return(<div style={{width:'100%',height:'99%',backgroundColor:'#FFF'}}>
            <div style={{height:50,display:'flex',flexDirection:'column',justifyContent:'center',paddingLeft:10,paddingRight:10}}>
                <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                    <span style={{fontSize:16,fontWeight:'bold'}}>{stringUtil.language(903)}{this.state.currWholesaleInfoItem.customerName+(this.state.currWholesaleInfoItem.customerAlias?this.state.currWholesaleInfoItem.customerAlias:'')}</span>
                    <span style={{color:'#FF8C00',fontSize:12}}>{arithUtil.formatToTimeStr(new Date(this.state.currWholesaleInfoItem.createTime),'Y-M-D h:m:s')}</span>
                </div>
                <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                    <span></span>
                    <span style={{color:'#F00',fontWeight:'bold'}}>KD.{this.state.currWholesaleInfoItem.wholesaleNo}</span>
                </div>
            </div>
            <div style={{height:30,backgroundColor:'#F2F2F2',display:'flex',flexDirection:'row',alignItems:'center'}}>
                <span style={{width:'20%',color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(518)}</span>
                <span style={{width:'15%',color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(1021)}</span>
                <span style={{width:'15%',color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(1022)}</span>
                <span style={{width:'20%',color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(1050)}</span>
                <span style={{width:'15%',color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(1068)}</span>
                <span style={{width:'15%',color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(1069)}</span>
            </div>
            <div style={{paddingLeft:10,paddingRight:10}}>
                {(()=>{this.state.totalWholesaleInfo={totalNumber:0,totalWeight:0,totalGoodMoney:0,totalTotalOtherMoney:0};})()}
                {this.state.currWholesaleInfoItem.commodities!=null?this.state.currWholesaleInfoItem.commodities.map((item,key)=>{
                    let totalOtherMoney = 0;
                    for(let i=0;i<item.costs.length;i++){
                        let costsItem = item.costs[i].money;
                        totalOtherMoney = totalOtherMoney.add(costsItem);
                    }
                    this.state.totalWholesaleInfo.totalNumber = (this.state.totalWholesaleInfo.totalNumber).add(item.number);
                    this.state.totalWholesaleInfo.totalWeight = (this.state.totalWholesaleInfo.totalWeight).add(item.weight);
                    this.state.totalWholesaleInfo.totalGoodMoney = (this.state.totalWholesaleInfo.totalGoodMoney).add(item.goodsMoney);
                    this.state.totalWholesaleInfo.totalTotalOtherMoney = (this.state.totalWholesaleInfo.totalTotalOtherMoney).add(totalOtherMoney);
                    return(<div style={{height:45,display:'flex',flexDirection:'column',justifyContent:'center'}} key={key}>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <span style={{width:'20%',fontSize:16,textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}>{item.commodityName}</span>
                            <span style={{width:'15%',fontSize:16,textAlign:'center'}}>{item.number}</span>
                            <span style={{width:'15%',fontSize:16,textAlign:'center'}}>{arithUtil.keToWeight(item.weight,this.state.weightMode)}</span>
                            <span style={{width:'20%',fontSize:16,textAlign:'center'}}>{(item.mode==0)?(arithUtil.unitPriceTo(arithUtil.fenToYuan(item.unitPrice,2),0,this.state.weightMode)+(this.state.weightMode==0?'/'+stringUtil.language(1023):'/'+stringUtil.language(1172))):(arithUtil.fenToYuan(item.unitPrice,2)+'/'+stringUtil.language(1173))}</span>
                            <span style={{width:'15%',fontSize:16,textAlign:'center'}}>{arithUtil.fenToYuan(item.goodsMoney)}</span>
                            <span style={{width:'15%',fontSize:16,textAlign:'center'}}>{arithUtil.fenToYuan(totalOtherMoney)}</span>
                        </div>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <div style={{width:'50%',display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                                <span style={{width:0,flexGrow:1,color:'#52A056',fontSize:10,textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}>{item.targetName?item.targetName:item.targetType==1?stringUtil.language(426):''} {stringUtil.language(1237,item.cargoNo)}</span>
                                <span style={{color:'#52A056',fontSize:10}}>{arithUtil.keToWeight(item.totalWeight,this.state.weightMode)}-{arithUtil.keToWeight(item.skinWeight,this.state.weightMode)}-{arithUtil.keToWeight(item.reduceWeight,this.state.weightMode)}</span>
                            </div>
                            <span style={{width:0,flexGrow:1,color:'#52A056',fontSize:10,textAlign:'right'}}>
                                {(()=>{let costsItems='';
                                    if(item.costs==null) item.costs=[];
                                    for(let i=0;i<item.costs.length;i++){costsItems = costsItems+' '+item.costs[i].costClauseName+':'+arithUtil.fenToYuan(item.costs[i].money);}
                                    return costsItems;
                                })()}
                            </span>
                            {!item.mode&&item.ownerUnitPrice?<span style={{color:'#F00',fontSize:10}}>{stringUtil.language(1255)}:{arithUtil.unitPriceTo(arithUtil.fenToYuan(item.ownerUnitPrice,2),0,this.state.weightMode)}</span>:''}
                            {item.mode&&item.ownerUnitPrice?<span style={{color:'#F00',fontSize:10}}>{stringUtil.language(1255)}:{arithUtil.fenToYuan(item.ownerUnitPrice,2)+'/'+(item.mode==2?item.commodityUnitSlave:item.commodityUnitMaster)}</span>:''}
                        </div>
                    </div>)
                }):''}
            </div>
            <div style={{paddingLeft:10,paddingRight:10}}>
                <div style={{height:30,display:'flex',flexDirection:'row',alignItems:'center',borderBottomStyle:'solid',borderBottomWidth:1,borderBottomColor:'#C0C0C0'}}>
                    <span style={{width:'20%',color:'#D2855D'}}>{stringUtil.language(890)}</span>
                    <span style={{width:'15%',color:'#D2855D',textAlign:'center'}}>{this.state.totalWholesaleInfo.totalNumber}</span>
                    <span style={{width:'15%',color:'#D2855D',textAlign:'center'}}>{arithUtil.keToWeight(this.state.totalWholesaleInfo.totalWeight,this.state.weightMode)}</span>
                    <span style={{width:'20%'}}></span>
                    <span style={{width:'15%',color:'#D2855D',textAlign:'center'}}>{arithUtil.fenToYuan(this.state.totalWholesaleInfo.totalGoodMoney)}</span>
                    <span style={{width:'15%',color:'#D2855D',textAlign:'center'}}>{arithUtil.fenToYuan(this.state.totalWholesaleInfo.totalTotalOtherMoney)}</span>
                </div>
                <div style={{overflowY:'hidden',overflow:'auto',whiteSpace:'nowrap'/*水平滚动*/,display:'flex',flexDirection:'row'}}>
                    {this.state.currWholesaleInfoItem.costs!=null?this.state.currWholesaleInfoItem.costs.map((item,key)=>{
                        return(<div style={{height:30,display:'flex',flexDirection:'row',alignItems:'center',marginLeft:key!=0?20:0}} key={key}><span style={{fontSize:12}}>{item.costClauseName}:{arithUtil.fenToYuan(item.money)}</span></div>)
                    }):''}
                    <div style={{height:30,display:'flex',flexDirection:'row',alignItems:'center',marginLeft:20}}><span style={{fontSize:12}}>{stringUtil.language(1161)}:{arithUtil.fenToYuan(this.state.currWholesaleInfoItem.advanceMoney)}</span></div>
                </div>
                {(()=>{this.state.yakuangxinxi={number:0,money:0};
                    if(this.state.currWholesaleInfoItem.commodities)for(let i=0;i<this.state.currWholesaleInfoItem.commodities.length;i++){
                        if(this.state.currWholesaleInfoItem.commodities[i].systemCommodityId==-1){
                            this.state.yakuangxinxi.number=this.state.yakuangxinxi.number+this.state.currWholesaleInfoItem.commodities[i].number;
                            this.state.yakuangxinxi.money=(this.state.yakuangxinxi.money).add(this.state.currWholesaleInfoItem.commodities[i].goodsMoney);
                        }
                    }
                })()}
                {this.state.yakuangxinxi.number?<span style={{fontSize:14}}>{stringUtil.language(1157)}:{this.state.yakuangxinxi.number}个,{arithUtil.fenToYuan(this.state.yakuangxinxi.money)}{stringUtil.currency(1)}</span>:''}
                <div style={{height:40,display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                    <span style={{fontSize:12}}>{stringUtil.language(858)}{this.state.currWholesaleInfoItem.describe}</span>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <span>{stringUtil.language(880)}</span><span style={{color:'#F00',fontSize:25,fontWeight:'bold'}}>{(()=>{
                            if((localStorage.getItem("shopConfigInfo")!=null&&JSON.parse(localStorage.getItem("shopConfigInfo")).isRound!=null&&JSON.parse(localStorage.getItem("shopConfigInfo")).isRound==0)&&this.state.currWholesaleInfoItem.customerType==1){//临时stringUtil.language(1048)取整
                                let madeAllMoney = arithUtil.getRoundMoney(this.state.currWholesaleInfoItem.money);
                                if (madeAllMoney!=this.state.currWholesaleInfoItem.money){//在取整规则范围内
                                    this.state.currWholesaleInfoItem.billMoney = madeAllMoney.sub(this.state.currWholesaleInfoItem.money,2);
                                    return arithUtil.fenToYuan(madeAllMoney);
                                }
                            }
                            return arithUtil.fenToYuan(this.state.currWholesaleInfoItem.money);
                        })()}</span>
                    </div>
                </div>
            </div>
        </div>)
    }
}

const styles = {}