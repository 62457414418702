import React,{Component} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import * as arithUtil from '../utils/arithUtil.js';
import * as stringUtil from '../utils/stringUtil.js';
import * as otherUtil from '../utils/otherUtil.js';
import urlUtil from '../utils/urlUtil.js';
import ToastBox from '../components/ToastBox';
import MaskModal from '../components/MaskModal';
import TabTopActivity from '../components/TabTopActivity';
import Pagination from '../components/Pagination';

 //{stringUtil.language(235)}
 export default class SaleProfitActivity extends Component{
    constructor(props) {
      super(props);
      let paramsPage = this.props.navigationStacks[this.props.navigationStacks.length-1].paramsPage;
      this.state={
        weightMode:localStorage.getItem("shopConfigInfo")&&JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit?JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit:0,//0stringUtil.language(1023)，1市stringUtil.language(1172)
        profitDatas:[],
        dateTimeStr:arithUtil.getCurrDateTime(1,true),
        dateTimeStrEnd:arithUtil.getCurrDateTime(1,true),
      };
    }
    componentDidMount(){
        this.initData();
    }
    initData(){
        stringUtil.httpRequest(urlUtil.SUMM_SALE_PROFIT_URL,{
            startDate:this.state.dateTimeStr,
            endDate:this.state.dateTimeStrEnd,
        },['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.setState({profitDatas:successResponse.data});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    }

    render(){
        return(<div style={{width:'98.5%',height:'97%',padding:'0.5%'}}>
            <TabTopActivity viewHeight='6%' context={this.props.context} navigationStacks={this.props.navigationStacks}/>
            <div style={{height:'94%',paddingLeft:arithUtil.pxToDp(10),paddingRight:arithUtil.pxToDp(10),backgroundColor:'#FFF'}}>
                <div style={{height:'8%',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',borderBottomStyle:'solid',borderBottomWidth:1,borderBottomColor:'#A9A9A9'}}>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <span style={{width:50}}>{stringUtil.language(888)}</span><div style={styles.time}><DatePicker locale='zhCN' selected={arithUtil.strToDate(this.state.dateTimeStr)} onChange={(date) => {
                                this.state.dateTimeStr = arithUtil.formatToTimeStr(date,'Y-M-D');
                                this.initData();
                            }} /></div>&nbsp;{stringUtil.language(1174)}&nbsp;<div style={styles.time}><DatePicker locale='zhCN' selected={arithUtil.strToDate(this.state.dateTimeStrEnd)} onChange={(date) => {
                                this.state.dateTimeStrEnd = arithUtil.formatToTimeStr(date,'Y-M-D');
                                this.initData();
                            }} /></div>
                        </div>
                        <span style={{marginLeft:15}}>{stringUtil.language(914)}{stringUtil.currency(1)}</span>
                    </div>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                            this.setState({isShowTabTitle:true},()=>{
                                const printArea = document.getElementById('printArea');
                                const iframe = document.createElement('IFRAME');
                                let doc = null;
                                iframe.setAttribute('style', 'position:absolute;width:0px;height:0px;left:500px;top:500px;');
                                document.body.appendChild(iframe);
                                doc = iframe.contentWindow.document;
                                doc.write(printArea.innerHTML);
                                doc.close();
                                iframe.contentWindow.focus();// 获取iframe的焦stringUtil.language(1198)，stringUtil.language(1190)iframe开始stringUtil.language(1045)
                                iframe.contentWindow.print();
                                if (navigator.userAgent.indexOf("MSIE")>0){document.body.removeChild(iframe);}
                                this.setState({isShowTabTitle:false});
                            });
                        }}><i className="iconfontIndexCss" style={{fontSize:17,color:'#52A056'}}>&#xe867;</i>
                            <span style={{color:'#52A056',fontSize:15,marginLeft:5}}>{stringUtil.language(1045)}</span>
                        </div>
                    </div>
                </div>
                <div style={{width:'100%',height:'92%',paddingTop:5,overflowY:'auto'}} id='printArea'>
                    {this.state.isShowTabTitle?<div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                        <span style={{fontSize:25,fontWeight:'bold'}}>{localStorage.getItem("currentStallItem")?JSON.parse(localStorage.getItem("currentStallItem")).name:''}{stringUtil.language(596)}</span>
                        <span style={{fontSize:17}}>{this.state.dateTimeStr+' '+stringUtil.language(1174)+' ' +this.state.dateTimeStrEnd}&nbsp;&nbsp;&nbsp;{stringUtil.language(1046)}:{stringUtil.currency(1)}</span>
                    </div>:''}
                    <div style={{height:35,display:'flex',flexDirection:'row',alignItems:'center',backgroundColor:'#F2F2F2'}}>
                        <span style={{width:0,flexGrow:1,fontSize:12,textAlign:'center'}}>{stringUtil.language(1067)}</span>
                        <span style={{width:0,flexGrow:1,fontSize:12,textAlign:'center'}}>{stringUtil.language(1021)}</span>
                        <span style={{width:0,flexGrow:1,fontSize:12,textAlign:'center'}}>{stringUtil.language(1022)}</span>
                        <span style={{width:0,flexGrow:1,fontSize:12,textAlign:'center'}}>{stringUtil.language(1068)}</span>
                        <span style={{width:0,flexGrow:1,fontSize:12,textAlign:'center'}}>{stringUtil.language(538)}</span>
                        <span style={{width:0,flexGrow:1,fontSize:12,textAlign:'center'}}>{stringUtil.language(1257)}</span>
                        <span style={{width:0,flexGrow:1,fontSize:12,textAlign:'center'}}>{stringUtil.language(1120)}</span>
                        <span style={{width:0,flexGrow:1,fontSize:12,textAlign:'center'}}>{stringUtil.language(597)}</span>
                        {this.state.profitDatas.names?this.state.profitDatas.names.map((item,key)=>{
                        this.state.totalInfo['names'+key]=0;
                        return(<span style={{width:0,flexGrow:1,fontSize:12,textAlign:'center'}} key={key}>{item}</span>);
                        }):''}
                        <span style={{width:0,flexGrow:1,fontSize:12,textAlign:'center'}}>{stringUtil.language(963)}</span>
                        <span style={{width:0,flexGrow:1,fontSize:12,textAlign:'center'}}>{stringUtil.language(941)}</span>
                    </div>
                    {(()=>{this.state.totalInfo={number:0,weight:0,goodsMoney:0,diffGoodsMoney:0,ownerDeductGoodsMoney:0,profit:0,income:0,disburdenMoney:0,entrustMoney:0}})()}
                    {this.state.profitDatas.items?this.state.profitDatas.items.map((item,key)=>{
                        this.state.totalInfo.number=(this.state.totalInfo.number)+(item.number);
                        this.state.totalInfo.weight=(this.state.totalInfo.weight).add(item.weight);
                        this.state.totalInfo.goodsMoney=(this.state.totalInfo.goodsMoney).add(item.goodsMoney);
                        this.state.totalInfo.diffGoodsMoney=(this.state.totalInfo.diffGoodsMoney).add(item.diffGoodsMoney);
                        this.state.totalInfo.ownerDeductGoodsMoney=(this.state.totalInfo.ownerDeductGoodsMoney).add(item.ownerDeductGoodsMoney);
                        this.state.totalInfo.profit=(this.state.totalInfo.profit).add(item.profit);
                        this.state.totalInfo.income=(this.state.totalInfo.income).add(item.income);
                        this.state.totalInfo.disburdenMoney=(this.state.totalInfo.disburdenMoney).add(item.disburdenMoney);
                        this.state.totalInfo.entrustMoney=(this.state.totalInfo.entrustMoney).add(item.entrustMoney);
                        return(<div style={{display:'flex',flexDirection:'row'}} key={key}>
                            <span style={{width:0,flexGrow:1,height:35,fontSize:12,display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderColor:'#F0F0F0',borderWidth: 0.5}}>{item.date}</span>
                            <span style={{width:0,flexGrow:1,height:35,fontSize:12,display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderColor:'#F0F0F0',borderWidth: 0.5}}>{item.number}</span>
                            <span style={{width:0,flexGrow:1,height:35,fontSize:12,display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderColor:'#F0F0F0',borderWidth: 0.5}}>{arithUtil.keToWeight(item.weight,this.state.weightMode)}</span>
                            <span style={{width:0,flexGrow:1,height:35,fontSize:12,display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderColor:'#F0F0F0',borderWidth: 0.5}}>{arithUtil.fenToYuan(item.goodsMoney)}</span>
                            <span style={{width:0,flexGrow:1,height:35,fontSize:12,display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderColor:'#F0F0F0',borderWidth: 0.5}}>{arithUtil.fenToYuan(item.diffGoodsMoney)}</span>
                            <span style={{width:0,flexGrow:1,height:35,fontSize:12,display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderColor:'#F0F0F0',borderWidth: 0.5}}>{arithUtil.fenToYuan(item.ownerDeductGoodsMoney)}</span>
                            <span style={{width:0,flexGrow:1,height:35,fontSize:12,display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderColor:'#F0F0F0',borderWidth: 0.5}}>{arithUtil.fenToYuan(item.profit)}</span>
                            <span style={{width:0,flexGrow:1,height:35,fontSize:12,display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderColor:'#F0F0F0',borderWidth: 0.5}}>{arithUtil.fenToYuan(item.income)}</span>
                            {item.costs?item.costs.map((itemb,keyb)=>{
                                this.state.totalInfo['names'+keyb]=(this.state.totalInfo['names'+keyb]).add(itemb);
                                return(<span style={{width:0,flexGrow:1,height:35,fontSize:12,display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderColor:'#F0F0F0',borderWidth: 0.5}} key={keyb}>{arithUtil.fenToYuan(itemb)}</span>);
                            }):''}
                            <span style={{width:0,flexGrow:1,height:35,fontSize:12,display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderColor:'#F0F0F0',borderWidth: 0.5}}>{arithUtil.fenToYuan(item.disburdenMoney)}</span>
                            <span style={{width:0,flexGrow:1,height:35,fontSize:12,display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderColor:'#F0F0F0',borderWidth: 0.5}}>{arithUtil.fenToYuan(item.entrustMoney)}</span>
                        </div>)
                    }):''}
                    <div style={{display:'flex',flexDirection:'row'}}>
                        <span style={{width:0,flexGrow:1,height:35,fontSize:12,display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderColor:'#F0F0F0',borderWidth: 0.5,color:'#D89573',borderBottomStyle:'solid',borderBottomColor:'#F0F0F0',borderBottomWidth: 1}}>{stringUtil.language(890)}</span>
                        <span style={{width:0,flexGrow:1,height:35,fontSize:12,display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderColor:'#F0F0F0',borderWidth: 0.5,color:'#D89573',borderBottomStyle:'solid',borderBottomColor:'#F0F0F0',borderBottomWidth: 1}}>{this.state.totalInfo.number}</span>
                        <span style={{width:0,flexGrow:1,height:35,fontSize:12,display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderColor:'#F0F0F0',borderWidth: 0.5,color:'#D89573',borderBottomStyle:'solid',borderBottomColor:'#F0F0F0',borderBottomWidth: 1}}>{arithUtil.keToWeight(this.state.totalInfo.weight,this.state.weightMode)}</span>
                        <span style={{width:0,flexGrow:1,height:35,fontSize:12,display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderColor:'#F0F0F0',borderWidth: 0.5,color:'#D89573',borderBottomStyle:'solid',borderBottomColor:'#F0F0F0',borderBottomWidth: 1}}>{arithUtil.fenToYuan(this.state.totalInfo.goodsMoney)}</span>
                        <span style={{width:0,flexGrow:1,height:35,fontSize:12,display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderColor:'#F0F0F0',borderWidth: 0.5,color:'#D89573',borderBottomStyle:'solid',borderBottomColor:'#F0F0F0',borderBottomWidth: 1}}>{arithUtil.fenToYuan(this.state.totalInfo.diffGoodsMoney)}</span>
                        <span style={{width:0,flexGrow:1,height:35,fontSize:12,display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderColor:'#F0F0F0',borderWidth: 0.5,color:'#D89573',borderBottomStyle:'solid',borderBottomColor:'#F0F0F0',borderBottomWidth: 1}}>{arithUtil.fenToYuan(this.state.totalInfo.ownerDeductGoodsMoney)}</span>
                        <span style={{width:0,flexGrow:1,height:35,fontSize:12,display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderColor:'#F0F0F0',borderWidth: 0.5,color:'#D89573',borderBottomStyle:'solid',borderBottomColor:'#F0F0F0',borderBottomWidth: 1}}>{arithUtil.fenToYuan(this.state.totalInfo.profit)}</span>
                        <span style={{width:0,flexGrow:1,height:35,fontSize:12,display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderColor:'#F0F0F0',borderWidth: 0.5,color:'#D89573',borderBottomStyle:'solid',borderBottomColor:'#F0F0F0',borderBottomWidth: 1}}>{arithUtil.fenToYuan(this.state.totalInfo.income)}</span>
                        {this.state.profitDatas.names?this.state.profitDatas.names.map((itemb,keyb)=>{
                        return(<span style={{width:0,flexGrow:1,height:35,fontSize:12,display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderColor:'#F0F0F0',borderWidth: 0.5,color:'#D89573',borderBottomStyle:'solid',borderBottomColor:'#F0F0F0',borderBottomWidth: 1}} key={keyb}>{arithUtil.fenToYuan(this.state.totalInfo['names'+keyb])}</span>);
                        }):''}
                        <span style={{width:0,flexGrow:1,height:35,fontSize:12,display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderColor:'#F0F0F0',borderWidth: 0.5,color:'#D89573',borderBottomStyle:'solid',borderBottomColor:'#F0F0F0',borderBottomWidth: 1}}>{arithUtil.fenToYuan(this.state.totalInfo.disburdenMoney)}</span>
                        <span style={{width:0,flexGrow:1,height:35,fontSize:12,display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderColor:'#F0F0F0',borderWidth: 0.5,color:'#D89573',borderBottomStyle:'solid',borderBottomColor:'#F0F0F0',borderBottomWidth: 1}}>{arithUtil.fenToYuan(this.state.totalInfo.entrustMoney)}</span>
                    </div>
                </div>
            </div>
        </div>)
    }
}

const styles = {
    time:{
        height:arithUtil.pxToDp(30),
        display:'flex',
        flexDirection:'row',
        justifyContent:'center',
        alignItems:'center',
        borderStyle:'solid',
        borderWidth:1,
        borderColor:'#A9A9A9',
        borderRadius:3,
    },
};